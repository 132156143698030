import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useStyles from '../stylesheet';
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';


import Meralco from '../assets/meralco.png'
import Maynilad from '../assets/maynilad.png'
import Primewater from '../assets/prime.png'
const subStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        width: '100%',
    },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        height: 440,
    },
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function BranchsDialog({ initialDialog, showDialogBranch ,getInitialFetch}) {
    const classes = useStyles();
    const Dispatch = useDispatch();
    const [branches, setbranches] = React.useState([]);
    const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)

    const paramStructure=(params)=>{
        getInitialFetch(params)
        showDialogBranch()
    }

    useEffect(() => {
        setbranches(BranchHandled)
    }, [])
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={initialDialog}
            // onClose={handleClose}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="form-dialog-title">Handled Branches</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div style={{width:'35%'}}>
                    <FormControl style={{width:300,marginBottom:10,width:'100%'}}>
                        <TextField
                        label="Search Customer's name"
                        id="outlined-size-small"
                        // onChange={onChange}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: <InputAdornment position="end">
                                <IconButton 
                                    // onClick={()=>fetchCustomer()} 
                                    aria-label="toggle password visibility" edge="start">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>,
                        }} />
                    </FormControl>
                </div>
                <Divider style={{marginTop:5,marginBottom:15}}/>
                <div  className={classes.flexRows}>
                    {BranchHandled.length > 0 &&
                        <>
                            {BranchHandled.map((value, index) => {
                                let imageDisplay = null
                                if (String(value.branch_name).includes('Meralco')) {
                                    imageDisplay = Meralco
                                } if (String(value.branch_name).includes('Maynilad')) {
                                    imageDisplay = Maynilad
                                } if (String(value.branch_name).includes('Primewater')) {
                                    imageDisplay = Primewater
                                }
                                return <div key={index} className={classes.valueFlex} 
                                    onClick={()=>{ 
                                        paramStructure(value)
                                    }}>
                                    <Grid container style={{height:'100%'}} spacing={1}>
                                        <Grid item xs={4} md={5}>
                                            <div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}>
                                                {imageDisplay != null
                                                    ? <img src={imageDisplay} style={{ width: 100, height: 100 }} />
                                                    : <Avatar style={{width:70,height:70}} aria-label="recipe" className={classes.avatar}>{String(value.branch_name).charAt(0)}</Avatar>
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item xs={8} md={7}>
                                            <div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}>
                                                <div style={{width:'100%',lineHeight:'normal'}}>
                                                    <div style={{lineHeight:'normal'}}>
                                                        <Typography style={{width:'90%',fontSize:17,fontWeight:'600'}} noWrap>{value.branch_name}</Typography>
                                                    </div>
                                                    <Typography style={{width:'100%',fontSize:15}}>{value.company_name}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            })}
                        </>
                    }
                   

                </div>
                {/* <Grid container spacing={2} >
              <Grid item xs={12} md={3}>
                <center>
                  <img src={UploadPic} style={{ width: '90%', height: '45%' }} />
                </center>
                <Card variant="outlined" style={{marginTop:10}}>
                    <Typography variant="subtitle1" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b'}}>Attachments:</Typography>
                    <ul>
                      <li style={{fontSize:13,marginBottom:5,color:'#b33939'}}>Barangay Clearance</li>
                      <li style={{fontSize:13,marginBottom:5,color:'#b33939'}}>2x2 Picture</li>
                      <li style={{fontSize:13,marginBottom:5,color:'#b33939'}}>Birth Certificate</li>
                    </ul>
                </Card>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6}>
                          <TextField  id="standard-disabled" label="Fullname" value="John Lester Santos" style={{width:'100%'}} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField id="standard-disabled" label="Customer Type" value="WATER, Residential"  style={{width:'100%'}}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField success id="standard-disabled" label="Account Status" value="Active" style={{width:'100%'}}/>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{marginTop:10}}>
                          <Grid item xs={12} md={9}>
                              <TextField variant="filled" id="standard-disabled" label="Complete Address" value="Lynville Residences Barangay AS-IS Bauan Batangas" style={{width:'100%'}}/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                              <TextField id="standard-disabled" label="Account No." value="BAJ-1234-56789" style={{width:'100%'}}/>
                          </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{marginTop:10}}>
                          <Grid item xs={12} md={4}>
                              <TextField id="standard-disabled" label="Email Address" value="jqsantos@ngcruz.com" style={{width:'100%'}}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <TextField id="standard-disabled" label="Mobile No." value="09557617359" style={{width:'100%'}}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <TextField id="standard-disabled" label="Phone No." value="123-2345-667" style={{width:'100%'}}/>
                          </Grid>
                      </Grid>
                      <Divider style={{marginTop:20,marginBottom:10}}/>
                      <UserData userProfile={selectedCustomerData}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => showDialogBranch()}>
                    Cancel
              </Button>
                <Button color="primary">
                    Inactive
              </Button>
            </DialogActions>
        </Dialog>
    )
}