import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AppsIcon from '@material-ui/icons/Apps';
import useStyles from '../stylesheet';
import {
  HashRouter as Router,
  Route,
  useParams,
  Redirect,
  Link as NewLink
} from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useSelector, useDispatch } from 'react-redux'
const drawerWidth = 240;

function TogglingProfile() {
    const theme = useTheme();
    const classes = useStyles();
    const { user_id } = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [setTimer,setsetTimer] = React.useState("")
    const [searchExecutor,setsearchExecutor] = React.useState(false)
    const [searchBranch,setsearchBranch] = React.useState("")
    const [branchDisplay, setbranchDisplay] = React.useState([]);
    const Dispatch = useDispatch();
    const navigation_reducer = useSelector(state => state.navigation_reducer)
    const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)
    const AccountData = useSelector(state => state.userDataReducers.userProfile)

    const handleClick= (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return(
        <div>
        <IconButton
            onClick={handleClick}
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit">
            <AccountCircle style={{ color: "#786fa6" }} />
        </IconButton>
        <Menu
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            id="simple-menu"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            keepMounted
            elevation={2}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <div style={{width:350}}>
                <div style={{width:'100%',height:150,display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div>
                        <center>
                            <div style={{height:80,width:80,backgroundColor:'#786fa6',borderRadius:40,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <Typography variant="h4" style={{color:'#f5f6fa'}}>{AccountData.length > 0 ? String(AccountData[0].user_fname.charAt(0)).toLocaleUpperCase() : ""}</Typography>
                            </div>
                            <Typography  style={{marginTop:10,fontSize:16,fontWeight:'600'}}>{AccountData.length > 0 ? AccountData[0].user_fname+' '+AccountData[0].user_lname : ""}</Typography>
                        </center>
                    </div>
                </div>
                <Divider/>
                <center>
                    <Button
                        style={{ height: 40, backgroundColor: '#f5f6fa',marginTop:10,borderRadius:20}}
                        size="medium"
                        onClick={()=>{
                            localStorage.clear()
                            window.location.replace('http://customer.gzonetechph.com/#/home');
                            // window.location.replace('http://localhost:3000/#/');
                            window.location.reload()
                        }}
                        variant="outlined"
                        className={classes.button}>
                        Log out account
                    </Button>
                </center>
            </div>
        </Menu>
    </div>
    )
}

export default TogglingProfile;
