import React,{useState,useRef,useEffect, useCallback  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  HashRouter ,
  Route,
  Redirect,
  Link as NewLink,
} from "react-router-dom";
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { DropzoneArea,DropzoneDialog } from "material-ui-dropzone";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AttachFileIcon from '@material-ui/icons/AttachFile'
// import UploadImages from './material/dropzone'
import {
  regions,
  provinces,
  citiesMunicipalities,
} from 'ph-locations';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import useStyles from '../../stylesheet';
import UploadPic from '../../assets/uploaduser.png'
import AlertComponent from '../../material/snackbar'
import BackgroundImageq from '../../assets/polkadots.png'
import { getData } from '../../api/api'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { useDispatch, useSelector } from 'react-redux'

import { Profile_section,Address_section } from '../../assets/formComponents'
const subuseStyles = makeStyles((theme) => ({
  subroot: {
       flexGrow: 1,
       backgroundColor: theme.palette.background.paper,
       display: 'flex',
  },
 formcontainer: {
       '& .MuiTextField-root': {
       margin: theme.spacing(1),
       width: '25ch',
       },
 },
 formControl: {
     margin: theme.spacing(1),
     minWidth: 120,
 },
 tabs: {
     borderRight: `1px solid ${theme.palette.divider}`,
 },
 cardContainer: {
     minWidth: 275,
 },
 backdrop: {
     zIndex: theme.zIndex.drawer + 1,
     color: '#fff',
 },
}));
export default function UpdateApplication() {
    const classes = useStyles();
    const subClasses = subuseStyles();
    const theme = useTheme();

    const [openDialog, setopenDialog] = React.useState(true);
    const [openLoader, setopenLoader] = React.useState(false);
    const [refreshs, setrefreshs] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(1);
    const [openSnack, setopenSnack] = React.useState(false);
    const [currDate, setcurrDate] = React.useState(new Date);

    const [branchData, setbranchData] = React.useState({
      branch_id:"",
      company_id:"",
      fieldworks:null
    });
    const [alertStyle, setalertStyle] = React.useState({
        alertMessage:'',
        alertType:'Success'
      });

    const [RegionFilter, setRegionFilter] = React.useState([]);
    const [MunicipalitiesFilter, setMunicipalitiesFilter] = React.useState([]);
    const [ProvinceFilter, setProvinceFilter] = React.useState([]);

    const [Categories, setCategories] = React.useState([]);
    const [displayAllDisabled, setdisplayAllDisabled] = React.useState("");
    const [formsValue, setformsValue] = React.useState([]);
    const [formsBackup, setformsBackup] = React.useState([]);
    const [Customertype, setCustomertype] = React.useState("");
    const [FormSelections, setFormSelections] = React.useState([]);
    const [FormSelectionsAddress, setFormSelectionsAddress] = React.useState([]);
    const Dispatch = useDispatch();
    const AccountData = useSelector(state => state.userDataReducers.userProfile)
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)
    const custData = useSelector(state => state.custDetails.selectedCust)
    const update_type = useSelector(state => state.custDetails.updateType)

    const getJotype = () => {
      setbranchData({
          branch_id:initialBranch.b_id,
          company_id:initialBranch.c_id,
          fieldworks:initialBranch.branch_field_work
      })
    };

    const reconstructValues=(filterProfile)=>{
        let new_structure = []
        for (let index = 0; index < filterProfile.length; index++) {
            if(custData[0][filterProfile[index].fd_name] !== undefined){
                let restruct={
                    name:filterProfile[index].fd_name,
                    value:custData[0][filterProfile[index].fd_name],
                    push:1
                }
                new_structure.push(restruct)
            }
        }

        if(custData[0].cust_type === "COMMERCIAL" || custData[0].cust_type === "INDUSTRIAL" || custData[0].cust_type === "GOVERNMENT"){
            let status_handler = JSON.parse(custData[0].contacts)
            new_structure.push({name:'c_fname',value:status_handler[0].c_fname,push:0})
            new_structure.push({name:'c_lname',value:status_handler[0].c_lname,push:0})
            new_structure.push({name:'c_email',value:status_handler[0].c_email,push:0})
            new_structure.push({name:'relationship',value:status_handler[0].relationship,push:0})
            new_structure.push({name:'c_mobile',value:status_handler[0].c_mobile,push:0})
        }

        setformsBackup(JSON.stringify(new_structure))
        setformsValue(new_structure)
    }

    const onChange = (event,name,push_action) => {
        const currentIndex = formsValue.findIndex(x => x.name === event.target.name)
        let holder = event.target.value
        if(name === 'region'){
            var Splitting = ""
            var Splitted = ""
            Splitting = event.target.value
            Splitted = Splitting.split("*")
            const FilterProvince = provinces.filter(function (item) {
                return item.region === Splitted[0]
            });
            if(FilterProvince.length > 0){
                setProvinceFilter(FilterProvince)
                setMunicipalitiesFilter([])
            }else{
                const FilterCities = citiesMunicipalities.filter(function (item) {
                    return item.province === null;
                });
                setProvinceFilter([])
                setMunicipalitiesFilter(FilterCities)
            }
            if(currentIndex === -1){
            let form_val = {
                name:event.target.name,
                value:holder,
                push:push_action
            }
            formsValue.push(form_val)
            }else{
            formsValue[currentIndex].value = holder
            }
        }else if(name === 'province'){
            var Splitting = ""
            var Splitted = ""
            Splitting = event.target.value
            Splitted = Splitting.split("*")
            const FilterProvince = citiesMunicipalities.filter(function (item) {
                return item.province === Splitted[0]
            });
            if(currentIndex === -1){
            let form_val = {
                name:event.target.name,
                value:holder,
                push:push_action
            }
            formsValue.push(form_val)
            }else{
            formsValue[currentIndex].value = holder
            }
            setMunicipalitiesFilter(FilterProvince)

        }else if(name === 'municipalities'){
            if(currentIndex === -1){
            let form_val = {
                name:event.target.name,
                value:holder,
                push:push_action
            }
            formsValue.push(form_val)
            }else{
            formsValue[currentIndex].value = holder
            }
            setrefreshs(!refreshs)
        }else{
            if(currentIndex === -1){
            let form_val = {
                name:event.target.name,
                value:holder,
                push:push_action
            }
            formsValue.push(form_val)
            }else{
            formsValue[currentIndex].value = holder
            }
            setrefreshs(!refreshs)
        }
    }

    const submitApplication=()=>{
        setopenLoader(true)
        const filterForms = formsValue
        let arrayStruct = {}
            for (let index = 0; index < filterForms.length; index++) {
            if(filterForms[index].push === 1){
                arrayStruct[filterForms[index].name] = filterForms[index].value
            }
        }
        // if(Customertype === "COMMERCIAL" || Customertype === "INDUSTRIAL" || Customertype === "GOVERNMENT"){
        //     let push_contacts = {}
        //     const filterPush = filterForms.filter(function (item) {
        //         return item.push === 0
        //     });
        //     for (let index = 0; index < filterPush.length; index++) {
        //         push_contacts[filterPush[index].name] = filterPush[index].value
        //     }
        //     arrayStruct.contacts = JSON.stringify([push_contacts])
        // }
        arrayStruct.app_id = '10'
        arrayStruct.company_id = branchData.company_id
        arrayStruct.branch_id = branchData.branch_id
        arrayStruct.user_id = AccountData[0].user_id
        arrayStruct.country = 'Philippines'
        arrayStruct.cust_type = Customertype
        arrayStruct.cust_id = custData[0].cust_id
        getData('jls/UpdateCustomerDetails',arrayStruct)
        .then((res)=>{
            if(res.status === 'Success'){
                setdisplayAllDisabled("disabled")
                // setformsValue([])
                setopenLoader(false)
                setalertStyle({alertMessage:'Account successfully updated',alertType:'success'})
                setopenSnack(true)
            }else{
                setopenLoader(false)
                setalertStyle({alertMessage:'Failed to update selected customer. Please try again',alertType:'error'})
                setopenSnack(true)
            }
        })
    }
    useEffect(()=>{
        getJotype()
    },[initialBranch])

    const onTypechange = (e)=>{
        const filterForm = Profile_section.filter(function (item) {
            return JSON.stringify(item.fd_type).includes(e)
        });
        let formsHandler = formsValue
        for (let index = 0; index < FormSelections.length; index++) {
            const findTitleIndx = formsHandler.findIndex(x => x.name === FormSelections[index].fd_name)
            if(findTitleIndx > -1){
                formsHandler[findTitleIndx].value = ""
            }
        }
        if(custData[0].cust_type === e){
            setformsValue(JSON.parse(formsBackup))
        }else{
            setformsValue(formsHandler)
        }
        setFormSelections(filterForm)
        setCustomertype(e)
    }

    useEffect(()=>{
        const filterProfile = Profile_section.filter(function (item) {
            return JSON.stringify(item.fd_type).includes(custData[0].cust_type)
        });
        let concatination = filterProfile.concat(Address_section)
        setformsValue([])
        setCustomertype(custData[0].cust_type)
        setFormSelectionsAddress(Address_section)
        setFormSelections(filterProfile)
        setRegionFilter(regions)
        reconstructValues(concatination)
    },[])
   
    return(
        <div className={classes.root}>
            <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
            <Breadcrumbs aria-label="breadcrumb" gutterBottom>
                <Typography color="textPrimary">Home Page</Typography>
                <Typography color="textPrimary">Application</Typography>
                <Link>Update application</Link>
            </Breadcrumbs>
            <Grid container spacing={2} style={{marginTop:10}}>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={8}>
                    <Card variant="outlined">
                        <CardContent>
                            <FormControl   className={classes.formControl} required={true}  variant="outlined" size="small">
                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Customer type:</b></Typography>
                                <Select
                                    disabled={displayAllDisabled === "disabled" ? true : false}
                                    style={{
                                        backgroundColor:displayAllDisabled === "disabled" ? '#ced6e0' : '#fff',
                                        color: displayAllDisabled === "disabled" ? "#2f3542" : ""}}
                                    onChange={(e)=>{onTypechange(e.target.value) }}  
                                    size="small"
                                    native
                                    value={Customertype}
                                    inputProps={{
                                        name: 'cust_type',
                                        id: 'filled-age-native-simple',
                                    }}>
                                    <option aria-label="None" value="" />
                                    <option value="RESIDENTIAL">RESIDENTIAL</option>
                                    <option value="COMMERCIAL">COMMERCIAL</option>
                                    <option value="INDUSTRIAL">INDUSTRIAL</option>
                                    <option value="GOVERNMENT">GOVERNMENT</option>
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                    <Divider style={{backgroundColor:'#574b90',marginTop:10,marginBottom:10,height:5}}/>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container spacing={1} style={{marginTop:10}}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Profile section</b></Typography>
                                </Grid>
                                {FormSelections.map((val,index)=>{
                                    const findTitleIndx = formsValue.findIndex(x => x.name === val.fd_name)
                                    return<Grid item xs={12} md={val.row_no} key={index}>
                                        {val.fd_category !== "dropdown"
                                            ?<FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label} {val.fd_required === true ? "*" : ""} </Typography>
                                                <TextField
                                                    inputProps={{ style: { color: displayAllDisabled === "disabled" ? "#2f3542" : ""} }}
                                                    style={{backgroundColor:displayAllDisabled === "disabled" ? '#ced6e0' : '#fff',}}
                                                    disabled={displayAllDisabled === "disabled"
                                                        ? true
                                                        :val.push === 0 ? true : false
                                                    }
                                                    name={val.fd_name}
                                                    type={val.fd_category}
                                                    required={val.fd_required === true ? true : false} 
                                                    value={findTitleIndx === -1 ? "" : formsValue[findTitleIndx].value}
                                                    onChange={(e) => onChange(e,val.fd_name,val.push)}
                                                    id="outlined-size-small"
                                                    variant="outlined"
                                                    size="small"/>
                                            </FormControl>
                                            : <FormControl  
                                                required={val.fd_required === true ? true : false} 
                                                className={classes.formControl} variant="outlined" size="small">
                                                <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label} {val.fd_required === true ? "*" : ""}</Typography>
                                                <Select
                                                    disabled={displayAllDisabled === "disabled" ? true : false}
                                                    style={{
                                                        backgroundColor:displayAllDisabled === "disabled" ? '#ced6e0' : '#fff',
                                                        color: displayAllDisabled === "disabled" ? "#2f3542" : ""}}
                                                    onChange={(e)=>onChange(e,val.fd_name,val.push)}  
                                                    size="small"
                                                    native
                                                    value={findTitleIndx === -1 ? "" : formsValue[findTitleIndx].value}
                                                    inputProps={{
                                                        name: val.fd_name,
                                                        id: 'filled-age-native-simple',
                                                    }}>
                                                    <option aria-label="None" value="" />
                                                {val.fd_values.map((ary,index2)=>{
                                                    return <option aria-label="None" value={ary} key={index2}>{ary}</option>
                                                })}
                                                </Select>
                                            </FormControl>
                                        }
                                    </Grid>
                                })}
                                <Grid item xs={12} md={12} style={{marginTop:15}}>
                                    <Typography variant="h5" > <b style={{color:'#786fa6',}}>Address section</b></Typography>
                                </Grid>
                                {FormSelectionsAddress.map((val,index)=>{
                                    const findTitleIndx = formsValue.findIndex(x => x.name === val.fd_name)
                                    if(val.fd_category === 'input'){
                                        return<Grid item xs={12} md={val.row_no} key={index}>
                                            <FormControl style={{ width: '100%', }} variant="outlined" size="small">
                                                <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label} {val.fd_required === true ? "*" : ""}</Typography>
                                                <TextField
                                                    inputProps={{ style: { color: displayAllDisabled === "disabled" ? "#2f3542" : ""} }}
                                                    disabled={displayAllDisabled === "disabled" ? true : false}
                                                    style={{ backgroundColor:displayAllDisabled === "disabled" ? '#ced6e0' : '#fff',}}
                                                    required={val.fd_required === true ? true : false} 
                                                    value={findTitleIndx === -1 ? "" : formsValue[findTitleIndx].value}
                                                    onChange={(e) => onChange(e,val.fd_name,val.push)}
                                                    id="outlined-size-small"
                                                    name={val.fd_name}
                                                    variant="outlined"
                                                    size="small"/>
                                            </FormControl>
                                        </Grid>
                                    }else if(val.fd_category === 'dropdown'){
                                        return<Grid item xs={12} md={val.row_no} key={index}>
                                                <FormControl  
                                                    required={val.fd_required === true ? true : false} 
                                                    className={classes.formControl} variant="outlined" size="small">
                                                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label} {val.fd_required === true ? "*" : ""}</Typography>
                                                    <Select
                                                        disabled={displayAllDisabled === "disabled" ? true : false}
                                                        style={{
                                                            backgroundColor:displayAllDisabled === "disabled" ? '#ced6e0' : '#fff',
                                                            color: displayAllDisabled === "disabled" ? "#2f3542" : ""}}
                                                        size="small"
                                                        native
                                                        value={findTitleIndx === -1 ? "" : formsValue[findTitleIndx].value}
                                                        onChange={(e)=>onChange(e,val.fd_name,val.push)} 
                                                        inputProps={{
                                                            name: val.fd_name,
                                                            id: 'filled-age-native-simple',
                                                        }}>
                                                        <option aria-label="None" value="" />
                                                        {val.fd_label === 'Region' &&
                                                            <>
                                                                {RegionFilter.map((dropdownVal,index2)=>{
                                                                    return <option aria-label="None" value={dropdownVal.code+'\*/'+dropdownVal.name} key={index2}>{dropdownVal.name}</option>
                                                                })}
                                                            </>
                                                        }
                                                        {val.fd_label === 'Province' &&
                                                            <>
                                                                {ProvinceFilter.map((dropdownVal,index2)=>{
                                                                    return <option aria-label="None" value={dropdownVal.code+'\*/'+dropdownVal.name} key={index2}>{dropdownVal.name}</option>
                                                                })}
                                                            </>
                                                        }
                                                        {val.fd_label === 'City/Town' &&
                                                            <>
                                                                {MunicipalitiesFilter.map((dropdownVal,index2)=>{
                                                                    return <option aria-label="None" value={dropdownVal.province+'\*/'+dropdownVal.name} key={index2}>{dropdownVal.name}</option>
                                                                })}
                                                            </>
                                                        }
                                                    </Select>
                                                </FormControl>
                                        </Grid>
                                    }
                                })}
                            </Grid>
                        </CardContent>
                        <CardActions style={{width:'100%'}}>
                            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                                {displayAllDisabled === "disabled"
                                    ?<> 
                                        <Button  
                                            onClick={()=>setdisplayAllDisabled("")}
                                            style={{backgroundColor:'#474787',color:'#fff',marginRight:5}}>
                                            Edit
                                        </Button>
                                        <NewLink to={update_type === "master_" ? "/masterdata/" : '/pending/'} style={{ textDecoration: 'none', color: 'black' }}>
                                            <Button  
                                                style={{backgroundColor:'#2e86de',color:'#fff'}}
                                                onClick={()=>{
                                                    Dispatch({
                                                        type:'customer_account_',
                                                        data:{
                                                            selectedCust:[],
                                                            updateType:""
                                                        }
                                                    })
                                                }}>
                                                Done
                                            </Button>
                                        </NewLink>
                                    </>
                                    :<>  
                                        <NewLink to={update_type === "master_" ? "/masterdata/" : '/pending/'} style={{ textDecoration: 'none', color: 'black',marginRight:5 }}>
                                            <Button  
                                                style={{backgroundColor:'#8395a7',color:'#fff'}}
                                                onClick={()=>{
                                                    Dispatch({
                                                        type:'customer_account_',
                                                        data:{
                                                            selectedCust:[],
                                                            updateType:""
                                                        }
                                                    })
                                                }}>
                                                Cancel
                                            </Button>
                                        </NewLink>
                                        <Button  
                                            onClick={()=>submitApplication()}
                                            style={{backgroundColor:'#2e86de',color:'#fff'}}>
                                            Update
                                        </Button>
                                    </>
                                }
                               
                               
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={2}></Grid>
            </Grid>
           
            <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:9999}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}