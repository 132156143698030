const initialState = {
    initialSelectedBranch: [],
    configureIdentificator: "",
}
const BranchHandled = (state = initialState, action) => {
    switch (action.type) {
        case 'passInitialBranch':
            return {
                ...state,
                initialSelectedBranch: action.passSelectedBranch
            }
        case 'passConfiguration':
            return {
                ...state,
                configureIdentificator: action.passIdentificator
            }
        default:
            return state;
    }
}
export default BranchHandled; 