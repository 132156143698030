import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

export default function AssigningHeader({onChangeFieldwork,selectedFieldwork,fieldworks,onChangejoDate,joborderDate,fetch_request}) {
    const classes = useStyles();
    return (
        <Grid container spacing={1} style={{ marginBottom: 5 }}>
            <Grid item xs={12} md={3}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search Customer</b></Typography>
                    <TextField
                        style={{backgroundColor:'#f5f6fa'}}
                        // value={insertedSearch}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">
                                <IconButton
                                    // onClick={() => filterSearch()}
                                    aria-label="toggle password visibility" edge="start">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }} />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2} >
                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    required={true}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Job order:</b></Typography>
                    <Select
                        style={{backgroundColor:'#f5f6fa'}}
                        onChange={onChangeFieldwork}
                        size="small"
                        native
                        value={selectedFieldwork}
                        inputProps={{
                            name: "branch",
                            id: 'filled-age-native-simple',
                        }}>
                        <option aria-label="None" value="" />
                        {fieldworks != null &&
                            <>
                                {fieldworks === ""
                                    ? <></>
                                    : <>
                                        {JSON.parse(fieldworks).map((ary, index2) => {
                                            return <option aria-label="None" value={ary} key={index2}>{ary}</option>
                                        })}
                                    </>
                                }
                            </>
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2} >
                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Job Date:</b></Typography>
                <TextField
                    style={{backgroundColor:'#f5f6fa'}}
                    variant="outlined"
                    size="small"
                    id="date"
                    type="date"
                    name={joborderDate}
                    onChange={(e) => onChangejoDate(e)}
                    required={true}
                    value={moment(joborderDate).format('YYYY-MM-DD')}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: false,
                    }} />
            </Grid>
            <Grid item xs={12} md={1}>
                <Typography style={{ color: '#786fa6', fontSize: 15 }}> &nbsp;</Typography>
                <Button onClick={() => {fetch_request()}} style={{height: 40, alignSelf: 'flex-end', width: '100%', backgroundColor: '#574B90', color: '#fff' }}> Submit</Button>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
        </Grid>
    );
} 