import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
export default function DialogViewassign({unAssignDialog,handleCloseUnassign,selectedtoUnassign,selectTorevert,handleCheckUnassign,submitUnassign}) {
    const subClasses = subStyles();
    const classes = useStyles();

    return (
        <Dialog
        open={unAssignDialog}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleCloseUnassign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title">Assigned to: {selectedtoUnassign.length > 0 ? selectedtoUnassign[0].fieldmanData.completename+' - '+selectedtoUnassign[0].assigned.length+' account(s)' : ""}</DialogTitle>
            <DialogContent>
                <Card variant="outlined" style={{ marginTop: 10, }}>
                    <CardContent>
                        <List component="nav" className={classes.root} aria-label="contacts">
                            <Paper>
                                <TableContainer size="small" className={subClasses.tableContainer}>
                                    <Table size="small"
                                        stickyHeader
                                        style={{ whiteSpace: "nowrap" }}  >
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "blue" }}>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}></StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Jo id</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Account_No.</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>MRU</StyledTableCell>

                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Account_Name</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Address</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedtoUnassign.length > 0 &&
                                            <>
                                                {selectedtoUnassign[0].assigned.map((ary, index) => {
                                                    return <TableRow hover tabIndex={-1} key={index}>
                                                        <TableCell align="left">
                                                            <Checkbox
                                                                onClick={() => handleCheckUnassign(ary)}
                                                                edge="start"
                                                                checked={selectTorevert.findIndex(x => String(x.jo_id).toUpperCase() === String(ary.jo_id).toUpperCase()) !== -1}
                                                                tabIndex={-1}
                                                                disableRipple />
                                                        </TableCell>
                                                        <TableCell align="left">{ary.jo_id}</TableCell>
                                                        <TableCell align="left">{ary.account_number}</TableCell>
                                                        <TableCell align="left">{ary.mru === null ? "n/a" : ary.mru}</TableCell>
                                                        <TableCell align="left">{ary.name}</TableCell>
                                                        <TableCell align="left">{ary.address}</TableCell>

                                                    </TableRow>
                                                })}
                                            </>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </List>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseUnassign} color="primary"> Cancel</Button>
                <Button onClick={()=>{submitUnassign(selectedtoUnassign)}} color="primary" autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}