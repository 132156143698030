import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print'
import moment from 'moment';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { getData } from '../api/api'


import PersonalInformation from './personInfo'
import AddressInformation from './addressInfo'
import ContactInformation from './contactInfo'
import MeterInformation from './meterInfo'
import BillInformation from './billInfo'
import useStyles from '../stylesheet';

const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function UserData({userProfile,selectedHistory}) {
  const classes = useStyles();
  const theme = useTheme();
  const subClasses = subStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [disable_Identificator, setdisable_Identificator] = React.useState({
    min_step:0,
  });
  const [accountHistory, setaccountHistory] = React.useState([]);
  const [categorySelected, setcategorySelected] = React.useState(["Personal Information","Address Information","Contact Information","Meter Information","Account History"]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getuserHistory=()=>{
    var curDate = new Date();
    let y = curDate.getFullYear();
    let m = curDate.getMonth();
    let dateStart = new Date(y,m, + 1);
    let dateEnd = new Date(y,m + 1, 0);
    
    let structure = {
      date_start:moment(dateStart).format('YYYY-MM-DD'),
      date_end:moment(dateEnd).format('YYYY-MM-DD'),
      branch_id:userProfile[0].branch_id,
      account_number:userProfile[0].account_no
    }
    getData('aam/meter_tracking_history',structure)
    .then((res)=>{
      setaccountHistory(res)
    })
  }

  const triggerHistory=(specificHistory)=>{
    let restruct = specificHistory
    selectedHistory(JSON.stringify([restruct]))
  }

  useEffect(()=>{
    if(userProfile[0].cust_type === "COMMERCIAL" || userProfile[0].cust_type === "INDUSTRIAL" || userProfile[0].cust_type === "GOVERNMENT"){
      setActiveStep(1)
      setdisable_Identificator({
        min_step:1,
      })
    }
    getuserHistory()
  },[])
  return (
    <div>
        <Grid container  style={{marginBottom:15}}>
            <Grid item xs={12} md={6}>
                <div style={{marginTop:10}}>
                <Typography variant="h6" gutterBottom> <b style={{color:'#4b4b4b'}}>{categorySelected[activeStep]}</b></Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={6} >
            <MobileStepper
                variant="dots"
                steps={5}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === 4}>
                    Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === disable_Identificator.min_step}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                    </Button>
                }
                />
            </Grid>
        </Grid>
        <Grid container  style={{marginBottom:15}}>
            <Grid item xs={12} md={12}>
                <div style={{width:'100%',height:50}}>
                    {activeStep === 0 &&
                        <PersonalInformation userProfile={userProfile}/>
                    }
                    {activeStep === 1 &&
                        <AddressInformation userAddress={userProfile}/>
                    }
                    {activeStep === 2 &&
                        <ContactInformation userContact={userProfile}/>
                    }
                    {activeStep === 3 &&
                        <MeterInformation userMeter={userProfile}/>
                    }
                    {activeStep === 4 &&
                        <BillInformation userBill={userProfile} accountHistory={accountHistory} triggerHistory={triggerHistory}/>
                    } 
                    
                </div>
            </Grid>
        </Grid>

    </div>
  );
}