import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import PrintIcon from '@material-ui/icons/Print'
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    HashRouter ,
    Route,
    useParams,
    Redirect,
    Link as NewLink,
  } from "react-router-dom";
import UploadPic from '../assets/uploaduser.png'
import useStyles from '../stylesheet';
import UserData from '../customerDetails/userDetails'
import { useDispatch,useSelector } from 'react-redux'
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

export default function DialogViewCustomer({displayContainer,selectedCustomerData,selectedHistory,handleCloseDialog}) {
    const subClasses = subStyles();
    const classes = useStyles();

    const Dispatch = useDispatch();
  
    return (
        <Dialog
            open={displayContainer}
            fullScreen
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
            <DialogTitle id="form-dialog-title">Customer Master Data</DialogTitle>
            {selectedCustomerData.map((value,index)=>{
                let nameDisplay = ""
                let mobileno = "N/A"
                let telephone = "N/A"
                if(value.cust_type === "COMMERCIAL" || value.cust_type === "INDUSTRIAL" || value.cust_type === "GOVERNMENT"){
                    nameDisplay = value.business_name
                }else{
                    nameDisplay = value.cust_fname+' '+value.cust_lname
                }

                if(value.cust_contact_mobile != null){
                    mobileno = value.cust_contact_mobile
                }  
                if(value.cust_telephone != null){
                    telephone = value.cust_telephone
                }
                return<DialogContent key={index} style={{height:'60vh'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2} />
                        <Grid item xs={12} md={8}>
                        <Grid container spacing={1} style={{marginBottom:10}}>
                            <Grid item xs={12} md={4}>
                            <div style={{ width: '100%', height: 360, backgroundColor: '#dfe4ea', position: 'relative',display:'flex',justifyContent:'center',alignItems:'center' }}>
                            <center>
                                <img src={UploadPic} style={{ width: 200, height: 200}} />
                                </center>
                            </div>
                            <Button  
                                onClick={()=>{}}
                                size='small' 
                                color="primary"
                                variant="outlined" 
                                style={{ backgroundColor:'#f5f6fa',color: "#786fa6",width:'100%',marginTop:10,height:40}} 
                                className={classes.button} endIcon={<PrintIcon />}>Upload profile</Button>
                            </Grid>
                            <Grid item xs={12} md={8}>
                            <Card variant="outlined">
                                <CardContent>
                                <Typography variant="h5" gutterBottom style={{ color: '#786fa6' }}> <b>Account Information</b></Typography>
                                <Grid container spacing={1} style={{ marginBottom: 10 }}>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Name</b></Typography>
                                        <TextField
                                        value={nameDisplay}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Customer Type</b></Typography>
                                        <TextField
                                            value={value.cust_type === "" || value.cust_type === null ? 'N/A' : value.cust_type}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Account No.</b></Typography>
                                        <TextField
                                        value={value.account_no === "" ? 'N/A' : value.account_no}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Contact Email</b></Typography>
                                        <TextField
                                        value={value.cust_contact_email}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Customer Address</b></Typography>
                                        <TextField
                                        value={value.cust_address}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Coordinates</b></Typography>
                                        <TextField
                                        value="0.0,0.0"
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Account Status</b></Typography>
                                        <TextField
                                        value={value.acct_status === null ? "N/A" : value.acct_status}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Contact No.</b></Typography>
                                        <TextField
                                        value={mobileno + ' | ' + telephone}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small" />
                                    </FormControl>
                                    </Grid>
                                </Grid>
                                </CardContent>
                            </Card>
                            </Grid>
                        </Grid>
                        <UserData userProfile={selectedCustomerData} selectedHistory={selectedHistory}/>
                        </Grid>
                        <Grid item xs={12} md={2} />
                    </Grid>
                </DialogContent>
            })}
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary"> Cancel</Button>
                <NewLink to='/updateAccount/' style={{ textDecoration: 'none', color: 'black' }}>
                    <Button 
                        onClick={()=>{
                            Dispatch({
                                type:'customer_account_',
                                data:{
                                    selectedCust:selectedCustomerData,
                                    updateType:"master_"
                                }
                            })
                        }} 
                        color="primary" autoFocus>
                        Edit
                    </Button>
                </NewLink>
                <Button 
                    color="primary" autoFocus>
                    Inactive
                </Button>
               
            </DialogActions>
        </Dialog>
    );
}