import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import Routing from './components/router/router'
import {
  HashRouter ,
  Route,
  useParams,
  Router,
  Redirect
} from "react-router-dom";




function App() {

  const [state, setState] = React.useState({
    loginData: {
        app_id: 0,
        user_id: localStorage.getItem("u"),
    }
  });

const getUrlVars = () => {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
  });
  return vars;
}

useEffect(async () => {
  localStorage.setItem('login_state', 0)
  if (typeof localStorage.getItem("u") === "object") {
      if (typeof getUrlVars()["u"] !== "undefined") {
          localStorage.setItem("u", getUrlVars()['u']);
          // setTimeout(()=>{
            // window.location.reload()
          // },100)
          // window.location.replace('http://customer.gzonetechph.com/#/home');
      } else {
          localStorage.setItem("cookies", 1);
          window.location.replace("https://accounts.workflow.gzonetechph.com/?goto=" + window.location + "&app=" + state.loginData.app_id);
      }
  }else{
    window.location.replace('http://customer.gzonetechph.com/#/');
    // window.location.replace('http://192.168.2.42:3000/#/');
  }
}, [])


  return (
    <HashRouter>
      {/* <Route exact path="/:user_id" component={LoadingScreen} /> */}
      {/* <Route  exact path="/" component={Loginpg} /> */}
      <Route  path="/" component={Routing} />
      {/* <Route path="/pendingform" component={MainNav} />
      <Route path="/NewApplicationForm" component={NewApplicationForm} /> */}
    </HashRouter>
  );
}

export default App; 