import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
export default function DialogAssign({showDialog,selectedAccount,handleClose,insertedSearch,fieldmanData,selectedFieldman,selectFieldman,submitCreate}) {
    const subClasses = subStyles();
    const classes = useStyles();
    return (
        <Dialog
        open={showDialog}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
            <DialogTitle id="alert-dialog-title">Assign fieldman</DialogTitle>
            <DialogContent>
                <div style={{marginBottom:10,height:100,width:'100%',backgroundColor:'#487eb0'}}>
                    <div style={{position:'relative',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Typography variant="h4" gutterBottom style={{color:'#fff',marginTop:5}}> <b>{selectedAccount.length}</b></Typography>
                        <div style={{position:'absolute',bottom:1,width:'100%',display:'flex',justifyContent:'center'}}>
                            <Typography variant="h5" style={{color:'#fff',fontSize:13,marginBottom:10}}> Selected Account(s) </Typography>
                        </div>
                    </div>
                </div>
                <FormControl style={{width:'100%',marginTop:10}}>
                    <TextField
                    value={insertedSearch}
                    label="Search fieldman name"
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                    // onChange={(val)=>setinsertedSearch(val.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">
                            <IconButton  
                                // onClick={()=>filterSearch()}
                                aria-label="toggle password visibility" edge="start">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }} />
                </FormControl>
                <List component="nav" className={classes.root} aria-label="contacts">
                    {fieldmanData.map((val,index)=>{
                        return <>
                        <ListItem button key={index} 
                            onClick={()=>selectFieldman(val)} 
                            style={{backgroundColor:val.user_id === selectedFieldman.fieldmanId ? '#3c6382' : '#fff'}}> 
                            <ListItemText primary={val.completename} style={{color:val.user_id === selectedFieldman.fieldmanId ? '#fff' : '#3d3d3d'}}/>
                        </ListItem>
                        <Divider component="li" />
                        </>
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary"> Cancel</Button>
                <Button onClick={()=>submitCreate()} color="primary" autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}