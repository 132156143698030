import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment'
import { useDispatch,useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
      margin: {
        margin: theme.spacing(1),
      },
  }));
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
export default function MasterTable({rowsPerPage,page,tableLength,handleChangePage,handleChangeRowsPerPage,handleOpenDialog}) {
    const subClasses = subStyles();
    const CustomerData = useSelector(state => state.differentCustomer.masterData)
    
 
    return (
        <Paper variant="outlined">
            <TableContainer size="small" className={subClasses.tableContainer}>
                <Table size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}  >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: "#786fa6",  color: "#fff" }} align="left"> # </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6",  color: "#fff" }} align="left"> Action </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6",  color: "#fff" }} align="left"> Account No. </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6",  color: "#fff" }} align="left"> Status </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Name </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Customer Type </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Email Address </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Date Added </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Approve Date </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {CustomerData.map((ary,index) => {
                            let nameDisplay = ""
                            if(ary.cust_type === "COMMERCIAL" || ary.cust_type === "INDUSTRIAL" || ary.cust_type === "GOVERNMENT"){
                                nameDisplay = ary.business_name
                            }else{
                                nameDisplay = ary.cust_fname+' '+ary.cust_lname
                            }
                            return<TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="left">{index+(rowsPerPage*page)+1}</TableCell>
                                    <TableCell align="left">
                                        <IconButton 
                                            size="small"
                                            onClick={()=>handleOpenDialog(ary)}
                                            aria-label="delete" className={subClasses.margin}>
                                            <VisibilityIcon fontSize="small" style={{color:'#2980b9'}}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="left"><Typography style={{color: "#4b4b4b"}}>{ary.account_no === "" ? 'N/A' : ary.account_no}</Typography></TableCell>
                                    <TableCell  align="left" style={{backgroundColor: ary.cust_status === "Approved" ? '#2d98da': '#7f8c8d',color:'#fff'}}> {ary.cust_status === null ? 'N/A' : ary.cust_status}</TableCell>
                                    <TableCell  align="left">{nameDisplay}</TableCell>
                                    <TableCell  align="left">{ary.cust_type === null ? 'N/A' : ary.cust_type}</TableCell>
                                    <TableCell  align="left">{ary.cust_contact_email === null ? 'N/A' : ary.cust_contact_email}</TableCell>
                                    <TableCell  align="left"><a class="view">{ary.cust_date_added === null ? 'N/A' : moment(ary.cust_date_added).format('MMM DD, YYYY')}</a></TableCell>
                                    <TableCell  align="left"><a class="view">{ary.approved_date === "0000-00-00 00:00:00" ? 'N/A' : moment(ary.approved_date).format('MMM DD, YYYY')}</a></TableCell>
                              </TableRow>
                          })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10,50, 100]}
                component="div"
                count={tableLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>
    );
}