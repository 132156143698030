import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux'
export default function PendingHeader({onChange,fetchCustomer,searchedName,handleToggle,cust_type,clearFilter,selectedStatus,submitIdentifier}) {
    const classes = useStyles();
    const [anchorFilterby, setAnchorFilterby] = React.useState(null);
    const [anchorValue, setAnchorValue] = React.useState(null);
    const [typeArr_, settypeArr_] = React.useState(["All","Residential","Commercial","Industrial","Government"]);
    const [statusArr_, setstatusArr_] = React.useState(["All","Pending","Hold"]);
    const PendingCustomers = useSelector(state => state.differentCustomer.pendingCustomer)

    const handleClick= (event) => {
        setAnchorFilterby(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorFilterby(null);
    };
    useEffect(()=>{
        handleClose()
    },[PendingCustomers.length])
    return (
        <Grid container spacing={1} style={{marginBottom:10}}>
            <Grid item xs={12} md={3}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search customer's name</b></Typography>
                    <TextField
                        style={{backgroundColor:'#f5f6fa'}}
                        value={searchedName}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {fetchCustomer()}}
                                    aria-label="toggle password visibility" edge="start">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }} />
                </FormControl>
            </Grid>
             <Grid item xs={12} md={5}>
                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                <Button
                    style={{ height: 40, backgroundColor: '#f5f6fa',marginRight:5 }}
                    size="medium"
                    onClick={handleClick}
                    variant="outlined"
                    className={classes.button}
                    endIcon={<ArrowDropDownIcon />}>
                    FILTER TYPE {cust_type === "" ? "" : ": "+cust_type}
                </Button>
                {(cust_type !== "" || submitIdentifier === true) &&
                    <Button
                    style={{ height: 40, backgroundColor: '#8395a7',color:'#fff'}}
                    size="medium"
                    onClick={clearFilter}
                    variant="outlined"
                    className={classes.button}
                    endIcon={<ClearIcon />}>
                    Clear
                </Button>
                }
                <Menu
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    id="simple-menu"
                    elevation={1}
                    getContentAnchorEl={null}
                    anchorEl={anchorFilterby}
                    keepMounted
                    open={Boolean(anchorFilterby)}
                    onClose={handleClose}>
                    <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20}}>
                        <Card variant="outlined" style={{ marginBottom: 10 }}>
                            <CardContent >
                                {typeArr_.map((val,index)=>{
                                    return<MenuItem key={index} onClick={()=>handleToggle(val)}>{String(val).toLocaleUpperCase()}</MenuItem>
                                })}
                            </CardContent>
                        </Card>
                    </div>
                </Menu>
             </Grid>
            <Grid item xs={12} md={4}>
                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                <div style={{width:'100%',display:'flex',justifyContent:'flex-end',marginTop:5}}>
                    <Typography variant="h5" style={{ color: '#786fa6',}}> <b>Status: {selectedStatus}</b></Typography>
                </div>
            </Grid>
           
        </Grid>
    );
}