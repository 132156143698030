import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useDispatch,useSelector } from 'react-redux'
import AlertComponent from '../../material/snackbar'
import useStyles from '../../stylesheet';
import { getData } from '../../api/api'
import HistoryFilter from './historyhead'
import HistoryTable from './historyRec'
import LoadingGif from '../../assets/loading.gif'
import TodayChart from './graph/today'
import Percentages from './graph/total'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        width: '100%',
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        height: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  }));

export default function SalesHistory() {
  const classes = useStyles();
  const subClasses = subStyles();

  const [searchedName, setsearchedName] = React.useState("");
  const [displaysales, setdisplaysales] = React.useState(false);

  const [openLoader, setopenLoader] = React.useState(false);
  const [initialDialog, setinitialDialog] = React.useState(false);
  const [history_type, setHistory_type] = React.useState("");
  const [tableLength, settableLength] = React.useState(0);
  const [submitIdentifier, setsubmitIdentifier] = React.useState(false);
  const [openSnack, setopenSnack] = React.useState(false);
  const [alertStyle, setalertStyle] = React.useState({
      alertMessage:'',
      alertType:'Success'
  });
  const [branchData, setbranchData] = React.useState({
    branch_id:"",
    company_id:"",
    fieldworks:null
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sevendaysArr_, setsevendaysArr_] = React.useState([]);

  const Dispatch = useDispatch();
  const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)
  
  const getJotype = () => {
    setbranchData({
        branch_id:initialBranch.b_id,
        company_id:initialBranch.c_id,
        fieldworks:initialBranch.branch_field_work
    })
    fetchHistory(initialBranch.c_id,initialBranch.b_id,page,searchedName,history_type)
  };

  const fetchHistory=(c_id,b_id,newPage,search,status_)=>{
    setopenLoader(true)
    let history_ = {
      company_id:c_id,
      branch_id:b_id,
      page:newPage+1,
      limit:rowsPerPage,
      status:status_,
      search:search
    }
    getData('inventory/GetSalesHistory', history_)
    .then((res) => {
      setopenLoader(false)
      Dispatch({
        type:'customer_account_',
        data:{
          history:res.sales
        }
      })
      if (typeof res.weekly !== 'undefined') {
        setsevendaysArr_(res.weekly)
      }
      settableLength(res.sales_count)
      var snackMessage = res.sales_count + ' record(s) found!'
      setalertStyle({ alertMessage: snackMessage, alertType: 'Success' })
      setopenSnack(true)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchHistory(branchData.company_id,branchData.branch_id,newPage,searchedName,history_type)
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

  const fetchCustomer=()=>{
    setopenLoader(true)
    if(searchedName === ""){
      setalertStyle({alertMessage:'Incorrect entry, Please try again',alertType:'Error'})
      setopenSnack(true)
      setopenLoader(false)
    }else{
      setsubmitIdentifier(true)
      Dispatch({
        type:'customer_account_',
        data:{
          history:[]
        }
      })
      fetchHistory(branchData.company_id,branchData.branch_id,0,searchedName,history_type)
    }
  }

  const onChange = (event) => {
    var valuesearch = event.target.value
    setsearchedName(valuesearch);
  };

  const onChangeHisttype=(e)=>{
    setHistory_type(e)
    Dispatch({
      type:'customer_account_',
      data:{
        history:[]
      }
    })
    fetchHistory(branchData.company_id,branchData.branch_id,0,searchedName,e)
  }

  const clearFilter=()=>{
    setHistory_type("")
    setsearchedName("")
    setsubmitIdentifier(false)
    Dispatch({
      type:'customer_account_',
      data:{
        history:[]
      }
    })
    setTimeout(()=>{
        fetchHistory(branchData.company_id,branchData.branch_id,0,"","")
    },200)
  }
  // E55F7A14-2864-433C-8EDD-16EDFC843230
  useEffect(()=>{
    getJotype()
  },[initialBranch])
  return (
    <div className={classes.root}>
        <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
        <Breadcrumbs aria-label="breadcrumb" gutterBottom>
          <Typography color="textPrimary">Home Page</Typography>
          <Typography color="textPrimary">CRM</Typography>
          <Typography color="textPrimary">Sales</Typography>
          <Link>History</Link>
        </Breadcrumbs>

        <Card variant="outlined">
          <CardContent>
            <Card variant="outlined" style={{marginBottom:10}}>
              <CardContent>
                <Grid container spacing={1} >
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6"> <b style={{color:'#786fa6'}}>Weekly Sales</b></Typography>
                          <TodayChart sevendaysArr_={sevendaysArr_}/>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6"> <b style={{color:'#786fa6'}}>Sales Percentage</b></Typography>
                        <Percentages/>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container >
              <Grid item xs={12} md={12} >
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5"> <b style={{color:'#786fa6',marginBottom:30}}>Sales History</b></Typography>
                    <HistoryFilter
                      onChange={onChange}
                      searchedName={searchedName}
                      fetchHistory={fetchHistory}
                      onChangeHisttype={onChangeHisttype}
                      history_type={history_type}
                      clearFilter={clearFilter}
                      fetchCustomer={fetchCustomer}
                      submitIdentifier={submitIdentifier}/>
                    <HistoryTable
                      rowsPerPage={rowsPerPage}
                      tableLength={tableLength}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}/>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
       
        <Backdrop className={subClasses.backdrop} open={openLoader}>
          <img src={LoadingGif} style={{ width: 100, height: 100}} />
        </Backdrop>                                   
    </div>
  );
}