import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  HashRouter ,
  Route,
  Redirect,
  Link as NewLink,
} from "react-router-dom";
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { DropzoneArea,DropzoneDialog } from "material-ui-dropzone";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AttachFileIcon from '@material-ui/icons/AttachFile'
// import UploadImages from './material/dropzone'
import {
  regions,
  provinces,
  citiesMunicipalities,
} from 'ph-locations';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import useStyles from '../../stylesheet';
import UploadPic from '../../assets/uploaduser.png'
import AlertComponent from '../../material/snackbar'
import BackgroundImageq from '../../assets/polkadots.png'
import { getData } from '../../api/api'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const subuseStyles = makeStyles((theme) => ({
  subroot: {
       flexGrow: 1,
       backgroundColor: theme.palette.background.paper,
       display: 'flex',
  },
 formcontainer: {
       '& .MuiTextField-root': {
       margin: theme.spacing(1),
       width: '25ch',
       },
 },
 formControl: {
     margin: theme.spacing(1),
     minWidth: 120,
 },
 tabs: {
     borderRight: `1px solid ${theme.palette.divider}`,
 },
 cardContainer: {
     minWidth: 275,
 },
 backdrop: {
     zIndex: theme.zIndex.drawer + 1,
     color: '#fff',
 },
}));
export default function AddressSection({onChange,FormSelectionsAddress,formsValue,RegionFilter,ProvinceFilter,MunicipalitiesFilter}) {
    const classes = useStyles();
    const subClasses = subuseStyles();
    const theme = useTheme();

    const [openDialog, setopenDialog] = React.useState(true);
    const [openLoader, setopenLoader] = React.useState(false);
    const [refreshs, setrefreshs] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(1);
    const [openSnack, setopenSnack] = React.useState(false);
    const [currDate, setcurrDate] = React.useState(new Date);
    const [alertStyle, setalertStyle] = React.useState({
        alertMessage:'',
        alertType:'Success'
      });

    const [Categories, setCategories] = React.useState([]);
    const [formsTitle, setformsTitle] = React.useState([]);
  
  
    useEffect(()=>{
    },[])
    return(
        <Grid container spacing={1} style={{marginTop:10}}>
            {FormSelectionsAddress.map((val,index)=>{
                const findTitleIndx = formsValue.findIndex(x => x.name === val.fd_name)
                if(val.fd_category === 'input'){
                    return<Grid item xs={12} md={val.row_no} key={index}>
                        <FormControl style={{ width: '100%', }} variant="outlined" size="small">
                            <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label} {val.fd_required === true ? "*" : ""}</Typography>
                            <TextField
                                required={val.fd_required === true ? true : false} 
                                value={findTitleIndx === -1 ? "" : formsValue[findTitleIndx].value}
                                onChange={(e) => onChange(e,val.fd_name,val.push)}
                                id="outlined-size-small"
                                name={val.fd_name}
                                variant="outlined"
                                size="small"/>
                        </FormControl>
                    </Grid>
                }else if(val.fd_category === 'dropdown'){
                    return<Grid item xs={12} md={val.row_no} key={index}>
                            <FormControl  
                                required={val.fd_required === true ? true : false} 
                                className={classes.formControl} variant="outlined" size="small">
                                <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label} {val.fd_required === true ? "*" : ""}</Typography>
                                <Select
                                size="small"
                                native
                                value={findTitleIndx === -1 ? "" : formsValue[findTitleIndx].value}
                                onChange={(e)=>onChange(e,val.fd_name,val.push)} 
                                inputProps={{
                                    name: val.fd_name,
                                    id: 'filled-age-native-simple',
                                }}>
                                <option aria-label="None" value="" />
                                {val.fd_label === 'Region' &&
                                    <>
                                        {RegionFilter.map((dropdownVal,index2)=>{
                                            return <option aria-label="None" value={dropdownVal.code+'\*/'+dropdownVal.name} key={index2}>{dropdownVal.name}</option>
                                        })}
                                    </>
                                }
                                {val.fd_label === 'Province' &&
                                    <>
                                        {ProvinceFilter.map((dropdownVal,index2)=>{
                                            return <option aria-label="None" value={dropdownVal.code+'\*/'+dropdownVal.name} key={index2}>{dropdownVal.name}</option>
                                        })}
                                    </>
                                }
                                {val.fd_label === 'City/Town' &&
                                    <>
                                        {MunicipalitiesFilter.map((dropdownVal,index2)=>{
                                            return <option aria-label="None" value={dropdownVal.province+'\*/'+dropdownVal.name} key={index2}>{dropdownVal.name}</option>
                                        })}
                                    </>
                                }
                                </Select>
                            </FormControl>
                    </Grid>
                }
                // return  <Grid item xs={12} md={val.row_no} key={index}>
                    
                //     <FormControl style={{ width: '100%', }}>
                //         <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> {val.fd_label}</Typography>
                //         <TextField
                //             // value={insertedSearch}
                //             // onChange={(val) => setinsertedSearch(val.target.value)}
                //             id="outlined-size-small"
                //             name={val.fd_name}
                //             variant="outlined"
                //             size="small"/>
                //     </FormControl>
                // </Grid>
            })}
          
            {/* <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Province</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> City/Town</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Zip Code</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> District</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Barangay</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Complete address</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Landmark</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Subdivision</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> Street</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}> House no.</Typography>
                    <TextField
                        // value={insertedSearch}
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"/>
                </FormControl>
            </Grid> */}
        </Grid>
    )
}