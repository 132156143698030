import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      minWidth: 170,
      fontSize: 14,
    },
  }))(TableCell);
export default function DialogViewProduct({selectedDisplay,handleCloseDialog,prod_history,product_choice}) {
    const subClasses = subStyles();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Dialog
        open={selectedDisplay}
        fullWidth={true}
        fullScreen
        // onClose={handleCloseUnassign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={2} ></Grid>
                    <Grid item xs={12} md={8} >
                        <Card variant="outlined" style={{ marginTop: 10, }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4} >
                                        <div style={{ width: '100%', height: 360, backgroundColor: '#dfe4ea', position: 'relative' }}>

                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8} >
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="h5" gutterBottom style={{ color: '#786fa6' }}> <b>Product details</b></Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} md={6} >
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product name</b></Typography>
                                                        <TextField
                                                        name="prod_name"
                                                        value={product_choice.inventory_name}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small" />
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product code</b></Typography>
                                                        <TextField
                                                        name="prod_code"
                                                        value={product_choice.inventory_code}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small" />
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} >
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product description</b></Typography>
                                                        <TextField
                                                        name="prod_des"
                                                        value={product_choice.inventory_description}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small" />
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product qty.</b></Typography>
                                                        <TextField
                                                        name="prod_qty"
                                                        value={product_choice.inventory_quantity}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number" />
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product type</b></Typography>
                                                        <TextField
                                                        name="prod_qty"
                                                        value={product_choice.inventory_type}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"/>
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product price</b></Typography>
                                                        <TextField
                                                        name="prod_price"
                                                        value={product_choice.inventory_price}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small" />
                                                    </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card variant="outlined" style={{marginTop:10}}>
                            <CardContent>
                            <FormControl style={{marginTop:10,marginBottom:10}}>
                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search item name</b></Typography>
                                <TextField
                                    // value={insertedSearch}
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    // onChange={(val) => setinsertedSearch(val.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="end">
                                            <IconButton
                                                // onClick={() => filterSearch()}
                                                aria-label="toggle password visibility" edge="start">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                </FormControl>
                                <Paper variant="outlined">
                                    <TableContainer size="small" className={subClasses.tableContainer}>
                                        <Table size="small"
                                            stickyHeader
                                            style={{ whiteSpace: "nowrap" }}  >
                                            <TableHead >
                                                <TableRow style={{ backgroundColor: "blue" }}>
                                                    <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Referrence id</StyledTableCell>
                                                    <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Quantity</StyledTableCell>
                                                    <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Transaction</StyledTableCell>
                                                    <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Date</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {prod_history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ary, index) => {
                                                    return <TableRow hover tabIndex={-1} key={index}>
                                                        <TableCell align="left">{ary.item_id}</TableCell>
                                                        <TableCell align="left">{ary.item_changes}</TableCell>
                                                        <TableCell align="left">{ary.item_activity}</TableCell>
                                                        <TableCell align="left">{moment(ary.item_log_timestamp).format('MMM DD YYYY')}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={prod_history.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage} />
                                </Paper>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={2} ></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary"> Close</Button>
            </DialogActions>
        </Dialog>
    );
}