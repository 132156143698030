const initialState = {
    profileTemplate: [],
    addressTemplate:[],
    attachmentTemplate:[]
}
const formTemplate = (state = initialState, action) => {
    switch (action.type) {
        case 'profileObjectsInp':
            return {
                ...state,
                profileTemplate: action.passProfileObjectsInp
            }
        case 'AddressObjects':
            return {
                ...state,
                addressTemplate: action.passAddressObjects
            }
        case 'updateProfileObjects':
                return {
                    ...state,
                    profileTemplate : state.profileTemplate.map(value => {
                        if(value.sequencing === action.passIndex){
                            return action.passNewValue;
                        }
                        return value;
                    })
                }
        case 'updateAddressObjects':
            return {
                ...state,
                addressTemplate : state.addressTemplate.map(value => {
                    if(value.sequencing === action.passIndex){
                        return action.passNewValue;
                    }
                    return value;
                })
            }
        case 'updateAttachmentObjects':
            return {
                ...state,
                attachmentTemplate : state.attachmentTemplate.map(value => {
                    if(value.sequencing === action.passIndex){
                        return action.passNewValue;
                    }
                    return value;
                })
            }
        case 'addNewAttachment' :
            return{
                ...state,
                attachmentTemplate:state.attachmentTemplate.concat(action.passNewAttchmt),
            }
        case 'SplicedAttachment' :
            return{
                ...state,
                attachmentTemplate:action.updatedAttachment,
            }
        default:
            return state;
    }
}
export default formTemplate; 