import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch,useSelector } from 'react-redux'
export default function HistoryFilter({onChange,searchedName,fetchHistory,onChangeHisttype,history_type,clearFilter,fetchCustomer,submitIdentifier}) {
    const classes = useStyles();
    const [anchorFilterby, setAnchorFilterby] = React.useState(null);
    const [typeArr_, settypeArr_] = React.useState(["","0","1","2"]);
    const CustomerData = useSelector(state => state.differentCustomer.masterData)

    const handleClick= (event) => {
        setAnchorFilterby(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorFilterby(null);
    };

    useEffect(()=>{
        handleClose()
    },[CustomerData.length])
    return (
        <Grid container spacing={1} style={{marginTop:10,marginBottom:10}}>
            <Grid item xs={12} md={3}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search Customer</b></Typography>
                    <TextField
                        style={{backgroundColor:'#f5f6fa'}}
                        value={searchedName}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {fetchCustomer()}}
                                    aria-label="toggle password visibility" edge="start">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }} />
                </FormControl>
            </Grid>
             <Grid item xs={12} md={5}>
                <div style={{width:'100%',display:'flex'}}>
                    <div style={{marginRight:5}}>
                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                        <Button
                            style={{ height: 40, backgroundColor: '#f5f6fa',marginRight:5 }}
                            size="medium"
                            onClick={handleClick}
                            variant="outlined"
                            className={classes.button}
                            endIcon={<ArrowDropDownIcon />}>
                            Filter status {history_type === "" ?"ALL":history_type === "0" ? "PENDING" : history_type === "1" ? "ACCOMPLISHED" : history_type === "2" ? "CANCELLED" : ""}
                        </Button>
                        {(history_type !== "" || submitIdentifier === true) &&
                         <Button
                            style={{ height: 40, backgroundColor: '#8395a7',color:'#fff'}}
                            size="medium"
                            onClick={clearFilter}
                            variant="outlined"
                            className={classes.button}
                            endIcon={<ClearIcon />}>
                            Clear
                        </Button>
                        }
                        <Menu
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            id="simple-menu"
                            elevation={1}
                            getContentAnchorEl={null}
                            anchorEl={anchorFilterby}
                            keepMounted
                            open={Boolean(anchorFilterby)}
                            onClose={handleClose}>
                            <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20}}>
                                <Typography style={{ color: '#786fa6', fontSize: 17 }}> <b>Filter by Customer type :</b></Typography>
                                <Card variant="outlined" style={{ marginBottom: 10 }}>
                                    <CardContent >
                                        {typeArr_.map((val,index)=>{
                                            let value_name = ""
                                            if(val === ""){
                                                value_name = "ALL"
                                            }else if(val === "0"){
                                                value_name = "PENDING"
                                            }else if(val === "1"){
                                                value_name = "ACCOMPLISHED"
                                            }else if(val === "2"){
                                                value_name = "CANCELLED"
                                            }
                                            return<MenuItem key={index} 
                                                onClick={()=>{onChangeHisttype(val) }} 
                                                style={{backgroundColor: val === history_type ? '#2e86de' : '#fff'}}>{value_name}</MenuItem>
                                        })}
                                    </CardContent>
                                </Card>
                            </div>
                        </Menu>
                    </div>
                </div>
             </Grid>
            <Grid item xs={12} md={4}></Grid>
        </Grid>
    );
}