import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useDispatch,useSelector } from 'react-redux'
import { getData } from './api/api'
import useStyles from './stylesheet';
import LoadingGif from './assets/loading.gif'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  }));
export default function Index() {
  const classes = useStyles();
  const subClasses = subStyles();
  const [openLoader, setopenLoader] = React.useState(false);
  const [refreshs, setrefreshs] = React.useState(false);
  const AccountData = useSelector(state => state.userDataReducers.userProfile)
  const user_CheckHandle = useSelector(state => state.BranchHandled.configureIdentificator)

  const Dispatch = useDispatch();

  const accountDecryption=(user_id)=>{
    getData('Customer/idSecurity',user_id)
    .then((res)=>{
      if(res.result === 'success'){
        Dispatch({
          type:'accountDetails_',
          data:{
            userProfile:res.data,
            access_priv:res.nav,
            handledBranch:res.branches
          }
        })
        if(res.branches.length > 0){
          let checkInitialHandle = res.branches.findIndex(x => x.b_id === res.data[0].branch_id)
          if(checkInitialHandle > -1){
            Dispatch({
              type:'passInitialBranch',
              passSelectedBranch:res.branches[checkInitialHandle]
            })
          }else{
            Dispatch({
              type:'passInitialBranch',
              passSelectedBranch:res.branches[0]
            })
          }

          if(res.nav.length > 0){
            Dispatch({
              type:'passConfiguration',
              passIdentificator:"",
            })
          }else{
            Dispatch({
              type:'passConfiguration',
              passIdentificator:"no handle",
            })
          }
         
        }else{
          Dispatch({
            type:'passConfiguration',
            passIdentificator:"no handle",
          })
        }
        setopenLoader(false)
      }else{
        setopenLoader(false)
      }
    })
  }
 
useEffect(()=>{
  setopenLoader(true)
    if(localStorage.getItem("u") === null || typeof localStorage.getItem("u") === undefined){
      let url = window.location.href.split('/')
      let userID = url[4].split('=')
      accountDecryption(userID[1])
    }else{
      accountDecryption(localStorage.getItem("u"))
    }
},[])

useEffect(()=>{
  setrefreshs(!refreshs)
},[user_CheckHandle])
  return (
    <div className={classes.root}>
         <Breadcrumbs aria-label="breadcrumb" gutterBottom>
            <Typography color="textPrimary">Home Page</Typography>
            <Typography color="textPrimary">CRM</Typography>
            <Link>Initial</Link>
        </Breadcrumbs>
        <div style={{marginTop:'20%',width:'100%',alignItems:'center',justifyContent:'center',display:'flex'}}>
          <Grid container >
            <Grid item xs={12} md={2} ></Grid>
            <Grid item xs={12} md={8} >
              {user_CheckHandle === "no handle" &&
                <Typography variant="h4" gutterBottom style={{textAlign:'center'}}>Oops! your account is not completely configured please coordinate to your admin.</Typography>
              }
            </Grid>
            <Grid item xs={12} md={2} ></Grid>
          </Grid>
        </div>
        <Backdrop className={subClasses.backdrop} open={openLoader}>
          <img src={LoadingGif} style={{ width: 100, height: 100}} />
        </Backdrop>   
    </div>
  );
}