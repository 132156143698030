const initialState = {
    checked: [],
    checkedAddress: [],
}
const FieldsChecker = (state = initialState, action) => {
    switch (action.type) {
        case 'checkedFieldsProf':
            return {
                ...state,
                checked: action.passNewCheckedArray
            }
        case 'checkedFieldsAdd':
            return {
                ...state,
                checkedAddress: action.passNewCheckedArrayAddress
            }
        default:
            return state;
    }
}
export default FieldsChecker; 