import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import PrintIcon from '@material-ui/icons/Print'
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    HashRouter ,
    Route,
    useParams,
    Redirect,
    Link as NewLink,
  } from "react-router-dom";
import UploadPic from '../assets/uploaduser.png'
import useStyles from '../stylesheet';
import UserData from '../customerDetails/userDetails'
import { useDispatch,useSelector } from 'react-redux'
import { meterImages } from '../api/helperreadme'
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

export default function DialogViewHistory({displayHistory,singleAccom,handleCloseHistory}) {
    const subClasses = subStyles();
    const classes = useStyles();
    const [selectedImg, setselectedImg] = React.useState("");
    const Dispatch = useDispatch();

    useEffect(()=>{
       if(singleAccom.length > 0){
        if(String(singleAccom[0].accom_images).trim() === ""){
            setselectedImg("")
        }else{
            let parsingArr_ = JSON.parse(singleAccom[0].accom_images)
            setselectedImg(parsingArr_[0].path)
        }
       }
    },[singleAccom])
    return (
        <Dialog
            open={displayHistory}
            maxWidth="md"
            fullWidth={true}
            onClose={handleCloseHistory}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
            <DialogTitle id="form-dialog-title">Accomplishment</DialogTitle>
            <DialogContent  style={{height:'60vh'}}>
                {singleAccom.map((val,indx)=>{
                    let accomIdentifier = null
                    if(String(val.accom_images).trim() === ""){
                        accomIdentifier = []
                    }else{
                        accomIdentifier = JSON.parse(val.accom_images)
                    }
                    return<Grid container spacing={1} key={indx}>
                    <Grid item xs={12} md={6} >
                    <div style={{width:'100%',height:'50vh',backgroundColor:'#487eb0'}}>
                        {selectedImg != "" &&
                        <img src={meterImages+selectedImg} style={{ width: '100%', height: '100%'}} />
                        }
                    </div>
                    <div style={{width:'100%',overflowX:'auto',marginTop:10}}>
                        <div style={{display:'flex',flexDirection:'row',flex:'wrap',justifyContent:'flex-start'}}>
                        {accomIdentifier.map((val,index)=>{
                            return<div style={{height:100,minWidth:100,backgroundColor:'#487eb0',marginRight:10}} key={index} onClick={()=>setselectedImg(val.path)}>
                                <img src={meterImages+val.path} style={{ width: '100%', height: '100%'}} />
                            </div>
                        })}
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b'}}>Account No: <b>{val.account_no}</b></Typography>
                    <Typography variant="subtitle2" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b'}}>Type: <b>{val.accom_jo_type} </b></Typography>
                    <Typography variant="subtitle2" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b',cursor:'pointer',lineHeight:1}}>
                        Account Address: <b>{val.address}</b>
                        <br/>
                        <a href={"https://www.google.com.ph/maps/@"+val.fetched_coordinates+',17z'} target="_blank"> <i style={{fontSize:10,color:'#cf6a87'}}>Click here to go Map</i></a>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b'}}>Account Findings: <b>{val.accom_findings}</b></Typography>
                    <Typography variant="subtitle2" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b'}}>Date Accomplished: <b>{moment(val.date_accom).format('MMMM DD, YYYY hh:mm A')}</b></Typography>
                    <Typography variant="subtitle2" gutterBottom style={{marginLeft:5,marginTop:10,fontSize:14,color:'#4b4b4b'}}>Fieldman: <b>{val.accom_fieldman_name}</b></Typography>
                    </Grid>
                </Grid>
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseHistory} color="primary"> Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}