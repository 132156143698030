import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';

import moment from 'moment'
import useStyles from '../../stylesheet';
import { getData } from '../../api/api'
import CreateTable from './createRec'
import CreateHeader from './createHead'
import CreateFilter from './createFilterhead'
import DialogViewUnassign from './dialogviewAssigned'
import LoadingGif from '../../assets/loading.gif'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 450,
      },
    margin: {
        margin: theme.spacing(1),
      },
}));

export default function CreateJo() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableLength, settableLength] = React.useState(0);
    const [openLoader, setopenLoader] = React.useState(false);
    const [refreshs, setrefreshs] = React.useState(false);
    const [unAssignDialog, setunAssignDialog] = React.useState(false);

    const [cycleDate, setcycleDate] = React.useState(new Date());
    const [joborderDate, setjoborderDate] = React.useState(new Date());
    const [selectedFieldwork, setselectedFieldwork] = React.useState("");
    const [branchData, setbranchData] = React.useState({
        branch_id:"",
        company_id:"",
        fieldworks:null
    });

    const [selectTorevert, setselectTorevert] = React.useState([]);
    const [fetchedCustomer, setfetchedCustomer] = React.useState([]);
    const [selectedAccount, setselectedAccount] = React.useState([]);
    const [previouslyCreatedJo, setpreviouslyCreatedJo] = React.useState([]);

    const classes = useStyles();
    const subClasses = subStyles();
    const AccountData = useSelector(state => state.userDataReducers.userProfile)
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)

    const getJotype = () => {
        setbranchData({
            branch_id:initialBranch.b_id,
            company_id:initialBranch.c_id,
            fieldworks:initialBranch.branch_field_work
        })
    };
    const onChangeFieldwork=(event)=>{
        setselectedFieldwork(event.target.value)
    }
    const onChangeDate=(date)=>{
        setcycleDate(date)
    }
    const onChangejoDate=(date)=>{
        setjoborderDate(date.target.value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getCustomers(newPage)
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleCloseUnassign = () => {
        setunAssignDialog(false);
    };

    const handleCheck=(val)=>{
        const currentIndex = selectedAccount.findIndex(x => String(x.cust_id).toUpperCase() === String(val.cust_id).toUpperCase())
        if(currentIndex === -1){
            selectedAccount.push(val)
            setrefreshs(!refreshs)
        }else{
            selectedAccount.splice(currentIndex, 1);
            setrefreshs(!refreshs)
        }
    }
    const handleUncheck=(val)=>{
        const currentIndex = selectTorevert.findIndex(x => String(x.id).toUpperCase() === String(val.id).toUpperCase())
        if(currentIndex === -1){
            val.cust_id = val.id
            selectTorevert.push(val)
            setrefreshs(!refreshs)
        }else{
            selectTorevert.splice(currentIndex, 1);
            setrefreshs(!refreshs)
        }
    }

    const submitFilter=()=>{
        setPage(0)
        settableLength(0)
        setfetchedCustomer([])
        setselectedAccount([])
        setpreviouslyCreatedJo([])
        setTimeout(()=>{
            getCustomers(page)
        },300)
    }
    const getCustomers=(newPage)=>{
        setopenLoader(true)
        if(selectedFieldwork === ""){
            alert('Fieldwork cannot be empty')
        }else{
            let data = {
                company_id:branchData.company_id,
                branch_id:branchData.branch_id,
                jo_type:selectedFieldwork,
                page:newPage+1,
                limit:rowsPerPage,
                cycle_date:moment(cycleDate).format('MMYY')
            }
            getData('jls/getCustomerListJobOrder',data)
            .then(resp =>{
                if(typeof resp.customer_job !== 'undefined'){
                        setpreviouslyCreatedJo(resp.customer_job)
                }
                setfetchedCustomer(resp.masterlist)
                settableLength(resp.masterlist_count)
                setopenLoader(false)
            })
        }
    }
    const submitCreate=()=>{
        let jobCreation ={
            app_id:'10',
            company_id:branchData.company_id,
            branch_id:branchData.branch_id,
            user_id:AccountData[0].user_id,
            bill_cycle:moment(cycleDate).format('MMYY'),
            joborderDate:moment(joborderDate).format('YYYY-MM-DD'),
            accounts:selectedAccount,
            selectedFieldwork:selectedFieldwork
        }
        getData('jls/createJobOrder',jobCreation)
        .then(response=>{
            if(JSON.stringify(response).includes('Success')){
                let selectedHandler = selectedAccount
                for (let index = 0; index < selectedHandler.length; index++) {
                    let struct ={
                        account_number: selectedHandler[index].account_no,
                        date_filter: moment(joborderDate).format('YYYY-MM-DD'),
                        id: selectedHandler[index].cust_id
                    }
                    previouslyCreatedJo.push(struct)
                }
                setselectedAccount([])
                alert('Job Order successfuly created')
            }
        })
    }

    const submitUnassign=()=>{
        setopenLoader(true)
        let jobCreation ={
            app_id:'10',
            company_id:branchData.company_id,
            branch_id:branchData.branch_id,
            user_id:AccountData[0].user_id,
            bill_cycle:moment(cycleDate).format('MMYY'),
            joborderDate:moment(joborderDate).format('YYYY-MM-DD'),
            accounts:selectTorevert,
            selectedFieldwork:selectedFieldwork
        }
        getData('jls/cancelJobOrder',jobCreation)
        .then(response=>{
            if(JSON.stringify(response).includes('Success')){
                let toRemove = selectTorevert
                restructUnassign(toRemove)
            }
        })
    }
    
    const restructUnassign=(toRemove)=>{
        for (let index = 0; index < toRemove.length; index++) {
            previouslyCreatedJo.splice(previouslyCreatedJo.findIndex(x => x.id === toRemove[index].id),1)
        }
        setopenLoader(false)
        setselectTorevert([])
        setunAssignDialog(false)
    }

    useEffect(()=>{
        getJotype()
    },[initialBranch])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" gutterBottom>
                <Typography color="textPrimary">Home Page</Typography>
                <Typography color="textPrimary">CRM</Typography>
                <Typography color="textPrimary">Job Order</Typography>
                <Link>Assign</Link>
            </Breadcrumbs>
            <Card variant="outlined" style={{marginTop:15}}>
                <CardContent>
                    <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Create jo</b></Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <CreateHeader 
                                        branchData={branchData.fieldworks} 
                                        onChangeFieldwork={onChangeFieldwork} 
                                        selectedFieldwork={selectedFieldwork} 
                                        onChangeDate={onChangeDate} 
                                        cycleDate={cycleDate}
                                        onChangejoDate={onChangejoDate}
                                        joborderDate={joborderDate}
                                        submitFilter={submitFilter}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div style={{display:'flex',width:'100',justifyContent:'flex-end'}} >
                                        <Typography onClick={()=>setunAssignDialog(true)} variant="h5" style={{marginBottom:10,cursor:"pointer"}}> <b style={{color:'#786fa6',}}>Created jo: {previouslyCreatedJo.length}</b></Typography>
                                    </div>
                                </Grid>
                            </Grid>
                          
                            <Card variant="outlined" style={{marginTop:5}}>
                                <CardContent >
                                    <CreateFilter
                                        selectedAccount={selectedAccount}
                                        onChangejoDate={onChangejoDate}
                                        joborderDate={joborderDate}/>
                                    <CreateTable 
                                        fetchedCustomer={fetchedCustomer} 
                                        previouslyCreatedJo={previouslyCreatedJo}
                                        tableLength={tableLength}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleCheck={handleCheck}
                                        selectedAccount={selectedAccount}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </CardContent>
                                <CardActions>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            onClick={() => {submitCreate()}}
                                            style={{ marginTop: 15, alignSelf: 'flex-end', backgroundColor: selectedAccount.length > 0 ? "#0984e3" : '#bdc3c7', color: '#fff' }}> CREATE </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>    
            <DialogViewUnassign 
                previouslyCreatedJo={previouslyCreatedJo}
                selectTorevert={selectTorevert}
                handleUncheck={handleUncheck}
                unAssignDialog={unAssignDialog}
                handleCloseUnassign={handleCloseUnassign}
                submitUnassign={submitUnassign}/> 

            <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:'2500'}}>
                <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </Backdrop>   
        </div>
    );
}