import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import useStyles from '../../stylesheet';
import { useDispatch,useSelector } from 'react-redux'
export default function WidgetPending({widgetCounter,widgetStatus}) {
    const classes = useStyles();
    const PendingCustomers = useSelector(state => state.differentCustomer.pendingCustomer)

    return ( 
        <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
                <div 
                    onClick={()=>{widgetStatus('All')}}
                    style={{ height: 100, width: '100%', backgroundColor: '#4FA1ED',cursor:"pointer" }}>
                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{widgetCounter.all}</b></Typography>
                        <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> ALL</Typography>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={2}>
                <div 
                    onClick={()=>{widgetStatus('Pending')}}
                    style={{ height: 100, width: '100%', backgroundColor: '#F59B74',cursor:"pointer" }}>
                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> 
                            <b>{widgetCounter.all - widgetCounter.hold}</b>
                        </Typography>
                        <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> PENDING </Typography>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={2}>
                <div 
                    onClick={()=>{widgetStatus('Hold')}}
                    style={{ height: 100, width: '100%', backgroundColor: '#786fa6',cursor:"pointer" }}>
                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> 
                            <b>{widgetCounter.hold}</b>
                        </Typography>
                        <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> HOLD </Typography>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={2}>
                <div style={{ height: 100, width: '100%', backgroundColor: '#57606f' }}>
                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>0</b></Typography>
                        <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> DENIED </Typography>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}