import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
export default function RecordHeader({onChange,searchedName,fetchCustomer,submitIdentifier,clearFilter}) {
    const classes = useStyles();

    return (
        <Grid container spacing={1} style={{marginBottom:10}}>
            <Grid item xs={12} md={3}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search item</b></Typography>
                    <TextField
                        style={{backgroundColor:'#f5f6fa'}}
                        value={searchedName}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={() => fetchCustomer()}
                                    aria-label="toggle password visibility" edge="start">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }} />
                </FormControl>
            </Grid>
             <Grid item xs={12} md={5}>
                {submitIdentifier === true &&
                    <>
                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> &nbsp;</Typography>
                        <Button
                            style={{ height: 40, backgroundColor: '#8395a7',color:'#fff'}}
                            size="medium"
                            onClick={clearFilter}
                            variant="outlined"
                            className={classes.button}
                            endIcon={<ClearIcon />}>
                            Clear
                        </Button>
                    </>
                   
                }
             </Grid>
            <Grid item xs={12} md={4}>
                <div style={{display:'flex',width:'100%',justifyContent:'flex-end'}}>
                    <div>
                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                        <Button
                            style={{backgroundColor:'#574b90',marginRight:10,height:40,color:'#fff'}}
                            color="primary"
                            size="small"
                            className={classes.button}> Excel </Button>
                    </div>
                    <div>
                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                        <Button
                        style={{backgroundColor:'#574b90',height:40,color:'#fff'}}
                        color="primary"
                        size="small"
                        className={classes.button} >Export PDF</Button>
                    </div>
                    
                </div>
            </Grid>
           
        </Grid>
    );
}