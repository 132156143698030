import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  HashRouter ,
  Route,
  Redirect,
  Link as NewLink,
} from "react-router-dom";
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { DropzoneArea,DropzoneDialog } from "material-ui-dropzone";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AttachFileIcon from '@material-ui/icons/AttachFile'
// import UploadImages from './material/dropzone'
import {
  regions,
  provinces,
  citiesMunicipalities,
} from 'ph-locations';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import useStyles from '../../stylesheet';
import UploadPic from '../../assets/uploaduser.png'
import AlertComponent from '../../material/snackbar'
import BackgroundImageq from '../../assets/polkadots.png'
import { getData } from '../../api/api'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { useDispatch, useSelector } from 'react-redux'

import ProfileSection from './profileForm'
import AddressSection from './addressForm.js'
import AttachSection from './attachForm.js'
import Reviewsection from './reviewForm.js'
import { Profile_section,Address_section } from '../../assets/formComponents'
import LoadingGif from '../../assets/loading.gif'
const subuseStyles = makeStyles((theme) => ({
  subroot: {
       flexGrow: 1,
       backgroundColor: theme.palette.background.paper,
       display: 'flex',
  },
 formcontainer: {
       '& .MuiTextField-root': {
       margin: theme.spacing(1),
       width: '25ch',
       },
 },
 formControl: {
     margin: theme.spacing(1),
     minWidth: 120,
 },
 tabs: {
     borderRight: `1px solid ${theme.palette.divider}`,
 },
 cardContainer: {
     minWidth: 275,
 },
 backdrop: {
     zIndex: theme.zIndex.drawer + 1,
     color: '#fff',
 },
}));
export default function NewApplication() {
    const classes = useStyles();
    const subClasses = subuseStyles();
    const theme = useTheme();

    const [openDialog, setopenDialog] = React.useState(true);
    const [openLoader, setopenLoader] = React.useState(false);
    const [refreshs, setrefreshs] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(1);
    const [openSnack, setopenSnack] = React.useState(false);
    const [currDate, setcurrDate] = React.useState(new Date);

    const [branchData, setbranchData] = React.useState({
      branch_id:"",
      company_id:"",
      fieldworks:null
    });
    const [alertStyle, setalertStyle] = React.useState({
        alertMessage:'',
        alertType:'Success'
    });

    const [RegionFilter, setRegionFilter] = React.useState([]);
    const [MunicipalitiesFilter, setMunicipalitiesFilter] = React.useState([]);
    const [ProvinceFilter, setProvinceFilter] = React.useState([]);

    const [Categories, setCategories] = React.useState([]);
    const [formsTitle, setformsTitle] = React.useState([]);
    const [formsValue, setformsValue] = React.useState([]);
    const [Customertype, setCustomertype] = React.useState("RESIDENTIAL");
    const [FormSelections, setFormSelections] = React.useState([]);
    const [FormSelectionsAddress, setFormSelectionsAddress] = React.useState([]);

    const AccountData = useSelector(state => state.userDataReducers.userProfile)
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)
    
    const getJotype = () => {
      setbranchData({
          branch_id:initialBranch.b_id,
          company_id:initialBranch.c_id,
          fieldworks:initialBranch.branch_field_work
      })
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = (e) => {
      e.preventDefault()
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const onChange = (event,name,push_action) => {
      const currentIndex = formsValue.findIndex(x => x.name === event.target.name)
      let holder = event.target.value
      if(name === 'region'){
        var Splitting = ""
        var Splitted = ""
        Splitting = event.target.value
        Splitted = Splitting.split("*")
        const FilterProvince = provinces.filter(function (item) {
            return item.region === Splitted[0]
        });
        if(FilterProvince.length > 0){
            setProvinceFilter(FilterProvince)
            setMunicipalitiesFilter([])
        }else{
            const FilterCities = citiesMunicipalities.filter(function (item) {
                return item.province === null;
            });
            setProvinceFilter([])
            setMunicipalitiesFilter(FilterCities)
        }
        if(currentIndex === -1){
          let form_val = {
            name:event.target.name,
            value:holder,
            push:push_action
          }
          formsValue.push(form_val)
        }else{
          formsValue[currentIndex].value = holder
        }
      }else if(name === 'province'){
        var Splitting = ""
        var Splitted = ""
        Splitting = event.target.value
        Splitted = Splitting.split("*")
        const FilterProvince = citiesMunicipalities.filter(function (item) {
            return item.province === Splitted[0]
        });
        if(currentIndex === -1){
          let form_val = {
            name:event.target.name,
            value:holder,
            push:push_action
          }
          formsValue.push(form_val)
        }else{
          formsValue[currentIndex].value = holder
        }
        setMunicipalitiesFilter(FilterProvince)

      }else if(name === 'municipalities'){
        if(currentIndex === -1){
          let form_val = {
            name:event.target.name,
            value:holder,
            push:push_action
          }
          formsValue.push(form_val)
        }else{
          formsValue[currentIndex].value = holder
        }
        setrefreshs(!refreshs)
      }else{
        if(currentIndex === -1){
          let form_val = {
            name:event.target.name,
            value:holder,
            push:push_action
          }
          formsValue.push(form_val)
        }else{
          formsValue[currentIndex].value = holder
        }
        setrefreshs(!refreshs)
      }
    }

    const submitApplication=()=>{
      setopenLoader(true)
      const filterForms = formsValue.filter(function (item) {
        return item.value !== ""
      });
      let arrayStruct = {}
      for (let index = 0; index < filterForms.length; index++) {
        if(filterForms[index].push === 1){
          arrayStruct[filterForms[index].name] = filterForms[index].value
        }
      }


      if(Customertype === "COMMERCIAL" || Customertype === "INDUSTRIAL" || Customertype === "GOVERNMENT"){
        let push_contacts = {}
        const filterPush = filterForms.filter(function (item) {
          return item.push === 0
        });
        for (let index = 0; index < filterPush.length; index++) {
          push_contacts[filterPush[index].name] = filterPush[index].value
        }
        arrayStruct.contacts = JSON.stringify([push_contacts])
      }

      arrayStruct.app_id = '10'
      arrayStruct.company_id = branchData.company_id
      arrayStruct.branch_id = branchData.branch_id
      arrayStruct.user_id = AccountData[0].user_id
      arrayStruct.country = 'Philippines'
      arrayStruct.cust_type = Customertype
      getData('jls/InsertCustomerDetails',arrayStruct)
      .then((res)=>{
        if(res.status === 'Success'){
          setformsValue([])
          setActiveStep(1)
          setopenLoader(false)
          setalertStyle({alertMessage:'Account successfully created',alertType:'success'})
          setopenSnack(true)
        }else{
            setopenLoader(false)
            setalertStyle({alertMessage:'Failed to add created application. Please try again',alertType:'error'})
            setopenSnack(true)
        }
      })
    }
    
    useEffect(()=>{
      const FilterProvince = Profile_section.filter(function (item) {
        return JSON.stringify(item.fd_type).includes(Customertype)
      });
      setformsValue([])
      setActiveStep(1)
      setFormSelections(FilterProvince)
    },[Customertype])

    useEffect(()=>{
        getJotype()
    },[initialBranch])

    useEffect(()=>{
      setFormSelectionsAddress(Address_section)
      setRegionFilter(regions)
    },[])
    return(
        <div className={classes.root}>
            <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
            <Breadcrumbs aria-label="breadcrumb" gutterBottom>
                <Typography color="textPrimary">Home Page</Typography>
                <Typography color="textPrimary">Application</Typography>
                <Link>New application</Link>
            </Breadcrumbs>
            <Grid container spacing={2} style={{marginTop:10}}>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={8}>
                {activeStep !== 4 &&
                  <>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Application Form step {activeStep} out of 4</b></Typography>
                      <FormControl  className={classes.formControl} required={true}  variant="outlined" size="small">
                          <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Customer type:</b></Typography>
                          <Select
                          onChange={(e)=>setCustomertype(e.target.value)}  
                          size="small"
                          native
                          value={Customertype}
                          inputProps={{
                              name: 'cust_type',
                              id: 'filled-age-native-simple',
                          }}>
                          <option aria-label="None" value="" />
                          <option value="RESIDENTIAL">RESIDENTIAL</option>
                          <option value="COMMERCIAL">COMMERCIAL</option>
                          <option value="INDUSTRIAL">INDUSTRIAL</option>
                          <option value="GOVERNMENT">GOVERNMENT</option>
                          </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                  <Divider style={{backgroundColor:'#574b90',marginTop:10,marginBottom:10,height:5}}/>
                  </>
                }
                <Card variant="outlined">
                  <form onSubmit={handleNext}>
                    <CardContent style={{backgroundColor:activeStep === 4 ? '#f1f2f6' : '#fff'}}>
                      {activeStep === 1 &&
                        <>
                        <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Profile section</b></Typography>
                        <ProfileSection 
                          onChange={onChange}
                          FormSelections={FormSelections}
                          formsValue={formsValue}/>
                        </>
                      }
                      {activeStep === 2 &&
                        <>
                        <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Address section</b></Typography>
                        <AddressSection
                          onChange={onChange}
                          FormSelectionsAddress={FormSelectionsAddress}
                          formsValue={formsValue}
                          RegionFilter={RegionFilter}
                          ProvinceFilter={ProvinceFilter}
                          MunicipalitiesFilter={MunicipalitiesFilter}/>
                        </>
                      }
                      {activeStep === 3 &&
                        <>
                        <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Attachment section</b></Typography>
                        <AttachSection/>
                        </>
                      }
                      {activeStep === 4 &&
                        <>
                        <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Review Form</b></Typography>
                        <Divider style={{backgroundColor:'#574b90',marginTop:10,marginBottom:10,height:5}}/>
                        <Reviewsection
                          Customertype={Customertype}
                          FormSelections={FormSelections}
                          FormSelectionsAddress={FormSelectionsAddress}
                          formsValue={formsValue}/>
                        </>
                      }
                    </CardContent>
                    <CardActions style={{width:'100%'}}>
                      <MobileStepper
                        variant="progress"
                        steps={5}
                        position="static"
                        activeStep={activeStep}
                        className={classes.root}
                        nextButton={
                          activeStep === 4
                          ?<Button 
                            onClick={()=>submitApplication()} 
                            size="small">Submit {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}</Button>
                          :<Button size="small" type="submit"  disabled={activeStep === 4}>
                              Next
                          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                          </Button>
                        }
                        backButton={
                            <Button 
                              onClick={handleBack}
                              size="small" 
                              disabled={activeStep === 1}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                            </Button>
                        }/>
                    </CardActions>
                  </form>
                </Card>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
            </Grid>
            <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:9999}}>
              <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </Backdrop>
        </div>
    )
}