import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment'
import { useDispatch,useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
      margin: {
        margin: theme.spacing(1),
      },
  }));
export default function HistoryTable({rowsPerPage,tableLength,page,handleChangePage,handleChangeRowsPerPage,showDisplay}) {
    const subClasses = subStyles();
    const historyData_ = useSelector(state => state.custDetails.history) 
    useEffect(()=>{
    },[historyData_])
    return (
        <Paper variant="outlined">
            <TableContainer size="small" className={subClasses.tableContainer}>
                <Table size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}  >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#786fa6",color: "#fff"}} align="left">#</TableCell>
                            <TableCell style={{backgroundColor: "#786fa6",color: "#fff"}} align="left">Action</TableCell>
                            <TableCell style={{backgroundColor: "#786fa6",color: "#fff"}} align="left"> Customer name</TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Status </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Address </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Price </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Qty </TableCell>
                            <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Date </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {historyData_.map((ary,index) => {
                            let status_identificator = ""
                            let status_color = ""
                            let total_quantity = 0
                            if(ary.accomplishment_status === null || parseInt(ary.accomplishment_status) === 0){
                                status_identificator = "Pending"
                                status_color = "#7f8c8d"
                            }else if(parseInt(ary.accomplishment_status) === "1"){
                                status_identificator = "Accomplished"
                                status_color = "#2d98da"
                            }else if(parseInt(ary.accomplishment_status) === "2"){
                                status_identificator = "Cancelled"
                                status_color = "#b33939"
                            }
                            if(ary.order_details !== null){
                                if (Array.isArray(JSON.parse(ary.order_details))) {
                                    let sumtotalPrice = JSON.parse(ary.order_details).reduce((previousValue, currentValue) => previousValue + parseInt(currentValue.item_changes), 0)
                                    total_quantity = sumtotalPrice
                                }
                            }
                            return<TableRow hover tabIndex={-1} key={index}>
                                    <TableCell align="left">{index+(rowsPerPage*page)+1}</TableCell>
                                    <TableCell>
                                        <IconButton 
                                            size="small"
                                            onClick={()=>console.log(ary)}
                                            aria-label="delete" className={subClasses.margin}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="left">{ary.name}</TableCell>
                                    <TableCell align="left"  style={{backgroundColor: status_color,color:'#fff'}}>{status_identificator}</TableCell>
                                    <TableCell align="left">{ary.address}</TableCell>
                                    <TableCell align="left">{ary.total === null ? "N/A" : '₱'+parseFloat(ary.total).toFixed(2)}</TableCell>
                                    <TableCell align="left">{total_quantity}</TableCell>
                                    <TableCell align="left"><a class="view">{ary.date_added === "0000-00-00 00:00:00" ? 'N/A' : moment(ary.date_added).format('MMM DD, YYYY hh:mm A')}</a></TableCell>
                                </TableRow>
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10,50, 100]}
                component="div"
                count={tableLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>
    );
}