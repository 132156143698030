import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiAlert from '@material-ui/lab/Alert';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DeleteIcon from '@material-ui/icons/Delete';
import { InsertEmoticonRounded } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { getData } from '../../api/api'
import LoadingGif from '../../assets/loading.gif'
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
      margin: {
        margin: theme.spacing(1),
      },
}));

export default function SalesToggeOrder({ details_order}) {
    const classes = useStyles();
    const subClasses = subStyles();
    const Dispatch = useDispatch();
    const [refreshs, setrefreshs] = React.useState(false);
    const [loadingDisplay, setloadingDisplay] = React.useState(false);
    const [productvalue, setproductvalue] = React.useState("");
    const [alertDisplay,setalertDisplay] = React.useState({
        showAlert:false,
        alertMessage:"",
        alertType:''
    })
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableLength, settableLength] = React.useState(0);

    const [itemHistory,setitemHistory] = React.useState([])
    const [anchorFilterby, setAnchorFilterby] = React.useState(null);
    const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)
    const InventoryArr_ = useSelector(state => state.inventoryData.prodList)
    const user_Data = useSelector(state => state.userDataReducers.userProfile)

    const handleClick= (event) => {
        setAnchorFilterby(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorFilterby(null);
    };

    return (
        <>
        <IconButton 
            size="small"
            onClick={handleClick}
            aria-label="delete" className={subClasses.margin}>
            <ListAltIcon fontSize="small" style={{color:'#2980b9'}}/>
        </IconButton>
        <Menu
            anchorOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: "left",
            }}
            id="simple-menu"
            elevation={1}
            getContentAnchorEl={null}
            anchorEl={anchorFilterby}
            keepMounted
            open={Boolean(anchorFilterby)}
            onClose={handleClose}>
            <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20}}>
                <Typography style={{ color: '#786fa6', fontSize: 17 }}> <b>Order list :</b></Typography>
                <Card variant="outlined" style={{ marginBottom: 10 }}>
                    <CardContent >
                        {details_order.map((val,index2)=>{
                            return<MenuItem key={index2}><span style={{marginRight:10}}>{val.item_name}</span>-- {val.item_changes}pc(s)</MenuItem>
                        })}  
                    </CardContent>
                </Card>
            </div>
        </Menu>
        </>
    )
}