import React, { useCallback, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import {
  HashRouter as Router,
  Route,
  useParams,
  Redirect,
  Link as NewLink
} from "react-router-dom";
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
const drawerWidth = 240;

function SubRoute({subPage,handleClick,index,openApp}) {
    const theme = useTheme();
    const Dispatch = useDispatch();
    const user_access = useSelector(state => state.userDataReducers.access_priv)

 
    return (
        <div>
            <ListItem button 
                onClick={() => handleClick(index)}>
                <ListItemIcon>
                <AssignmentTurnedInOutlinedIcon style={{ color: 'rgba(6,86,147)' }} />
                </ListItemIcon>
                <ListItemText primary={subPage.page_name} style={{ color: '#f5f6fa' }} />
                {openApp === 'panel'+index ? true : false ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openApp === 'panel'+index ? true : false} timeout="auto" unmountOnExit style={{display:subPage.subPage.length > 0 ? undefined : 'none'}}>
                {subPage.subPage.map((value,index)=>{
                    let match = user_access.filter((item)=>item.parent_name === value.page_name)
                    if(match.length > 0)
                    return<NewLink to={value.path} style={{ textDecoration: 'none', color: 'black' }} key={index}>
                            <ListItem button  style={{ backgroundColor: '#574b90' }}>
                                <ListItemIcon />
                                <ListItemText primary={value.page_name} style={{ color: '#dfe4ea' }} />
                            </ListItem>
                        </NewLink>
                })}
                <Divider />
            </Collapse>
        </div>
       
    )
        
}

export default SubRoute;
