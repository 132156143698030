import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import useStyles from '../../stylesheet';
import IconButton from '@material-ui/core/IconButton';
import CallMadeIcon from '@material-ui/icons/CallMade';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux'
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell); 
export default function PendingTable({rowsPerPage,page,tableLength,displayDialog,handleChangePage,handleChangeRowsPerPage}) {
    const subClasses = subStyles();
    const classes = useStyles();
    const PendingCustomers = useSelector(state => state.differentCustomer.pendingCustomer)
  
    return (
        <Paper>
            <TableContainer size="small" className={subClasses.tableContainer}>
                <Table size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}  >
                    <TableHead >
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>#</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Account no.</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Account name</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Trade name</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Rate name</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Customer type</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Date added</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Status</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {PendingCustomers.map((ary, index) => {
                            var backColor = '#fff'
                            let nameDisplay = ""
                            if(ary.cust_type === "COMMERCIAL" || ary.cust_type === "INDUSTRIAL" || ary.cust_type === "GOVERNMENT"){
                                nameDisplay = ary.business_name
                            }else{
                                nameDisplay = ary.cust_fname+' '+ary.cust_lname
                            }

                            if (ary.cust_status === 'Pending') {
                                backColor = '#F59B74'
                            } else {
                                backColor = '#786fa6'
                            }
                            return <TableRow hover tabIndex={-1} >
                                <TableCell align="left" key={index}>{index+(rowsPerPage*page)+1}</TableCell>
                                <TableCell align="left">{ary.account_no}</TableCell>
                                <TableCell align="left">{nameDisplay}</TableCell>
                                <TableCell align="left">N/A</TableCell>
                                <TableCell align="left">N/A</TableCell>
                                <TableCell align="left">{ary.cust_type}</TableCell>
                                <TableCell align="left">{moment(ary.cust_date_added).format('YYYY-MM-DD')}</TableCell>
                                <TableCell align="left" style={{backgroundColor: backColor,color:'#fff'}}>{ary.cust_status}</TableCell>
                                <TableCell align="left">
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => {displayDialog(ary)}} aria-label="delete" >
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10,50, 100]}
                component="div"
                count={tableLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}