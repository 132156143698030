import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      minWidth: 170,
      fontSize: 14,
    },
  }))(TableCell);
export default function DialogConfirmation({openHold,handleCloseHold,buttonClick,submitUpdate,onChangeJustification}) {
    const subClasses = subStyles();
    const classes = useStyles();

    return (
        <Dialog open={openHold} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {buttonClick.message}
                </DialogContentText>
                {buttonClick.type === "Hold" &&
                    <TextField
                        autoFocus
                        multiline
                        rowsMax={4}
                        margin="dense"
                        id="name"
                        label="Comment"
                        type="text"
                        onChange={onChangeJustification}
                        fullWidth />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseHold} color="primary"> Cancel</Button>
                <Button onClick={() => {submitUpdate()}} color="primary"> Submit </Button>
            </DialogActions>
        </Dialog>
    );
}