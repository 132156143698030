import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import useStyles from './stylesheet';
import {DropzoneArea} from 'material-ui-dropzone'
import UploadFile from './uploadFile'
import { getData } from './api/api'
import axios, { post } from 'axios';
import { useDispatch,useSelector } from 'react-redux'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));


export default function UploadCusttomer() {
  const classes = useStyles();
  const subClasses = subStyles();
  const [openLoader, setopenLoader] = React.useState(false);
  const [currDate, setcurrDate] = useState(new Date());
  const [FileData, setFileData] = useState(new Date());
  const [branchArray, setbranchArray] = React.useState([]);
  const [branchServices, setbranchServices] = React.useState([]);
  const [selectedBranch, setselectedBranch] = React.useState('');
  const [branch_id, setbranch_id] = React.useState('');
  const [company_id, setcompany_id] = React.useState('');
  const [selectedService, setselectedService] = React.useState('');

  const AccountData = useSelector(state => state.userDataReducers.userProfile)
  const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)

  const submitExcel=(e)=>{
    e.preventDefault();
    setopenLoader(true)
    var formData = new FormData();
    for (let i = 0; i < FileData.length; i++) {
        formData.append('file' + i, FileData[i])
    }
    axios.post("http://192.168.0.9/backend/api/Customer/uploadCustomer/"+selectedService+'/'+branch_id+'/'+company_id+'/'+AccountData[0].user_id+'/',formData)
    // getData('Customer/uploadCustomer',FileData)
    .then((res)=>{
      if(JSON.stringify(res).includes('Success')){
        setopenLoader(false)
      }else if(JSON.stringify(res).includes('some uploaded')){
        alert('failed to upload, data already uploaded')
        setopenLoader(false)
      }else{
        alert('fail')
        setopenLoader(false)
      }
    })
  }

  const handleChange = (files) => {
    setFileData(files)
  };

  const handleBranch = (event) => {
    const filterBranches = branchArray.filter(function(item){
      return item.branch_name === event.target.value;
    })
    setselectedBranch(event.target.value);
    setbranchServices(JSON.parse(filterBranches[0].service_type))
    setbranch_id(filterBranches[0].b_id)
    setcompany_id(filterBranches[0].c_id)
  };

  const handleService =(event)=>{
    setselectedService(event.target.value)
  }


  useEffect(()=>{
    setbranchArray(BranchHandled)
    // BranchFormat()
  },[])
  return (
    <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" gutterBottom>
            <Typography color="textPrimary">Home Page</Typography>
            <Typography color="textPrimary">CRM</Typography>
            <Typography color="textPrimary">Application</Typography>
            <Link>Upload Customer</Link>
        </Breadcrumbs>
         <Typography variant="h5" gutterBottom> <b style={{color:'#3d3d3d'}}>Customer Pending Inspection</b></Typography>
         <Grid container spacing={1}>
            <Grid item item xs={12} md={4}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography variant="overline"gutterBottom>System entry Date : <b>{moment(currDate).format('MMMM DD YYYY')}</b></Typography>
                        <Divider style={{marginBottom:10}}/>
                        <Grid container spacing={3} style={{marginBottom:10}}>
                            <Grid item item xs={12} md={6}>
                              <FormControl className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedBranch}
                                    onChange={handleBranch}>
                                      {branchArray.map((val,index)=>{
                                        let services = JSON.parse(val.service_type)
                                        if(services.length > 0 ){
                                          return<MenuItem value={val.branch_name} key={index}>{val.branch_name}</MenuItem>
                                        }
                                      })}
                                  </Select>
                              </FormControl>
                            </Grid>
                            <Grid item item xs={12} md={6}>
                              <FormControl className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-label">Select Service</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedService}
                                    onChange={handleService}>
                                      {branchServices.map((val,index)=>{
                                          return<MenuItem value={val} key={index}>{val}</MenuItem>
                                      })}
                                  </Select>
                              </FormControl>
                            </Grid>
                        </Grid>
                       
                        <UploadFile handleChange={handleChange} submitExcel={submitExcel}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item item xs={12} md={8}>
              <Card className={classes.root}>
                <CardContent>

                </CardContent>
              </Card>
            </Grid>
        </Grid>
        <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:9999}}>
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}