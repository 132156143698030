const initialState = {
    navlist: [],
}
const priviledgesNav = (state = initialState, action) => {
    switch (action.type) {
        case 'productArr_':
            return {
                ...state,
                navlist: action.passNav
            }
        default:
        return state;
    }
}
export default priviledgesNav; 