import React, { useEffect } from "react";
// import '../../../App';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from './stylesheet';
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { DropzoneArea } from 'material-ui-dropzone'

function Index({handleChange,submitExcel}) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    upload: false,
    loading:false
  });
  return (
    <div className={classes.root}>
      <form onSubmit={submitExcel}>
      <DropzoneArea
          className={classes.drop_zone_area}
          acceptedFiles={[".csv,.xlsx,text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
          onChange={handleChange}
          showFileNames={true}
          maxFileSize={500800000}
      />
      <div style={{width:'100%',display:'flex',justifyContent:'flex-end',marginTop: 10}}>
      <Button
            type='submit'
            size="medium"
            variant="contained"
            style={{
              backgroundColor: "rgba(6,86,147)",
              color: "white",
              marginRight: 5,
            }}
            className={classes.button}
           
          >
           Submit
          </Button>
      </div>
      </form>
  
         
    </div>
  );
}

export default Index;
