import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
// import '../../src/App.css';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
// import imglogo from '../media/field_logo.gif';
import {
  HashRouter as Router,
  Route,
  useParams,
  Redirect,
  Link as NewLink
} from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import Chip from '@material-ui/core/Chip';
import Toggling from '../navigation/toggleAppbar'
import TogglingProfile from '../navigation/toggleProf'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {routes,routesDrawer} from './routes'
import { useSelector, useDispatch } from 'react-redux'
import SubRoute from './subroute'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getData } from '../api/api'
import LandingPg from '../landingpg'
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        background: '#fff',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: theme.zIndex.drawer+1
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
      backgroundColor:'#786fa6',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width:'100%',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
}));

function App() {
    const classes = useStyles();
    const theme = useTheme();
     const { user_id } = useParams()
     const Dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const [openApp, setOpenApp] = React.useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)
    const user_CheckHandle = useSelector(state => state.BranchHandled.configureIdentificator)
    const user_access = useSelector(state => state.userDataReducers.access_priv)

    const handleClick = (index) => {
      setOpenApp("panel"+index)
   };
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    
    const handleDrawerClose = () => {
      setOpen(false);
    };

    useEffect(()=>{
    },[])
    return <Router>
        <div className={classes.root}>
        <CssBaseline />
        <AppBar
            position="fixed"
            elevation={1}
            className={classes.appBar} >
                <Toolbar style={{ displa: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton
                      style={{ color: 'rgba(0,47,84)' }}
                      aria-label="open drawer"
                      onClick={handleDrawerClose}
                      edge="start"
                      className={clsx(classes.menuButton, !open && classes.hide)} >
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <IconButton
                      style={{ color: 'rgba(0,47,84)' }}
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, open && classes.hide)}>
                      <MenuIcon />
                    </IconButton>
                    <Link href="/#/" >
                      <Typography variant="h5">CRM</Typography>
                    </Link>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                       <Chip label={initialBranch === [] ? "No selected branch" : initialBranch.branch_name} variant="outlined" color="primary"/>
                      <Toggling/>
                      <TogglingProfile/>
                    </div>
                  </div>
            </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{paper: classes.drawerPaper, }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {user_CheckHandle === "no handle"
            ?<></>
            :<>
                <Divider style={{ marginTop: '10%' }} />
                {routesDrawer.map((val,index)=>{
                  let match = user_access.filter((item)=>item.parent_name === val.page_name)
                  if(match.length === 0){
                    for (let index2 = 0; index2 < val.subPage.length; index2++) {
                      let findHandler = user_access.filter((item)=>item.parent_name === val.subPage[index2].page_name)
                      if(findHandler.length > 0){
                        match = findHandler
                      }
                    }
                  }
                  if(match.length > 0)
                    return<div key={index}>
                      {val.subPage.length === 0
                        ? <NewLink to={val.path} style={{ textDecoration: 'none', color: 'black' }}>
                            <ListItem button>
                              <ListItemIcon>
                                <AssignmentTurnedInOutlinedIcon style={{ color: 'rgba(6,86,147)' }} />
                              </ListItemIcon>
                              <ListItemText primary={val.page_name} style={{ color: '#f5f6fa' }} />
                            </ListItem>
                          </NewLink>
                        :<SubRoute subPage={val} handleClick={handleClick} index={index} openApp={openApp}/>
                      }
                    </div>
                })}
            </>
          }      
        </Drawer>
            <main className={clsx(classes.content, { [classes.contentShift]: open,})}>
                <div className={classes.drawerHeader} />
                  <Route 
                    exact={true} 
                    path={'/'} 
                    component={LandingPg} />
                  {routes.map((value, index) => {
                      return <Route 
                      key={index} 
                      exact={value.exact} 
                      path={value.path} 
                      component={user_access.filter((item)=>item.parent_name === value.page_name).length > 0
                        ?value.component
                        :undefined
                      } />
                  })}
            </main>
        </div>
        <Backdrop className={classes.backdrop} open={openLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>   
    </Router>
}

export default App;
