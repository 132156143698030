import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function SalesHistory({sevendaysArr_}) {
    return (
        <div style={{width:'100%'}}>
            <ResponsiveContainer width="100%" aspect={2}>
                <LineChart
                    data={sevendaysArr_}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="title" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="cancel" stroke="#b33939" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="pending" stroke="#84817a" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="done" stroke="#7158e2" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
      
    );
}
