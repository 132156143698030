import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
export default function CreateTable({fetchedCustomer,previouslyCreatedJo,tableLength,page,rowsPerPage,handleCheck,selectedAccount,handleChangePage,handleChangeRowsPerPage}) {
    const subClasses = subStyles();

 
    return (
        <Paper variant="outlined">
            <TableContainer size="small" className={subClasses.tableContainer}>
                <Table size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}  >
                    <TableHead >
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>#</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}></StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>cust Id</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Account_No.</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>MRU</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Account name</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Account type</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Address</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Date filter</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fetchedCustomer.map((ary, index) => {
                            let background_identificator = "#fff"
                            let display_handler = ""
                            let nameDisplay = ""
                            const findmatchId = previouslyCreatedJo.filter(item => item.id === ary.cust_id)
                            if(findmatchId.length > 0){
                                background_identificator = "#ced6e0"
                                display_handler = "dontshow"
                            }
                            if(ary.cust_type === "COMMERCIAL" || ary.cust_type === "INDUSTRIAL" || ary.cust_type === "GOVERNMENT"){
                                nameDisplay = ary.business_name
                            }else{
                                nameDisplay = ary.cust_fname+' '+ary.cust_lname
                            }
                            return <TableRow hover tabIndex={-1} key={index} style={{backgroundColor:background_identificator}}>
                                <TableCell align="left">{index+(rowsPerPage*page)+1}</TableCell>
                                <TableCell align="left">
                                    {display_handler === "" 
                                        ?<Checkbox
                                            onClick={() => handleCheck(ary)}
                                            edge="start"
                                            checked={selectedAccount.findIndex(x => String(x.cust_id).toUpperCase() === String(ary.cust_id).toUpperCase()) !== -1}
                                            tabIndex={-1}
                                            disableRipple />
                                        :<Checkbox
                                            color="primary"
                                            indeterminate
                                            inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                                            edge="start"
                                            checked={true}
                                            tabIndex={-1}
                                            disableRipple />
                                    } 
                                </TableCell>
                                <TableCell align="left">{ary.cust_id}</TableCell>
                                <TableCell align="left">{ary.account_no === "" ? 'N/A' : ary.account_no}</TableCell>
                                <TableCell align="left">{ary.mru === null ? "N/A" : ary.mru}</TableCell>
                                <TableCell align="left" >{nameDisplay}</TableCell>
                                <TableCell align="left" >{ary.cust_type === null ? 'N/A' : ary.cust_type}</TableCell>
                                <TableCell align="left">{ary.cust_address}</TableCell>
                                <TableCell align="left">{ary.last_date_filter}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10,50, 100]}
                component="div"
                count={tableLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}