// account status -> Active/Inactive
//customer status -> Approved/Deny/Hold

//account history -> 
    //electric
        //location
        // account_no
        // customer type 
        // address
        // findings
        // meter_number
        // date accom
        // fieldman 
        // images


    //water
        //location
        // account_no
        // customer type 
        // address
        // findings
        // meter_number
        // date accom
        // fieldman 
        // images

 export const meterImages = 'https://api.workflow.gzonetechph.com/assets/img/meter/'       