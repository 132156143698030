import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import useStyles from '../../stylesheet';
import IconButton from '@material-ui/core/IconButton';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useDispatch, useSelector } from 'react-redux'
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
export default function RecordTable({selectedProd,rowsPerPage,tableLength,page,handleChangePage,handleChangeRowsPerPage}) {
    const subClasses = subStyles();
    const classes = useStyles();
    const InventoryArr_ = useSelector(state => state.inventoryData.prodList)
    return (
        <Paper>
            <TableContainer size="small" className={subClasses.tableContainer}>
                <Table size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}  >
                    <TableHead >
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>#</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Code id</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Item type</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Name</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Description</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Price</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Quantity</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Expiry Date</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {InventoryArr_.map((ary, index) => {
                            return <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="left">{index+1}</TableCell>
                                <TableCell align="left">{ary.inventory_code}</TableCell>
                                <TableCell align="left">{ary.inventory_type}</TableCell>
                                <TableCell align="left">{ary.inventory_name}</TableCell>
                                <TableCell align="left">{ary.inventory_description === "" ? 'N/A' : ary.inventory_description}</TableCell>
                                <TableCell align="left">{ary.inventory_price}</TableCell>
                                <TableCell align="left">{ary.inventory_quantity}</TableCell>
                                <TableCell align="left">{ary.inventory_expiration === "0000-00-00 00:00:00" ? 'no expiry' : moment(ary.inventory_expiration).format('MMM DD YYYY')}</TableCell>
                                <TableCell align="left">
                                    <IconButton onClick={() => selectedProd(ary)} aria-label="delete" className={classes.margin} size="small">
                                        <CallMadeIcon fontSize="inherit" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10,50, 100]}
                component="div"
                count={tableLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}