const initialState = {
    selectedCust:[],
    updateType:"",
    history:[]
}
const custDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'custEditdata':
            return {
                ...state,
                selectedCust: action.passCustAccount
            }
        case 'customer_account_':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
} 
export default custDetails; 