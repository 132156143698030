import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';

import moment from 'moment'
import useStyles from '../../stylesheet';
import { getData } from '../../api/api'
import Widgets from './widget'
import AssignTable from './assignRec'
import AssigningHeader from './assignHead'
import LoadingGif from '../../assets/loading.gif'
import DialogAssign from './dialogAssign'
import DialogViewassign from './dialogviewAssign'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 450,
      },
    margin: {
        margin: theme.spacing(1),
      },
}));

export default function AssignJo() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableLength, settableLength] = React.useState(0);
  const [openLoader, setopenLoader] = React.useState(false);

  const [showbranch, setshowbranch] = React.useState(false);
  const [showDialog, setshowDialog] = React.useState(false);
  const [unAssignDialog, setunAssignDialog] = React.useState(false);
  const [refreshs, setrefreshs] = React.useState(false);
  const [branchID, setbranchID] = React.useState(false);
  const [companyID, setcompanyID] = React.useState(false);
  const [fieldworks, setfieldworks] = React.useState(null);
  const [totalAccounts, settotalAccounts] = React.useState(0);
  const [selectedFieldman, setselectedFieldman] = React.useState({
      fieldmanId:"",
      completeName:""
  });


  const [selectedBranch, setselectedBranch] = React.useState('');
  const [selectedtoUnassign, setselectedtoUnassign] = React.useState([]);
  const [selectedFieldwork, setselectedFieldwork] = React.useState("");
  const [insertedSearch, setinsertedSearch] = React.useState("");
  const [searchFieldman, setsearchFieldman] = React.useState("");
  const [sortBy, setsortBy] = React.useState("Accounts");
  const [filterby, setfilterby] = React.useState("");
  const [mrWithAssign, setmrWithAssign] = React.useState(0);

  const [cycleDate, setcycleDate] = React.useState(new Date());
  const [joborderDate, setjoborderDate] = React.useState(new Date());
  const [Customerbackup, setCustomerbackup] = React.useState([]);
  const [fetchedCustomer, setfetchedCustomer] = React.useState([]);
  const [selectedAccount, setselectedAccount] = React.useState([]);
  const [selectTorevert, setselectTorevert] = React.useState([]);
  const [fieldmanData, setfieldmanData] = React.useState([]);
  const [assignedMR, setassignedMR] = React.useState([]);

  const classes = useStyles();
  const subClasses = subStyles();
  const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)

    const getJotype = () => {
        setbranchID(initialBranch.b_id)
        setcompanyID(initialBranch.c_id)
        setfieldworks(initialBranch.branch_field_work)
        setfetchedCustomer([])
        // setselectedBranch(event.target.value)
    };
    const onChangeFieldwork=(event)=>{
        setselectedFieldwork(event.target.value)
    }
    const onChangejoDate=(date)=>{
        setjoborderDate(date.target.value)
    }
    const handleCheck=(val)=>{
        const currentIndex = selectedAccount.findIndex(x => String(x.jo_id).toUpperCase() === String(val.jo_id).toUpperCase())
        if(currentIndex === -1){
            selectedAccount.push(val)
            setrefreshs(!refreshs)
        }else{
            selectedAccount.splice(currentIndex, 1);
            setrefreshs(!refreshs)
        }
    }
    const handleClose = () => {
        setshowDialog(false);
        setselectedFieldman((prev)=>({
            ...prev,
            fieldmanId:"",
            completeName:""
          }))
    };

    const fetch_request=()=>{
        // setPage(0)
        // settableLength(0)
        setCustomerbackup([])
        setfetchedCustomer([])
        settotalAccounts(0)
        setfieldmanData([])
        setassignedMR([])
        setselectedAccount([])
        setopenLoader(true)
        setTimeout(() => {
            getCustomers()
        }, 300)
    }
    const getCustomers=()=>{
        if(selectedFieldwork === ""){
            alert('Fieldwork cannot be empty')
            setopenLoader(false)
        }else{
            let data = {
                company_id:companyID,
                branch_id:branchID,
                date:moment(joborderDate).format('YYYY-MM-DD'),
                jo_type:selectedFieldwork,
            }
            getData('jls/GetJobOrderDetails',data)
            .then(response=>{
                if(JSON.stringify(response).includes('Success')){
                    let reunmutable = ""
                    let filterGroups = response.data.filter(item => item.fieldman_id != null)
                    let remaining = response.data.filter(item => item.fieldman_id === null)
                    reunmutable = JSON.stringify(remaining)
                    settotalAccounts(response.data.length)
                    setfieldmanData(response.fieldman)
                    setCustomerbackup(remaining)
                    setfetchedCustomer(JSON.parse(reunmutable))
                    Fieldman_assignedStructure(filterGroups,response.fieldman,'initial')
                    setopenLoader(false)

                }
            })
        }
    }
    const selectFieldman=(val)=>{
        setselectedFieldman((prev)=>({
            ...prev,
            fieldmanId:val.user_id,
            completeName:val.completename
        }))
    }
    const submitCreate=()=>{
        setopenLoader(true)
        let joAssign = {
            company_id:companyID,
            branch_id:branchID,
            date:moment(joborderDate).format('YYYY-MM-DD'),
            jo_type:selectedFieldwork,
            fieldman_id:selectedFieldman.fieldmanId,
            fieldman_name:selectedFieldman.completeName,
            accounts:selectedAccount
        }
        getData('jls/AssignJobOrder',joAssign)
        .then(response=>{
            if(JSON.stringify(response).includes('Success')){
                let remainingJo = []
                //for removing account in remaining list
                for (let index = 0; index < selectedAccount.length; index++) {
                    //para malagyan ng fieldman id yung index bago tawagin sa Fieldman_assignedStructure function
                    selectedAccount[index].fieldman_id = joAssign.fieldman_id
                    selectedAccount[index].group = null

                    //para sa pag splice ng inassign na jo sa remaining job order
                    Customerbackup.splice(Customerbackup.findIndex(x => x.jo_id === selectedAccount[index].jo_id), 1);
                    fetchedCustomer.splice(fetchedCustomer.findIndex(x => x.jo_id === selectedAccount[index].jo_id), 1);
                }
                setshowDialog(false)
                setselectedAccount([])
                Fieldman_assignedStructure(joAssign.accounts,fieldmanData,'assigning')
            }
        })
    }
    const Fieldman_assignedStructure=(filterGroups,fieldman,type)=>{
        let groupAccounts = []
        let newArrayStruc = [];
        if(type === "assigning"){
            newArrayStruc = assignedMR
        }
        for (let d = 0; d < filterGroups.length; d++) {
            //check if fieldman ID on this accounts (filterGroups) is existine in assignedMR array
            const findAccountFieldmanId = newArrayStruc.findIndex(x => x.fieldmanData.user_id === filterGroups[d].fieldman_id)
            if(findAccountFieldmanId > -1){
                newArrayStruc[findAccountFieldmanId].assigned.push(filterGroups[d])
            }else{
                const findFieldmanDetails = fieldman.findIndex(y => y.user_id === filterGroups[d].fieldman_id)
                let fieldmanAssign = {
                    assigned:[filterGroups[d]],
                    fieldmanData:fieldman[findFieldmanDetails]
                }
                newArrayStruc.push(fieldmanAssign)
            }
        }
        setassignedMR(newArrayStruc)
        setopenLoader(false)
    }
    const handleCheckUnassign=(val)=>{
        const currentIndex = selectTorevert.findIndex(x => String(x.jo_id).toUpperCase() === String(val.jo_id).toUpperCase())
        if(currentIndex === -1){
            selectTorevert.push(val)
            setrefreshs(!refreshs)
        }else{
            selectTorevert.splice(currentIndex, 1);
            setrefreshs(!refreshs)
        }
    }
    const handleCloseUnassign = () => {
        setunAssignDialog(false);
        setselectedFieldman({
           fieldmanId:"",
           completeName:""
        })
        setselectTorevert([])
    };
    const submitUnassign=(value)=>{
        setopenLoader(true)
        let joAssign = {
            company_id:companyID,
            branch_id:branchID,
            date:moment(joborderDate).format('YYYY-MM-DD'),
            jo_type:selectedFieldwork,
            fieldman_id:selectedFieldman.fieldmanId,
            fieldman_name:selectedFieldman.completeName,
            accounts:selectTorevert
        }
        getData('jls/UnassignJobOrder',joAssign)
        .then(response=>{
            if(JSON.stringify(response).includes('Success')){
                let accountNewRemain = Customerbackup
                let reunmutable=""
                for (let index = 0; index < joAssign.accounts.length; index++) {
                    joAssign.accounts[index].fieldman_id = null
                    joAssign.accounts[index].group = null
                    accountNewRemain.push(joAssign.accounts[index])
                }
                reunmutable = JSON.stringify(accountNewRemain)
                setselectedFieldman((prev)=>({
                    ...prev,
                    fieldmanId:"",
                    completeName:""
                }))
                setselectTorevert([])
                setCustomerbackup(accountNewRemain)
                setfetchedCustomer(JSON.parse(reunmutable))
                setselectedtoUnassign([])
                setunAssignDialog(false)
                unAssignedStructure(value[0].fieldmanData.user_id,selectTorevert)
            }
        })
    }

    const unAssignedStructure=(mr_id,accounts)=>{
        let findExistingIndx = assignedMR.findIndex(y => y.fieldmanData.user_id === mr_id)
        if(findExistingIndx > -1){
            for (let a = 0; a < accounts.length; a++) {
                assignedMR[findExistingIndx].assigned.splice(assignedMR[findExistingIndx].assigned.indexOf(accounts[a].jo_id), 1);
            }
        }
        setopenLoader(false)
    }
    
    const handleWidgetAssigned=(val)=>{
        setselectedtoUnassign([val])
        setunAssignDialog(true)
    }

    useEffect(()=>{
        getJotype()
    },[initialBranch])
  return (
    <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" gutterBottom>
            <Typography color="textPrimary">Home Page</Typography>
            <Typography color="textPrimary">CRM</Typography>
            <Typography color="textPrimary">Job Order</Typography>
            <Link>Assign</Link>
        </Breadcrumbs>
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" style={{marginBottom:10}}> <b style={{color:'#786fa6',}}>Assign job order</b></Typography>
                        {/* <Card variant="outlined" style={{ marginTop: 10, }}>
                            <CardContent>
                                <Typography variant="h5" style={{ fontSize: 13, marginBottom: 10 }}> Assigned Fieldman </Typography>
                                <div style={{ height: 200, overflow: 'auto' }}>
                                    <List component="nav" className={classes.root} aria-label="contacts">
                                        {assignedMR.map((val, index) => {
                                            if (val.assigned.length > 0) {
                                                return <>
                                                    <ListItem button key={index}
                                                        onClick={() => {
                                                            // selectFieldman(val.fieldmanData)
                                                            setselectedtoUnassign([val])
                                                            setunAssignDialog(true)
                                                        }}
                                                        style={{ backgroundColor: '#fff' }}>
                                                        <ListItemText primary={val.fieldmanData.completename} style={{ color: '#3d3d3d' }} />
                                                        <ListItemSecondaryAction>
                                                            <p><b>{val.assigned.length}</b></p>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </>
                                            }
                                        })}
                                    </List>
                                </div>
                            </CardContent>
                        </Card> */}
                <Card variant="outlined" style={{marginBottom:10}}>
                    <CardContent>
                        <Widgets 
                            total={totalAccounts} 
                            remaining={Customerbackup.length} 
                            assignedMR={assignedMR}
                            handleWidgetAssigned={handleWidgetAssigned}
                            openLoader={openLoader}/>
                    </CardContent>
                </Card>
                <Card variant="outlined" >
                    <CardContent style={{ height: 530 }}>
                        <AssigningHeader onChangeFieldwork={onChangeFieldwork} selectedFieldwork={selectedFieldwork} fieldworks={fieldworks} onChangejoDate={onChangejoDate} joborderDate={joborderDate} fetch_request={fetch_request}/>
                        <AssignTable fetchedCustomer={fetchedCustomer} handleCheck={handleCheck} selectedAccount={selectedAccount}/>
                    </CardContent>
                    <CardActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => {setshowDialog(true)}}style={{ marginTop: 15, alignSelf: 'flex-end',backgroundColor:'#0984e3',color:'#fff' }}> Assign </Button>
                        </div>
                    </CardActions>
                </Card>
            </CardContent>
        </Card>     
        

        {/* selecting fieldman for assigning */}
        <DialogAssign 
            showDialog={showDialog} 
            selectedAccount={selectedAccount} 
            handleClose={handleClose} 
            insertedSearch={insertedSearch} 
            fieldmanData={fieldmanData} 
            selectedFieldman={selectedFieldman}
            selectFieldman={selectFieldman}
            submitCreate={submitCreate}/>

        <DialogViewassign
            unAssignDialog={unAssignDialog}
            handleCloseUnassign={handleCloseUnassign}
            selectedtoUnassign={selectedtoUnassign} 
            selectTorevert={selectTorevert}
            handleCheckUnassign={handleCheckUnassign}
            submitUnassign={submitUnassign}/>
        <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:'2500'}}>
            <img src={LoadingGif} style={{ width: 100, height: 100}} />
        </Backdrop>   
    </div>
  );
}