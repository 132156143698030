const initialState = {
    pendingCustomer: [],
    approvedCustomer:[],
    masterData:[]
}
const differentCustomer = (state = initialState, action) => {
    switch (action.type) {
        case 'pendingApplication':
            return {
                ...state,
                pendingCustomer: action.passAplicationpending
            }
        case 'approvedApplication':
            return {
                ...state,
                approvedCustomer: action.passAplicationapprove
            }
        case 'CustomersArr_':
            return {
                ...state,
                masterData: action.passMaster_
            }
        default:
            return state;
    }
}
export default differentCustomer; 