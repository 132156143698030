import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ImageIcon from '@material-ui/icons/Image';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CallMadeIcon from '@material-ui/icons/CallMade';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import useStyles from '../../stylesheet';
import {  getData } from '../../api/api'
import moment from 'moment'
import AlertComponent from '../../material/snackbar'
import WidgetInv from './widgets'
import RecordHeader from './recordHead'
import RecordTable from './recordTable'
import DialogViewProduct from './dialogviewProduct'
import DialogAddProduct from './dialogaddProduct'
import LoadingGif from '../../assets/loading.gif'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 450,
      },
    margin: {
        margin: theme.spacing(1),
      },
  }));
export default function MainInventory() {
    const [openSnack, setopenSnack] = React.useState(false);
    const [alertStyle, setalertStyle] = React.useState({
        alertMessage:'',
        alertType:'Success'
    });
    const [branchData, setbranchData] = React.useState({
        branch_id:"",
        company_id:"",
        fieldworks:null
    });
    const [openLoader, setopenLoader] = React.useState(false);
    const [addItemdisplay, setaddItemdisplay] = React.useState(false);
    const [selectedDisplay, setselectedDisplay] = React.useState(false);
    const [tableLength, settableLength] = React.useState(0);
    const [insertedSearch, setinsertedSearch] = React.useState("");
    const [selectedBranch, setselectedBranch] = React.useState("");
    const [branchID, setbranchID] = React.useState("");
    const [companyID, setcompanyID] = React.useState("");
    const [branchName, setbranchName] = React.useState("");
    const [prod_history, setprod_history] = React.useState([]);
    const [product_choice, setproduct_choice] = React.useState([]);
    const [newProdState, setnewProdState] = React.useState({
        prod_name:"",
        prod_code:"",
        prod_des:"",
        prod_qty:"",
        prod_type:"",
        prod_price:""
    });
    const [submitIdentifier, setsubmitIdentifier] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchedName, setsearchedName] = React.useState("");
    const Dispatch = useDispatch();
    const classes = useStyles();
    const subClasses = subStyles();
    const AccountData = useSelector(state => state.userDataReducers.userProfile)
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)

    const getJotype = () => {
        setbranchData({
            branch_id:initialBranch.b_id,
            company_id:initialBranch.c_id,
            fieldworks:initialBranch.branch_field_work
        })
        submitBranch(initialBranch.b_id,page,searchedName)
    };
    const submitBranch=(b_id,newPage,search_)=>{
        setopenLoader(true)
        let getItems ={
            branch_id:b_id,
            page:newPage+1,
            limit:rowsPerPage,
            search:search_
        }
        getData('Inventory/GetInventoryDetails',getItems)
        .then((res)=>{
            settableLength(res.inventory_count)
            setopenLoader(false)
            Dispatch({
                type:'productArr_',
                passProduct:res.inventory,
            })
        })
    }
    const handleCloseDialog=()=>{
        setselectedDisplay(false)
        setprod_history([])
        setproduct_choice([])
    }
    const handleCloseAddProd=()=>{
        setaddItemdisplay(false) 
    }
    const selectedProd=(values)=>{
        setselectedDisplay(true)
        setproduct_choice(values)
        let prod_param={
          inventory_id:values.inventory_id
        }
        getData('Inventory/GetInventoryItemLogs',prod_param)
        .then((res)=>{
          setprod_history(res.item)
        })
    }
    const onChangeProd=(e)=>{
        setnewProdState((prev)=>({
          ...prev,
          [e.target.name]:e.target.value
        }))
    }
    const submitAdded=()=>{
        setopenLoader(true)
        let formState = {
          app_id:"17",
          company_id:branchData.company_id,
          branch_id:branchData.branch_id,
          inventory_name:newProdState.prod_name,
          inventory_code:newProdState.prod_code,
          inventory_description:newProdState.prod_des,
          inventory_quantity:newProdState.prod_qty,
          inventory_type:newProdState.prod_type,
          inventory_price:newProdState.prod_price
        }
        getData('Inventory/LogInventory',formState)
        .then((res)=>{
          setopenLoader(false)
          if(res.status === 'Success'){
              Dispatch({
                type:'newlyAdded',
                passNewlyprod:res.data,
              })
              setnewProdState({
                prod_name:"",
                prod_code:"",
                prod_des:"",
                prod_qty:"",
                prod_type:"",
                prod_price:""
              })
              var snackMessage = 'product'+newProdState.prod_code+''
              setalertStyle({alertMessage:snackMessage,alertType:'Success'})
              setopenSnack(true)
          }else{
            setalertStyle({alertMessage:'Failed to add new product',alertType:'Warning'})
            setopenSnack(true)
          }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        submitBranch(branchData.branch_id,newPage,searchedName)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onChange = (event) => {
        var valuesearch = event.target.value
        setsearchedName(valuesearch);
    };
    const fetchCustomer=()=>{
        if(searchedName === ""){
          setalertStyle({alertMessage:'Incorrect entry, Please try again',alertType:'Error'})
          setopenSnack(true)
          setopenLoader(false)
        }else{
            setsubmitIdentifier(true)
            Dispatch({
                type:'productArr_',
                passProduct:[],
            })
            submitBranch(branchData.branch_id,0,searchedName)
        }
    }
    const clearFilter=()=>{
        setsearchedName("")
        setsubmitIdentifier(false)
        Dispatch({
            type:'productArr_',
            passProduct:[],
        })
        setTimeout(()=>{
            submitBranch(branchData.branch_id,0,"")
        },200)
    } 
    useEffect(()=>{
        getJotype()
    },[initialBranch])
    return (
        <div className={classes.root}>
            <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
            <Breadcrumbs aria-label="breadcrumb" gutterBottom>
                <Typography color="textPrimary">Home Page</Typography>
                <Typography color="textPrimary">CRM</Typography>
                <Typography color="textPrimary">Inventory</Typography>
                <Link>Records</Link>
            </Breadcrumbs>
            <Card variant="outlined" style={{marginTop:15}}>
                <CardContent>
                    <Grid container spacing={1} style={{marginTop:10,marginBottom:10}}>
                        <Grid item xs={12} md={6} >
                            <Typography variant="h5"> <b style={{ color: '#786fa6', }}>Inventory list</b></Typography>
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                                <Button
                                    onClick={()=>setaddItemdisplay(true)}
                                    style={{backgroundColor:'#778beb',height:40,color:'#fff'}}
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<AddIcon />}> Add item </Button>
                           </div>
                        </Grid>
                    </Grid>
                    <Card variant="outlined" style={{marginTop:15}}>
                        <CardContent>
                            <WidgetInv/>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" style={{marginTop:15}}>
                        <CardContent>
                            <RecordHeader
                                onChange={onChange}
                                searchedName={searchedName}
                                fetchCustomer={fetchCustomer}
                                submitIdentifier={submitIdentifier}
                                clearFilter={clearFilter}/>
                            <RecordTable 
                                selectedProd={selectedProd}
                                rowsPerPage={rowsPerPage}
                                tableLength={tableLength}
                                page={page}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}/>
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>


            <DialogAddProduct 
                addItemdisplay={addItemdisplay}
                handleCloseAddProd={handleCloseAddProd}
                newProdState={newProdState}
                onChangeProd={onChangeProd}
                submitAdded={submitAdded}/>

            {/* View product details */}
            <DialogViewProduct
                selectedDisplay={selectedDisplay}
                handleCloseDialog={handleCloseDialog}
                prod_history={prod_history}
                product_choice={product_choice}/>

            <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:'2500'}}>
                <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </Backdrop>   
        </div>
    );
}