import axios from "axios"
// import swal from "sweetalert/dist/sweetalert.min.js";
let serverurl = "https://api.workflow.gzonetechph.com/";
// let serverurl = "http://192.168.0.10/backend/api/"; 
// let serverurl ="http://192.168.5.21/backend/beta_api/";
// let serverurl = "http://beta.api.pacificweb.com.ph/";
// let serverurl ="http://192.168.1.44/api_pacific/";
// let serverurl ="http://192.168.2.36/beta_api3/";
let key = "?key=PocketHR@20190208&type=web";
let key2 = "?key=12345ABCFleet";

export function getData(method,param,discon) {
    return new Promise((resolve, reject) => {
        axios
            .post(serverurl + method + key, {
                param
            })
            .then(res => {
                resolve(res.data);
            });
    });
}


// if(count($returnCustomized) > 0){
//     $parent_id = "";
//     $formParent = "";
//     $formParse = array();
//     foreach($returnCustomized as $formDetails){
//         $parent_id = $formDetails['form_id'];
//         $formParent = $formDetails['json_data'];
//     }
//     //idedecode ko yung formParent
//     $formparsing = json_decode($formParent);
//     //ichecheck ko kung array ang nakuha ko sa decode
//     if(isArray($formparsing)){
//         //kapag hindi naputol ang pagkakadecode ko ito ang ipapasa ko sa front end
//         $formParse = $formparsing;
//     }else{
//         //kapag naputol naman ang pagkakadecode, tsaka ako mag fefetch ng formdetails nito sa database gamit ang parent_id
//         $returnFormDetails = $this->customer_model->fetchFormDetails($parent_id);
//     }
    

// }

// sa pag update ng details ng tao, para malaman ko kung san ko ididisplay ang data nung client, pag default. sa datafield ko icocompare, kapag additional naman, sa fd_name