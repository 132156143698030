import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useDispatch,useSelector } from 'react-redux'
import AlertComponent from '../../material/snackbar'
import useStyles from '../../stylesheet';
import { getData } from '../../api/api'
import BranchsDialog from '../../initialRender/mstrDialog'
import SalesDialog from '../../initialRender/addsales'
import SalesTable from './salesRec'
import SalesFilter from './salesfilterHead'
import SalesHistoryDialog from './salesDialogHistory'
import LoadingGif from '../../assets/loading.gif'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        width: '100%',
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        height: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  }));

 
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  

export default function Sales() {
    const classes = useStyles();
    const subClasses = subStyles();

    const [searchedName, setsearchedName] = React.useState("");
    const [displaysales, setdisplaysales] = React.useState(false);

    const [openLoader, setopenLoader] = React.useState(false);
    const [historyDialog, sethistoryDialog] = React.useState(false);
    const [cust_type, setcust_type] = React.useState("");
    const [tableLength, settableLength] = React.useState(0);
    const [submitIdentifier, setsubmitIdentifier] = React.useState(false);
    const [openSnack, setopenSnack] = React.useState(false);
    const [alertStyle, setalertStyle] = React.useState({
        alertMessage:'',
        alertType:'Success'
    });
    const [branchData, setbranchData] = React.useState({
      branch_id:"",
      company_id:"",
      fieldworks:null
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [cust_details, setcust_details] = React.useState([]);
    const [customer_credential, setcustomer_credential] = React.useState([]);

    const Dispatch = useDispatch();
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)
    
    const getJotype = () => {
      setbranchData({
          branch_id:initialBranch.b_id,
          company_id:initialBranch.c_id,
          fieldworks:initialBranch.branch_field_work
      })
      fetchBranchClients(initialBranch.c_id,initialBranch.b_id,page,searchedName,cust_type)
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      fetchBranchClients(branchData.company_id,branchData.branch_id,newPage,searchedName,cust_type)
    };

    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };

    const onChange = (event) => {
      var valuesearch = event.target.value
      setsearchedName(valuesearch);
    };
    
    const onChangeCusttype=(e)=>{
      setcust_type(e)
      Dispatch({
          type:'approvedApplication',
          passAplicationapprove:[],
      })
      fetchBranchClients(branchData.company_id,branchData.branch_id,0,searchedName,e)
    }

    const fetchCustomer=()=>{
      setopenLoader(true)
      if(searchedName === ""){
        setalertStyle({alertMessage:'Incorrect entry, Please try again',alertType:'Error'})
        setopenSnack(true)
        setopenLoader(false)
      }else{
        setsubmitIdentifier(true)
        Dispatch({
            type:'approvedApplication',
            passAplicationapprove:[],
        })
        fetchBranchClients(branchData.company_id,branchData.branch_id,0,searchedName,cust_type)
      }
    }

    const fetchBranchClients=(c_id,b_id,newPage,search,type)=>{
      setopenLoader(true)
      let typeSelected = type
      if(type === "ALL"){
          typeSelected = ""
      }
      let customers = {
        company_id:c_id,
        branch_id:b_id,
        page:newPage+1,
        limit:rowsPerPage,
        type:typeSelected,
        search:search
      }
      Promise.all([
        getData('Customer/GetAllCustomerDetails', customers),
        getData('Inventory/GetInventoryDetails', customers)
      ])
      .then((res) => {
        setopenLoader(false)
        if (Array.isArray(res)) {
          Dispatch({
            type: 'approvedApplication',
            passAplicationapprove: res[0].masterlist,
          })
          settableLength(res[0].masterlist_count)
          if(res[1].inventory.length > 0){
            Dispatch({
              type:'productArr_',
              passProduct:res[1].inventory,
            })
          }
          var snackMessage = res[0].masterlist_count + ' customer(s) found!'
          setalertStyle({ alertMessage: snackMessage, alertType: 'Success' })
          setopenSnack(true)
        } else {
          setalertStyle({ alertMessage: 'Customer not found.', alertType: 'Warning' })
          setopenSnack(true)
        }
      })
    }

    const showDisplay=(value)=>{
      setdisplaysales(!displaysales)
      setcust_details(value)
    }

    const addSalesAction=(action)=>{
      setdisplaysales(!displaysales)
      var snackMessage = action.message
      setalertStyle({ alertMessage: snackMessage, alertType:action.type})
      setopenSnack(true)
    }

    const clearFilter=()=>{
      setcust_type("")
      setsearchedName("")
      setsubmitIdentifier(false)
      Dispatch({
        type:'approvedApplication',
        passAplicationapprove:[],
      })
      setTimeout(()=>{
          fetchBranchClients(branchData.company_id,branchData.branch_id,0,"","")
      },200)
    } 

    const openHistoryDialog=(ary)=>{
      setcustomer_credential([ary])
      sethistoryDialog(true)
    }

    const handleCloseHistory=()=>{
      setcustomer_credential([])
      sethistoryDialog(false)
    }

 
    useEffect(()=>{
      getJotype()
    },[initialBranch])
  return (
    <div className={classes.root}>
        <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
        <Breadcrumbs aria-label="breadcrumb" gutterBottom>
          <Typography color="textPrimary">Home Page</Typography>
          <Typography color="textPrimary">CRM</Typography>
          <Link>Sales</Link>
        </Breadcrumbs>
         
        {/* {displayContainer === 'allCustomer'  */}

        <Grid container >
          <Grid item xs={12} md={12} >
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5"> <b style={{color:'#786fa6',marginBottom:30}}>Sales Customer</b></Typography>
                  <SalesFilter 
                    onChange={onChange}
                    searchedName={searchedName}
                    fetchCustomer={fetchCustomer}
                    onChangeCusttype={onChangeCusttype}
                    cust_type={cust_type}
                    clearFilter={clearFilter}
                    submitIdentifier={submitIdentifier}/>
                  <SalesTable 
                    rowsPerPage={rowsPerPage}
                    tableLength={tableLength}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPag={handleChangeRowsPerPage}
                    showDisplay={showDisplay}
                    openHistoryDialog={openHistoryDialog}/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
        <SalesDialog 
          displaySales={displaysales} 
          showDisplay={showDisplay} 
          cust_details={cust_details} 
          addSalesAction={addSalesAction}/>
        <SalesHistoryDialog 
          historyDialog={historyDialog}
          handleCloseHistory={handleCloseHistory}
          customer_credential={customer_credential}/>

        <Backdrop className={subClasses.backdrop} open={openLoader}>
          <img src={LoadingGif} style={{ width: 100, height: 100}} />
        </Backdrop>                                   
    </div>
  );
}