import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts';

const data = [
    { name: "NE Send", completed: 230, failed: 335, inprogress: 453 },
    { name: "NE Resend", completed: 335, failed: 330, inprogress: 345 },
    {
      name: "Miles Orchestrator",
      completed: 537,
      failed: 243,
      inprogress: 2110
    },
    {
      name: "Commissions Payment Orch",
      completed: 132,
      failed: 328,
      inprogress: 540
    },
    {
      name: "Business Integrators",
      completed: 530,
      failed: 145,
      inprogress: 335
    },
    { name: "SmartTrack", completed: 538, failed: 312, inprogress: 110 }
  ];
  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props;
  
    return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
  };
export default function Percentages() {

    return (
        <div style={{width:'100%'}}>
            <ResponsiveContainer width="100%" aspect={2}>
            {/* <BarChart
                layout="vertical"
                data={data}
                margin={{ left: 50, right: 50 }}
                stackOffset="expand" >
                <XAxis hide type="number" />
                <YAxis
                type="category"
                dataKey="name"
                stroke="#FFFFFF"
                fontSize="12"
                />
                <Tooltip />
                <Bar dataKey="failed" fill="#dd7876" stackId="a">
                
                </Bar>
                <Bar dataKey="completed" fill="#82ba7f" stackId="a">
                
                </Bar>
                <Bar dataKey="inprogress" fill="#76a8dd" stackId="a">
                
                </Bar>
            </BarChart> */}

            <BarChart
                layout="vertical"
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis  hide type="number"/>
                <YAxis 
                  type="category"
                  dataKey="name"
                  stroke="#4b4b4b"
                  fontSize="12"/>
                <Tooltip />
                <Legend />
                <Bar dataKey="completed" stackId="a" fill="#8884d8">
                  <LabelList
                    dataKey="completed"
                    position="center"
                    content={renderCustomizedLabel}/>
                </Bar>
                <Bar dataKey="failed" stackId="a" fill="#82ca9d">
                  <LabelList
                      dataKey="failed"
                      position="center"
                      content={renderCustomizedLabel}/>
                </Bar>
            </BarChart>
            </ResponsiveContainer>
        </div>
    );
}