import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print'

import moment from 'moment';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import useStyles from '../stylesheet';

const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export default function MeterInformation({userMeter}) {
  const classes = useStyles();
  const subClasses = subStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [CustomerData, setCustomerData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (

        <Grid container >
            <Grid item xs={12} md={12} >
            <Paper>
                <TableContainer  style={{ maxHeight: 400, width: "100%" }} size="small">
                    <Table 
                    size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Status</TableCell>
                                <TableCell style={{ backgroundColor: "#574b90",  color: "#f5f6fa" }} align="center"> Meter No. </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Serial </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Seal No. </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Route </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Meter Type </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Meter Brand </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Sequence No. </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Multiplier </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Date Installed </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Installed By </TableCell>
                                <TableCell style={{ backgroundColor: "#574b90", color: "#f5f6fa" }} align="left"> Reading Tool </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {CustomerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ary,index) => {
                        return<TableRow hover tabIndex={-1} key={index}>
                                <TableCell  align="left" ></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                                <TableCell  align="left"></TableCell>
                            </TableRow>
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={CustomerData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}/>
                </Paper>
            </Grid>
        </Grid>
  );
}