import React,{useState,useRef,useEffect  } from 'react'
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';
import { useDispatch,useSelector } from 'react-redux'
import moment from 'moment'
import useStyles from '../stylesheet';
import { getData } from '../api/api'
import MasterFilter from './masterFilterhead'
import MasterTable from './masterRec'
import DialogViewCustomer from './dialogviewCustomer'
import DialogViewHistory from './dialogviewHistory'
import LoadingGif from '../assets/loading.gif'
import AlertComponent from '../material/snackbar'

const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 450,
      },
    margin: {
        margin: theme.spacing(1),
      },
}));

export default function MasterData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableLength, settableLength] = React.useState(0);
    const [openLoader, setopenLoader] = React.useState(false);
    const [displayContainer, setdisplayContainer] = React.useState(false);
    const [displayHistory, setdisplayHistory] = React.useState(false);
    const [openSnack, setopenSnack] = React.useState(false);
    const [searchedName, setsearchedName] = React.useState("");
    const [cust_type, setcust_type] = React.useState("");
    const [alertStyle, setalertStyle] = React.useState({
        alertMessage:'',
        alertType:'Success'
    });
    const [branchData, setbranchData] = React.useState({
        branch_id:"",
        company_id:"",
        fieldworks:null
    });
    const [submitIdentifier, setsubmitIdentifier] = React.useState(false);
    const [singleAccom, setsingleAccom] = React.useState([]);
    const [selectedCustomerData, setselectedCustomerData] = React.useState([]);

    const classes = useStyles();
    const subClasses = subStyles();
    const Dispatch = useDispatch();
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)


    const getJotype = () => {
        setbranchData({
            branch_id:initialBranch.b_id,
            company_id:initialBranch.c_id,
            fieldworks:initialBranch.branch_field_work
        })
        fetchBranchClients(initialBranch.b_id,initialBranch.c_id,page,searchedName,cust_type)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchBranchClients(branchData.branch_id,branchData.company_id,newPage,searchedName,cust_type)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchBranchClients=(branch_id,company_id,newPage,search,type)=>{
        setopenLoader(true)
        let typeSelected = type
        if(type === "ALL"){
            typeSelected = ""
        }
        let data = {
            company_id:company_id,
            branch_id:branch_id,
            page:newPage+1,
            limit:rowsPerPage,
            type:typeSelected,
            search:search
        }
        getData('Customer/GetAllCustomerDetails',data)
        .then((res)=>{
            if( Array.isArray(res.masterlist)){
                Dispatch({
                    type:'CustomersArr_',
                    passMaster_:res.masterlist,
                })
                settableLength(res.masterlist_count)
                var snackMessage = res.masterlist_count+' customer(s) found!'
                setalertStyle({alertMessage:snackMessage,alertType:'Success'})
                setopenSnack(true)
                setopenLoader(false)
            }else{
                setalertStyle({alertMessage:'Customer not found.',alertType:'Warning'})
                setopenSnack(true)
                setopenLoader(false)
            }
        })
    }

    const handleCloseDialog=()=>{
        setdisplayContainer(false)
    }

    const handleOpenDialog=(ary)=>{
        setopenLoader(true)
        var pushCustomer = []
        pushCustomer.push(ary)
        setselectedCustomerData(pushCustomer)
        setTimeout(() => {
            setopenLoader(false)
            setdisplayContainer(true)
        }, 200)
    }

    const selectedHistory=(data)=>{
        setsingleAccom(JSON.parse(data))
        setdisplayHistory(true)
    }

    const handleCloseHistory=()=>{
        setdisplayHistory(false)
    }

    const onChange = (event) => {
        var valuesearch = event.target.value
        setsearchedName(valuesearch);
    };

    const onChangeCusttype=(e)=>{
        setcust_type(e)
        Dispatch({
            type:'CustomersArr_',
            passMaster_:[],
        })
        fetchBranchClients(branchData.branch_id,branchData.company_id,0,searchedName,e)
    }
    const fetchCustomer=()=>{
        setopenLoader(true)
        if(searchedName === ""){
          setalertStyle({alertMessage:'Incorrect entry, Please try again',alertType:'Error'})
          setopenSnack(true)
          setopenLoader(false)
        }else{
            setsubmitIdentifier(true)
            Dispatch({
                type:'CustomersArr_',
                passMaster_:[],
            })
            fetchBranchClients(branchData.branch_id,branchData.company_id,0,searchedName,cust_type)
        }
    }
 
    const clearFilter=()=>{
        setcust_type("")
        setsearchedName("")
        setsubmitIdentifier(false)
        Dispatch({
            type:'CustomersArr_',
            passMaster_:[],
        })
        setTimeout(()=>{
            fetchBranchClients(branchData.branch_id,branchData.company_id,0,"","")
        },200)
    }

    useEffect(()=>{
        getJotype()
    },[initialBranch])

    return (
        <div className={classes.root}>
            <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
            <Breadcrumbs aria-label="breadcrumb" gutterBottom>
                <Typography color="textPrimary">Home Page</Typography>
                <Typography color="textPrimary">CRM</Typography>
                <Link>Customer Master Data</Link>
            </Breadcrumbs>
            <Card variant="outlined" style={{marginTop:15}}>
                <CardContent>
                    <Typography variant="h5" style={{marginBottom:20}}> <b style={{color:'#786fa6',}}>Customers Master Data</b></Typography>
                    <MasterFilter
                        onChange={onChange}
                        searchedName={searchedName}
                        fetchCustomer={fetchCustomer}
                        onChangeCusttype={onChangeCusttype}
                        cust_type={cust_type}
                        clearFilter={clearFilter}
                        submitIdentifier={submitIdentifier}/>
                    <MasterTable
                        rowsPerPage={rowsPerPage}
                        page={page}
                        tableLength={tableLength}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleOpenDialog={handleOpenDialog}/>
                </CardContent>
            </Card>

            <DialogViewCustomer
                displayContainer={displayContainer}
                selectedCustomerData={selectedCustomerData}
                selectedHistory={selectedHistory}
                handleCloseDialog={handleCloseDialog}/>
            <DialogViewHistory
                displayHistory={displayHistory}
                singleAccom={singleAccom}
                handleCloseHistory={handleCloseHistory}/>

            <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:'2500'}}>
                <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </Backdrop>   
        </div>
    );
}