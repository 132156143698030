import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useStyles from '../stylesheet';
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiAlert from '@material-ui/lab/Alert';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DeleteIcon from '@material-ui/icons/Delete';
import { InsertEmoticonRounded } from '@material-ui/icons';
import moment from 'moment';
import { getData } from '../api/api'
import LoadingGif from '../assets/loading.gif'
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function SalesDialog({ displaySales,showDisplay,cust_details,addSalesAction}) {
    const classes = useStyles();
    const Dispatch = useDispatch();
    const [refreshs, setrefreshs] = React.useState(false);
    const [loadingDisplay, setloadingDisplay] = React.useState(false);
    const [productvalue, setproductvalue] = React.useState("");
    const [alertDisplay,setalertDisplay] = React.useState({
        showAlert:false,
        alertMessage:"",
        alertType:''
    })
    const [buttonAction,setbuttonAction] = React.useState("Add")
    const [setTimer,setsetTimer] = React.useState("")
    const [subTotal,setsubTotal] = React.useState("")
    const [insertedQty,setinsertedQty] = React.useState("")


    const [productAdded,setproductAdded] = React.useState([])
    const [newProdState, setnewProdState] = React.useState([]);

    const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)
    const InventoryArr_ = useSelector(state => state.inventoryData.prodList)
    const user_Data = useSelector(state => state.userDataReducers.userProfile)

    const onChangeProd=(e)=>{
        let parsingValue = JSON.parse(e.target.value)
        setnewProdState(parsingValue)
        setproductvalue(e.target.value)
        setinsertedQty("")
    }

    const getTotalPrice=(e)=>{
        let passQty = e.target.value
        setinsertedQty(e.target.value)
        clearTimeout(setTimer)
        const timerSet = setTimeout(() => {
            computePrice(passQty)
        }, 500)
        setsubTotal("")
        setsetTimer(timerSet)
    }

    const computePrice=(qty)=>{
        if(parseInt(qty) > parseInt(newProdState.inventory_quantity)){
            setalertDisplay({
                showAlert:true,
                alertMessage:"Invalid amount. Inserted quantity cannot be greater than available stocks.",
                alertType:"warning"
            })
        }else if(parseInt(qty) <= 0){
            setalertDisplay({
                showAlert:true,
                alertMessage:"Invalid inserted amount.",
                alertType:"warning"
            })
        }else{
            if(qty !== ""){
                let ProductSum = parseInt(qty) * parseFloat(newProdState.inventory_price)
                setsubTotal(ProductSum)
            }
            setalertDisplay({
                showAlert:false,
                alertMessage:"",
                alertType:""
            })
        }
    }

    const addProdtoSale=()=>{
        const findExistingIndex = productAdded.findIndex(x => x.inventory_id === newProdState.inventory_id)
        if(findExistingIndex > -1){
            let totalQuantity = parseInt(productAdded[findExistingIndex].product_qty) + parseInt(insertedQty)
            if(parseInt(totalQuantity) > parseInt(newProdState.inventory_quantity)){
                setalertDisplay({
                    showAlert:true,
                    alertMessage:"Invalid inserted quantity, limit reached.",
                    alertType:'warning'
                })
            }else{
                productAdded[findExistingIndex].product_qty = parseInt(productAdded[findExistingIndex].product_qty) + parseInt(insertedQty)
                productAdded[findExistingIndex].prod_subtotal = parseFloat(productAdded[findExistingIndex].prod_subtotal) + parseFloat(subTotal)
                setrefreshs(!refreshs)
            }
        }else{
            let prod_struct = {
                inventory_id:newProdState.inventory_id,
                product_name:newProdState.inventory_name,
                product_qty:insertedQty,
                product_price:newProdState.inventory_price,
                prod_subtotal:subTotal
            }
            productAdded.push(prod_struct)
            setrefreshs(!refreshs)
        }
    }

    const UpdateProdtoSale=()=>{
            const findExistingIndex = productAdded.findIndex(x => x.inventory_id === newProdState.inventory_id)
            let totalQuantity = parseInt(insertedQty)
            if(parseInt(totalQuantity) > parseInt(newProdState.inventory_quantity)){
                setalertDisplay({
                    showAlert:true,
                    alertMessage:"Invalid inserted quantity, limit reached.",
                    alertType:'warning'
                })
            }else{
                productAdded[findExistingIndex].product_qty = parseInt(insertedQty)
                productAdded[findExistingIndex].prod_subtotal = parseFloat(subTotal)
                setrefreshs(!refreshs)
                setbuttonAction("Add")
            }
       
    }

    const salesDisplay=()=>{
        setnewProdState([])
        setalertDisplay({
            showAlert:false,
            alertMessage:"",
            alertType:''
        })
        setproductAdded([])
        setproductvalue("")
        setinsertedQty("")
        setsubTotal("")
        showDisplay()
    }

    const removeItems=(value)=>{
      var newValueLeft = productAdded.filter(item => item.inventory_id !== value.inventory_id);
      setproductAdded(newValueLeft)
    }

    const editItems=(value)=>{
        const findExistingIndex = InventoryArr_.findIndex(x => x.inventory_id === value.inventory_id)
        setnewProdState(InventoryArr_[findExistingIndex])
        setinsertedQty(value.product_qty)
        setbuttonAction('Update')
    }

    const submitAdd=()=>{
        //first check if there are added product
        if(productAdded.length > 0){

            //check kung may laman ang user_data na reducers dahil pag nag rerefresh ay nawawalan ng laman ang reducer
            if (user_Data.length > 0) {
                // itemid, itemactivity = out, itemchanges = quantity, datetime = currdate, user_id 
                let sumtotalPrice = productAdded.reduce((previousValue, currentValue) => previousValue + currentValue.prod_subtotal, 0)
                let currdate = new Date()
                let order_Details_ = []
                let nameDisplay = ""
                for (let index = 0; index < productAdded.length; index++) {
                    let itemsturc = {
                        item_id: productAdded[index].inventory_id,
                        item_activity: "out",
                        item_changes: productAdded[index].product_qty,
                        item_log_timestamp: moment(currdate).format('YYYY-MM-DD HH:ss'),
                        item_log_remarks:"",
                        item_name:productAdded[index].product_name,
                        item_location:"",
                        user_id: user_Data[0].user_id,
                        cust_id: cust_details.cust_id,
                    }
                    order_Details_.push(itemsturc)
                }
                if(cust_details.cust_type === "COMMERCIAL" || cust_details.cust_type === "INDUSTRIAL" || cust_details.cust_type === "GOVERNMENT"){
                  nameDisplay = cust_details.business_name
                }else{
                  nameDisplay = cust_details.cust_fname+' '+cust_details.cust_lname
                }
                let submitStruc = {
                    upload_type:"Delivery",
                    id: cust_details.cust_id,
                    bid: cust_details.branch_id,
                    mru: cust_details.mru,
                    account_number: cust_details.account_no,
                    seq_no: cust_details.seq_no, //wala akong makita na sequence number sa details ng customer
                    meter_no: cust_details.meter_num,
                    name: nameDisplay,
                    address: cust_details.cust_address,
                    total: sumtotalPrice, //' overall total price',
                    date_added: moment(currdate).format('YYYY-MM-DD'),
                    date_filter: moment(currdate).format('YYYY-MM-DD '), //'current date yyyy-mm-dd'
                    app_id: '10',
                    bill_cycle: moment(currdate).format('MMYY'),//'current date na naka format na billcycle 202203 ata or 032022',
                    order_details: order_Details_, //'array ng inventory na iniadd mo',
                }
                getData('Inventory/LogSalesJobOrder', submitStruc)
                .then((res) => {
                    if(res.status === 'Success'){
                        setloadingDisplay(false)
                        let snack={
                            message:"Successfuly added",
                            type:'Success'
                        }
                        setnewProdState([])
                        setalertDisplay({
                            showAlert:false,
                            alertMessage:"",
                            alertType:''
                        })
                        setproductAdded([])
                        setproductvalue("")
                        setinsertedQty("")
                        setsubTotal("")
                        addSalesAction(snack)
                    }else{
                        setloadingDisplay(false)
                        setalertDisplay({
                            showAlert:true,
                            alertMessage:"Failed to post your request.",
                            alertType:'warning'
                        })
                    }
                })
            }else{
                setloadingDisplay(false)
                setalertDisplay({
                    showAlert:true,
                    alertMessage:"Invalid structure format. No id found",
                    alertType:'warning'
                })
            }
        }else{
            //if no added product
            setloadingDisplay(false)
            setalertDisplay({
                showAlert:true,
                alertMessage:"No inserted products found.",
                alertType:'warning'
            })
        }
    }

    useEffect(() => {
    }, [])
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={displaySales}
            // onClose={handleClose}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="form-dialog-title">Add sales</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ backgroundColor: '#f5f6fa', }}>
                        <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product name</b></Typography>
                            <Select
                                style={{ backgroundColor: '#fff' }}
                                native
                                value={productvalue}
                                onChange={onChangeProd}
                                inputProps={{
                                    name: 'prod_type',
                                    id: 'filled-age-native-simple',
                                }}>
                                <option aria-label="None" value="" />
                                {InventoryArr_.map((val,index)=>{
                                    return<option value={JSON.stringify(val)} key={index}>{val.inventory_name}</option>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: '100%',marginTop:10}}>
                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product price</b></Typography>
                            <TextField
                                value={newProdState.inventory_price}
                                style={{ backgroundColor: '#fff' }}
                                id="outlined-size-small"
                                variant="outlined"
                                disabled
                                size="small" />
                        </FormControl>
                        <FormControl style={{ width: '100%',marginTop:10}}>
                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Available stocks</b></Typography>
                            <TextField
                                value={newProdState.inventory_quantity}
                                style={{ backgroundColor: '#fff' }}
                                id="outlined-size-small"
                                variant="outlined"
                                disabled
                                size="small" />
                        </FormControl>
                        <FormControl style={{ width: '100%',marginTop:10}}>
                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Insert qty.</b></Typography>
                            <TextField
                                value={insertedQty}
                                onChange={getTotalPrice}
                                style={{ backgroundColor: '#fff' }}
                                id="outlined-size-small"
                                variant="outlined"
                                type="number"
                                size="small" />
                        </FormControl>
                        <Typography style={{ color: '#786fa6', fontSize: 20,marginTop:10 }}> <b>Subtotal : {subTotal === "" ? '...' : subTotal}</b></Typography>
                        {buttonAction ==='Add'
                            ? <Button
                                disabled={subTotal === "" ? true : false}
                                onClick={()=>addProdtoSale()}
                                style={{ height: 40,width:'100%', backgroundColor: subTotal === "" ? '#95a5a6' :'#574b90', color: '#fff',marginTop:10 }}
                                color="primary"
                                size="small"
                                className={classes.button}>&nbsp;&nbsp;&nbsp; Add &nbsp;&nbsp;&nbsp;</Button>
                            : <Button
                                disabled={subTotal === "" ? true : false}
                                onClick={()=>UpdateProdtoSale()}
                                style={{ height: 40,width:'100%', backgroundColor: subTotal === "" ? '#95a5a6' :'#27ae60', color: '#fff',marginTop:10 }}
                                color="primary"
                                size="small"
                                className={classes.button}>&nbsp;&nbsp;&nbsp; Update &nbsp;&nbsp;&nbsp;</Button>
                        }
                       
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {alertDisplay.showAlert === true &&
                            <Alert severity="warning" style={{marginBottom:10}}>{alertDisplay.alertMessage}</Alert>
                        }
                        <Card variant="outlined" >
                            <CardContent>
                                <Typography style={{ color: '#786fa6', fontSize: 20,}}> <b>Inserted products</b></Typography>
                                    <TableContainer style={{ maxHeight: 400,marginTop:10}} size="small">
                                        <Table
                                            size="small"
                                            stickyHeader
                                            style={{ whiteSpace: "nowrap" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Action </TableCell>
                                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left">Name</TableCell>
                                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Qty</TableCell>
                                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Price </TableCell>
                                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Total </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {productAdded.map((val,index)=>{
                                                    return<TableRow hover tabIndex={-1} key={index}>
                                                        <TableCell align="left"> 
                                                            <IconButton aria-label="CallMadeIcon" color="primary" onClick={()=>editItems(val)}>
                                                                <CallMadeIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" onClick={()=>removeItems(val)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align="left">{val.product_name}</TableCell>
                                                        <TableCell align="left">{val.product_qty}</TableCell>
                                                        <TableCell align="left">{val.product_price}</TableCell>
                                                        <TableCell align="left">{parseFloat(val.prod_subtotal).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <Typography style={{ color: '#786fa6', fontSize: 20,marginTop:10 }}> <b>Total : 
                                            {productAdded.reduce((previousValue, currentValue) => previousValue + currentValue.prod_subtotal,0)}</b></Typography>
                                    </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => salesDisplay()}>
                    Cancel
                </Button>
                <Button color="primary" onClick={()=>{
                    setloadingDisplay(true)
                    submitAdd()
                }}>
                    Submit
              </Button>
            </DialogActions>
            {loadingDisplay === true &&
             <div    
                style={{
                    width:'100%',
                    height:'100%',
                    backgroundColor:'rgba(209, 209, 209,.5)',
                    position:'absolute',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    zIndex:2}}>
                <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </div>  
            }
        </Dialog>
    )
}