import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import {
    HashRouter ,
    Route,
    useParams,
    Redirect,
    Link as NewLink,
} from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux'
import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

export default function DialogViewDetails({ViewDialog,handleClose,selectedCustomer,actionButtonDialog}) {
    const subClasses = subStyles();
    const classes = useStyles();
    const Dispatch = useDispatch();
    return (
        <Dialog
        open={ViewDialog}
        fullWidth={true}
        fullScreen
        // onClose={handleCloseUnassign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
            <DialogTitle id="max-width-dialog-title">
                <Typography variant="h5"> <b style={{color:'#4b4b4b'}}>Review Application Form</b></Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={2}></Grid>
                    {selectedCustomer.map((ary,index)=>{
                        let nameDisplay = ""
                        let mobileno = "N/A"
                        let telephone = "N/A"
                        if(ary.cust_type === "COMMERCIAL" || ary.cust_type === "INDUSTRIAL" || ary.cust_type === "GOVERNMENT"){
                            nameDisplay = ary.business_name
                        }else{
                            nameDisplay = ary.cust_fname+' '+ary.cust_lname
                        }

                        if(ary.cust_contact_mobile != null){
                            mobileno = ary.cust_contact_mobile
                        }  
                        if(ary.cust_telephone != null){
                            telephone = ary.cust_telephone
                        }
                    return<Grid item xs={12} md={8} key={index}>
                            <Typography variant="subtitle1">Customer Type: <b style={{color:'#4b6584'}}>{ary.cust_type}</b></Typography>
                            <Card variant="outlined" style={{ marginTop: 10, }}>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={4} >
                                            <div style={{ width: '100%', height: 360, backgroundColor: '#dfe4ea', position: 'relative' }}>

                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={8} >
                                            {ary.cust_type === "COMMERCIAL" || ary.cust_type === "INDUSTRIAL" || ary.cust_type === "GOVERNMENT"
                                                ?<Card variant="outlined">
                                                    <CardContent>
                                                        <Typography variant="h5" gutterBottom style={{ color: '#786fa6' }}> <b>Account details</b></Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} md={8} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Name</b></Typography>
                                                                <TextField
                                                                    value={nameDisplay}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Account No.</b></Typography>
                                                                <TextField
                                                                    value={ary.account_no === "" ? 'N/A' : ary.account_no}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={8} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Customer address</b></Typography>
                                                                    <TextField
                                                                        value={ary.cust_address}
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"/>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Contact email</b></Typography>
                                                                    <TextField
                                                                        value={ary.cust_contact_email}
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Coordinates</b></Typography>
                                                                    <TextField
                                                                        value="0.0,0.0"
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small"/>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Account status</b></Typography>
                                                                    <TextField
                                                                        value={ary.acct_status === null ? "N/A" : ary.acct_status}
                                                                        id="outlined-size-small"
                                                                        variant="outlined"
                                                                        size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Contact no.</b></Typography>
                                                                    <TextField
                                                                    value={mobileno + ' | ' + telephone}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                                :<Card variant="outlined">
                                                    <CardContent>
                                                        <Typography variant="h5" gutterBottom style={{ color: '#786fa6' }}> <b>Account details</b></Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} md={8} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Name</b></Typography>
                                                                <TextField
                                                                name="prod_name"
                                                                value={nameDisplay}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small" />
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Suffix(JR/III/etc.)</b></Typography>
                                                                <TextField
                                                                name="prod_code"
                                                                value={ary.cust_suffixes}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small" />
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Gender</b></Typography>
                                                                <TextField
                                                                name="prod_des"
                                                                value={ary.cust_gender}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small" />
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Birthdate</b></Typography>
                                                                <TextField
                                                                name="prod_qty"
                                                                value={moment(ary.cust_birthdate).format('YYYY-MM-DD')}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"/>
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Age</b></Typography>
                                                                <TextField
                                                                name="prod_qty"
                                                                value={"0"}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"/>
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Marital Status</b></Typography>
                                                                    <TextField
                                                                    name="prod_price"
                                                                    value={ary.marital_status}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={8} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Email</b></Typography>
                                                                    <TextField
                                                                    name="prod_price"
                                                                    value={ary.cust_contact_email}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Mobile no.</b></Typography>
                                                                    <TextField
                                                                    name="prod_price"
                                                                    value={ary.cust_contact_mobile}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <FormControl style={{ width: '100%' }}>
                                                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Phone no.</b></Typography>
                                                                    <TextField
                                                                    name="prod_price"
                                                                    value={ary.cust_telephone}
                                                                    id="outlined-size-small"
                                                                    variant="outlined"
                                                                    size="small" />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" style={{ marginTop: 10, }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom style={{ color: '#786fa6' }}> <b>Address details</b></Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Country</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.country}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Region</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.region}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Province</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.province}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Complete address</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.cust_address}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>City/Town</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.city}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Zip code</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.zipcode}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>District</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.district}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Barangay</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.barangay}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Landmark</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.landmark}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Subdivision</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.subdivision}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>House no.</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.housenum}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <FormControl style={{ width: '100%' }}>
                                                <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Street</b></Typography>
                                                <TextField
                                                name="prod_price"
                                                value={ary.street}
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small" />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                    <Grid item xs={12} md={2}></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button  
                    style={{backgroundColor:'#b2bec3'}}
                    onClick={()=>handleClose(false)}  color="primary">
                    Cancel
                </Button>
                <NewLink to='/updateAccount/' style={{ textDecoration: 'none', color: 'black' }}>
                    <Button  
                        style={{backgroundColor:'#218c74',color:'#fff'}}
                        onClick={()=>{
                            Dispatch({
                                type:'customer_account_',
                                data:{
                                    selectedCust:selectedCustomer,
                                    updateType:"pending_"
                                }
                            })
                        }} color="primary">
                        Edit
                    </Button>
                </NewLink>
                <Button  
                    style={{backgroundColor:'#778beb',color:'#fff'}}
                    onClick={()=>{
                        let actionStruct={
                            type:'Approved',
                            message:'Are you sure you want to Approve this application?',
                        }
                        actionButtonDialog(actionStruct)
                    }} color="primary">
                    Approve
                </Button>
                {selectedCustomer.length > 0 &&
                    <>
                        {selectedCustomer[0].cust_status === "Pending"
                            ?   <Button  
                                    style={{backgroundColor:'#786fa6',color:'#fff'}}
                                    onClick={()=>{
                                        let actionStruct={
                                            type:'Hold',
                                            message:'Please add justification for holding this application form.',
                                        }
                                        actionButtonDialog(actionStruct)
                                    }} color="primary">
                                    Hold
                                </Button>
                            :   <Button  
                                    style={{backgroundColor:'#F59B74',color:'#fff'}}
                                    onClick={()=>{
                                        let actionStruct={
                                            type:'Pending',
                                            message:'Are you sure you want to Repending this application?',
                                        }
                                        actionButtonDialog(actionStruct)
                                    }} color="primary">
                                    Pending
                                </Button>
                        }
                    </>
                }
                <Button  
                     style={{backgroundColor:'#303952',color:'#fff'}}
                        onClick={()=>{
                        let actionStruct={
                            type:'Denied',
                            message:'Are you sure you want to Deny this application?',
                        }
                        actionButtonDialog(actionStruct)
                    }} color="primary">
                    Deny
                </Button>
            </DialogActions>
        </Dialog>
    );
}