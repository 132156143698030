import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import moment from 'moment';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import useStyles from '../stylesheet';
import { getData } from '../api/api'
import LoadingGif from '../assets/loading.gif'
import AlertComponent from '../material/snackbar'
import { useDispatch,useSelector } from 'react-redux'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        maxHeight: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 5,
        color: '#fff',
    },
  }));
  
export default function ContactInformataion({userContact}) {
  const classes = useStyles();
  const subClasses = subStyles();
  const [buttonType, setbuttonType] = React.useState("");
  const [openSnack, setopenSnack] = React.useState(false);
  const [addContact, setaddContact] = React.useState(false);
  const [openLoader, setopenLoader] = React.useState(false);
  const [deleteDisplay, setdeleteDisplay] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedIndex, setselectedIndex] = React.useState(0);
  const [Contacts, setContacts] = React.useState([]);
  const [alertStyle, setalertStyle] = React.useState({
    alertMessage:'',
    alertType:'Success'
  }); 
  const [newContact, setnewContact] = React.useState({
    newFname:"",
    newLname:"",
    newRelation:"",
    newemail:"",
    newMobile:"",
  });
  const CustomerData = useSelector(state => state.differentCustomer.masterData)


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onChange = (event) => {
    const name = event.target.name;
    setnewContact({
      ...newContact,
    [name]:event.target.value
    })
  }

  const callSubmite=(e)=>{
    e.preventDefault()
    setaddContact(false)
    submitNewContact()
  }

  const submitNewContact=()=>{
    setopenLoader(true)
    let mutable_handler = JSON.stringify(Contacts)
    let contactHolder = JSON.parse(mutable_handler)
    let newStruct={
      c_fname:newContact.newFname,
      c_lname:newContact.newLname,
      relationship:newContact.newRelation,
      c_email:newContact.newemail,
      c_mobile:newContact.newMobile,
    }
    if(buttonType === "Add"){
      contactHolder.push(newStruct)
    }else if(buttonType === "Edit"){
      contactHolder[selectedIndex] = newStruct
    }else if(buttonType === "delete"){
      contactHolder.splice(selectedIndex, 1);
    }
    let contact_param = {
      cust_id:userContact[0].cust_id,
      contacts:JSON.stringify(contactHolder)
    }
    getData('jls/UpdateCustomerDetails',contact_param)
      .then((res)=>{
        if(res.status === 'Success'){
          setopenLoader(false)
          const findTitleIndx = CustomerData.findIndex(x => x.cust_id === userContact[0].cust_id)
          CustomerData[findTitleIndx].contacts = contact_param.contacts
          setContacts(JSON.parse(contact_param.contacts))
          setnewContact({
            newFname:"",
            newLname:"",
            newRelation:"",
            newemail:"",
            newMobile:"",
          })
          let messagePop = ""
          if(buttonType === "Add"){
            messagePop = "New contact succesfully added"
          }else if(buttonType === "Edit"){
            messagePop = "Contact successfully updated"
          }else if(buttonType === "delete"){
            messagePop = "Contact successfully removed"
          }
          setbuttonType("")
          setalertStyle({alertMessage:messagePop,alertType:'success'})
          setaddContact(false)
          setopenSnack(true)
        }else{
          setaddContact(true)
          setopenLoader(false)
          setalertStyle({alertMessage:'Failed to add new contact person. Please try again',alertType:'error'})
          setopenSnack(true)
        }
    })
  }

  const editContact=(val,index)=>{
    setselectedIndex(index)
    setnewContact({
      newFname:val.c_fname,
      newLname:val.c_lname,
      newRelation:val.relationship,
      newemail:val.c_email,
      newMobile:val.c_mobile,
    })
    setbuttonType("Edit")
    setaddContact(true)
  }

  const handleClose=()=>{
    setdeleteDisplay(false)
  }

  const goDelete=()=>{
  }


  useEffect(()=>{
    if(userContact[0].contacts !== null){
      setContacts(JSON.parse(userContact[0].contacts))
    }
  },[])
  return (
      <div> 
          <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
          <Grid container >
            <Grid item xs={12} md={12} >
                <Grid container >
                    <Grid item xs={12} md={4}>
                        <Button
                            onClick={()=>{
                              setbuttonType("Add")
                              setaddContact(true)}}
                            style={{marginBottom:10,backgroundColor:'#574b90'}}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<PhoneIcon fontSize="small"/>}>
                            Add New Contact
                        </Button>
                    </Grid>
                </Grid>
                
            <Paper>
                <TableContainer  style={{ maxHeight: 200, width: "100%" }} size="small">
                    <Table 
                    size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: "#786fa6",  color: "#f5f6fa" }} align="left"> Firstname </TableCell>
                                <TableCell style={{ backgroundColor: "#786fa6", color: "#f5f6fa" }} align="left"> Lastname </TableCell>
                                <TableCell style={{ backgroundColor: "#786fa6", color: "#f5f6fa" }} align="left"> Relation </TableCell>
                                <TableCell style={{ backgroundColor: "#786fa6", color: "#f5f6fa" }} align="left"> Email </TableCell>
                                <TableCell style={{ backgroundColor: "#786fa6", color: "#f5f6fa" }} align="left"> Mobile No. </TableCell>
                                <TableCell style={{ backgroundColor: "#786fa6",  color: "#f5f6fa" }} align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {Contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ary,index) => {
                        return<TableRow hover tabIndex={-1} key={index}>
                                <TableCell>{ary.c_fname}</TableCell>
                                <TableCell>{ary.c_lname}</TableCell>
                                <TableCell>{ary.relationship}</TableCell>
                                <TableCell>{ary.c_email}</TableCell>
                                <TableCell>{ary.c_mobile}</TableCell>
                                <TableCell>
                                  <IconButton 
                                    onClick={()=>editContact(ary,index)}
                                    aria-label="delete" className={classes.margin} size="small">
                                    <EditIcon fontSize="small" style={{color:'#2e86de'}}/>
                                  </IconButton>
                                  <IconButton 
                                    onClick={()=>{
                                      setdeleteDisplay(true)
                                      setbuttonType('delete')
                                      setselectedIndex(index)
                                      }}
                                    aria-label="delete" className={classes.margin} size="small">
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                            </TableRow>
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={Contacts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}/>
                </Paper>

                {/* edit contact */}
                <Dialog 
                  fullWidth={true}
                  maxWidth="sm"
                  open={addContact}
                  // onClose={handleClose}
                  aria-labelledby="max-width-dialog-title">
                  <DialogTitle id="form-dialog-title">{buttonType} Contact</DialogTitle>
                  <DialogContent>
                    <Card variant="outlined">
                      <form onSubmit={callSubmite}>
                        <CardContent>
                          <Grid container spacing={1} style={{marginTop:10}}>
                            <Grid item xs={12} md={6}>
                              <FormControl style={{ width: '100%', }}>
                                  <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}>Firstname</Typography>
                                  <TextField
                                      name="newFname"
                                      required={true} 
                                      value={newContact.newFname}
                                      onChange={(e)=>onChange(e)}
                                      id="outlined-size-small"
                                      variant="outlined"
                                      size="small"/>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl style={{ width: '100%', }}>
                                  <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}>Lastname</Typography>
                                  <TextField
                                      name="newLname"
                                      required={true} 
                                      value={newContact.newLname}
                                      onChange={(e)=>onChange(e)}
                                      id="outlined-size-small"
                                      variant="outlined"
                                      size="small"/>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl style={{ width: '100%', }}>
                                  <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}>Relationship</Typography>
                                  <TextField
                                    name="newRelation"
                                    required={true} 
                                    value={newContact.newRelation}
                                    onChange={(e)=>onChange(e)}
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"/>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl style={{ width: '100%', }}>
                                  <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}>Email</Typography>
                                  <TextField
                                    name="newemail"
                                    required={true} 
                                    value={newContact.newemail}
                                    onChange={(e)=>onChange(e)}
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"/>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl style={{ width: '100%', }}>
                                  <Typography style={{ color: '#786fa6', fontSize: 15,fontWeight:'500'}}>Mobile No.</Typography>
                                  <TextField
                                    name="newMobile"
                                    required={true} 
                                    value={newContact.newMobile}
                                    onChange={(e)=>onChange(e)}
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"/>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                            <Button 
                              onClick={() => {
                                setaddContact(false)
                                setnewContact({
                                  newFname:"",
                                  newLname:"",
                                  newRelation:"",
                                  newemail:"",
                                  newMobile:"",
                                })}}
                              style={{ color: '#fff', backgroundColor: '#a4b0be',marginRight:10 }} color="primary"> Cancel</Button>
                            <Button type="submit" style={{ color: '#fff', backgroundColor: '#1e90ff' }} color="primary" > Submit</Button>
                          </div>
                        </CardActions>
                      </form>
                    </Card>
                  </DialogContent>
                </Dialog>
            
                <Dialog 
                  fullWidth={true}
                  maxWidth="sm"
                  open={deleteDisplay}
                  onClose={handleClose}
                  aria-labelledby="max-width-dialog-title">
                  {/* <DialogTitle id="form-dialog-title">Are you sure you want to delete this contact?</DialogTitle> */}
                  <DialogContent>
                    <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h6" style={{marginBottom:10}}> <b style={{color:'#786fa6'}}>Are you sure you want to delete this contact?</b></Typography>
                          <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                            <Button 
                              onClick={()=>handleClose()}
                              style={{backgroundColor:'#8395a7',color:'#fff',marginRight:5}} color="primary">Cancel</Button>
                            <Button 
                              onClick={()=>{
                                handleClose()
                                submitNewContact()}}
                              style={{backgroundColor:'#2e86de',color:'#fff'}} color="primary">Delete</Button>
                          </div>
                        </CardContent>
                    </Card>
                  </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
        <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:9999}}>
          <img src={LoadingGif} style={{ width: 100, height: 100}} />
        </Backdrop>
      </div>
  );
}