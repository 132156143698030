
 export const InputForms = [
    {
     fd_name:'firstname',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'First Name',
     data_field:'cust_fname',
     fd_required:true,
     sequencing:0,
     fd_values:'[]'
 }, {
     fd_name:'lastname',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'Last Name',
     data_field:'cust_lname',
     fd_required:true,
     sequencing:1,
     fd_values:'[]'
 }, {
     fd_name:'middlename',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'Middle Name',
     data_field:'cust_mname',
     fd_required:false,
     sequencing:2,
     fd_values:'[]'
 },{
     fd_name:'suffixes',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'Suffix(JR/III/etc.)',
     data_field:'cust_suffixes',
     fd_required:false,
     sequencing:3,
     fd_values:'[]'
 },{
     fd_name:'emailadd',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'Email',
     data_field:'cust_contact_email',
     fd_required:true,
     sequencing:4,
     fd_values:'[]'
 },{
     fd_name:'mobilenum',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'Mobile No.',
     data_field:'cust_contact_mobile',
     fd_required:true,
     sequencing:5,
     fd_values:'[]'
 },{
     fd_name:'telephone',
     fd_type:'default',
     fd_category:'input',
     fd_class:'profile',
     fd_label:'Telephone No.',
     data_field:'cust_telephone',
     fd_required:false,
     sequencing:6,
     fd_values:'[]'
 },
 {
     fd_name:'customertype',
     fd_type:'default',
     fd_category:'dropdown',
     fd_class:'profile',
     fd_label:'Customer Type',
     data_field:'cust_type',
     fd_required:true,
     sequencing:7,
     fd_values:'[]'
 },{
     fd_name:'birthdate',
     fd_type:'default',
     fd_category:'date',
     fd_class:'profile',
     fd_label:'Birthdate',
     data_field:'cust_birthdate',
     fd_required:true,
     sequencing:8,
     fd_values:'[]'
 },{
     fd_name:'gender',
     fd_type:'default',
     fd_category:'dropdown',
     fd_class:'profile',
     fd_label:'Select Gender',
     data_field:'cust_gender',
     fd_required:true,
     sequencing:9,
     fd_values:'[]'
 },{
     fd_name:'maritalstatus',
     fd_type:'default',
     fd_category:'dropdown',
     fd_class:'profile',
     fd_label:'Marital Status',
     data_field:'marital_status',
     fd_required:true,
     sequencing:10,
     fd_values:'[]'
 }
]  

export const AddressForms = [
    {
        fd_name:'region',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Region',
        data_field:'region',
        fd_required:true,
        sequencing:0,
        fd_values:'[]'
    },  {
        fd_name:'province',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Province',
        data_field:'province',
        fd_required:true,
        sequencing:1,
        fd_values:'[]'
    },  {
        fd_name:'municipalities',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'City/Town',
        data_field:'city',
        fd_required:true,
        sequencing:2,
        fd_values:'[]'
    },
    {
        fd_name:'zipcode',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Zip Code',
        data_field:'zipcode',
        fd_required:true,
        sequencing:3,
        fd_values:'[]'
    }, {
        fd_name:'district',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'District',
        data_field:'district',
        fd_required:true,
        sequencing:4,
        fd_values:'[]'
    }, {
        fd_name:'barangay',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Barangay',
        data_field:'barangay',
        fd_required:true,
        sequencing:5,
        fd_values:'[]'
    }, {
        fd_name:'completeaddress',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Complete Address',
        data_field:'cust_address',
        fd_required:true,
        sequencing:6,
        fd_values:'[]'
    }, {
        fd_name:'landmark',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Landmark',
        data_field:'landmark',
        fd_required:false,
        sequencing:7,
        fd_values:'[]'
    }, {
        fd_name:'subdivision',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Subdivision',
        data_field:'subdivision',
        fd_required:false,
        sequencing:8,
        fd_values:'[]'
    }, {
        fd_name:'street',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Street',
        data_field:'street',
        fd_required:false,
        sequencing:9,
        fd_values:'[]'
    }, {
        fd_name:'housenum',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'House No',
        data_field:'housenum',
        fd_required:false,
        sequencing:10,
        fd_values:'[]'
    },
]



//not going to use
export const InputProfile = [
       {
        fd_name:'firstname',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'First Name',
        data_field:'cust_fname',
        fd_required:true,
        sequencing:0,
        fd_values:'[]'
    }, {
        fd_name:'lastname',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Last Name',
        data_field:'cust_lname',
        fd_required:true,
        sequencing:1,
        fd_values:'[]'
    }, {
        fd_name:'middlename',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Middle Name',
        data_field:'cust_mname',
        fd_required:false,
        sequencing:2,
        fd_values:'[]'
    },{
        fd_name:'suffixes',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Suffix(JR/III/etc.)',
        data_field:'cust_suffixes',
        fd_required:false,
        sequencing:3,
        fd_values:'[]'
    },{
        fd_name:'emailadd',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Email',
        data_field:'cust_contact_email',
        fd_required:true,
        sequencing:4,
        fd_values:'[]'
    },{
        fd_name:'mobilenum',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Mobile No.',
        data_field:'cust_contact_mobile',
        fd_required:true,
        sequencing:5,
        fd_values:'[]'
    },{
        fd_name:'telephone',
        fd_type:'default',
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Telephone No.',
        data_field:'cust_telephone',
        fd_required:false,
        sequencing:6,
        fd_values:'[]'
    },
    {
        fd_name:'customertype',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Customer Type',
        data_field:'cust_type',
        fd_required:false,
        sequencing:7,
        fd_values:'[]'
    },{
        fd_name:'birthdate',
        fd_type:'default',
        fd_category:'date',
        fd_class:'profile',
        fd_label:'Birthdate',
        data_field:'cust_birthdate',
        fd_required:true,
        sequencing:8,
        fd_values:'[]'
    },{
        fd_name:'gender',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Select Gender',
        data_field:'cust_gender',
        fd_required:true,
        sequencing:9,
        fd_values:'[]'
    },{
        fd_name:'maritalstatus',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Marital Status',
        data_field:'marital_status',
        fd_required:true,
        sequencing:10,
        fd_values:'[]'
    }
]

export const DropDownProfile = [
    {
        fd_name:'customertype',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Customer Type',
        data_field:'cust_type',
        fd_required:false,
        sequencing:7,
        fd_values:'[]'
    },{
        fd_name:'birthdate',
        fd_type:'default',
        fd_category:'date',
        fd_class:'profile',
        fd_label:'Birthdate',
        data_field:'cust_birthdate',
        fd_required:true,
        sequencing:8,
        fd_values:'[]'
    },{
        fd_name:'gender',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Select Gender',
        data_field:'cust_gender',
        fd_required:true,
        sequencing:9,
        fd_values:'[]'
    },{
        fd_name:'maritalstatus',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Marital Status',
        data_field:'marital_status',
        fd_required:true,
        sequencing:10,
        fd_values:'[]'
    }
]



export const InputAddress = [
    {
        fd_name:'region',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Region',
        data_field:'region',
        fd_required:true,
        sequencing:0,
        fd_values:'[]'
    },  {
        fd_name:'province',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Province',
        data_field:'province',
        fd_required:true,
        sequencing:1,
        fd_values:'[]'
    },  {
        fd_name:'municipalities',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'City/Town',
        data_field:'city',
        fd_required:true,
        sequencing:2,
        fd_values:'[]'
    },
    {
        fd_name:'zipcode',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Zip Code',
        data_field:'zipcode',
        fd_required:true,
        sequencing:3,
        fd_values:'[]'
    }, {
        fd_name:'district',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'District',
        data_field:'district',
        fd_required:true,
        sequencing:4,
        fd_values:'[]'
    }, {
        fd_name:'barangay',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Barangay',
        data_field:'barangay',
        fd_required:true,
        sequencing:5,
        fd_values:'[]'
    }, {
        fd_name:'completeaddress',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Complete Address',
        data_field:'cust_address',
        fd_required:true,
        sequencing:6,
        fd_values:'[]'
    }, {
        fd_name:'landmark',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Landmark',
        data_field:'landmark',
        fd_required:false,
        sequencing:7,
        fd_values:'[]'
    }, {
        fd_name:'subdivision',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Subdivision',
        data_field:'subdivision',
        fd_required:false,
        sequencing:8,
        fd_values:'[]'
    }, {
        fd_name:'street',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Street',
        data_field:'street',
        fd_required:false,
        sequencing:9,
        fd_values:'[]'
    }, {
        fd_name:'housenum',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'House No',
        data_field:'housenum',
        fd_required:false,
        sequencing:10,
        fd_values:'[]'
    },
]


export const DropDownAddress = [
    {
        fd_name:'region',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Region',
        data_field:'region',
        fd_required:true,
        sequencing:0,
        fd_values:'[]'
    },  {
        fd_name:'province',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Province',
        data_field:'province',
        fd_required:true,
        sequencing:1,
        fd_values:'[]'
    },  {
        fd_name:'municipalities',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'City/Town',
        data_field:'city',
        fd_required:true,
        sequencing:2,
        fd_values:'[]'
    },
]

// -------

export const Profile_section = [
    {
        fd_name:'cust_fname',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'First Name',
        data_field:'cust_fname',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:0,
        fd_values:'[]'
    }, {
        fd_name:'cust_lname',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Last Name',
        data_field:'cust_lname',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:1,
        fd_values:'[]'
    }, {
        fd_name:'cust_mname',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Middle Name',
        data_field:'cust_mname',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:2,
        fd_values:'[]'
    },{
        fd_name:'cust_suffixes',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Suffix(JR/III/etc.)',
        data_field:'cust_suffixes',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:3,
        fd_values:'[]'
    },{
        fd_name:'cust_contact_email',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Email',
        data_field:'cust_contact_email',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:4,
        fd_values:'[]'
    },{
        fd_name:'business_name',
        fd_type:['GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Goverment name',
        data_field:'business_name',
        fd_required:true,
        row_no:12,
        push:1,
        sequencing:5,
        fd_values:'[]'
    },{
        fd_name:'business_name',
        fd_type:['COMMERCIAL','INDUSTRIAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Business name',
        data_field:'business_name',
        fd_required:true,
        row_no:12,
        push:1,
        sequencing:6,
        fd_values:'[]'
    },{
        fd_name:'c_fname',
        fd_type:['COMMERCIAL','INDUSTRIAL','GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Contact first name',
        data_field:'c_fname',
        fd_required:true,
        row_no:4,
        push:0,
        sequencing:7,
        fd_values:'[]'
    },{
        fd_name:'c_lname',
        fd_type:['COMMERCIAL','INDUSTRIAL','GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Contact last name',
        data_field:'c_lname',
        fd_required:true,
        row_no:4,
        push:0,
        sequencing:8,
        fd_values:'[]'
    },{
        fd_name:'c_email',
        fd_type:['COMMERCIAL','INDUSTRIAL','GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Contact email',
        data_field:'c_email',
        fd_required:true,
        row_no:4,
        push:0,
        sequencing:9,
        fd_values:'[]'
    },{
        fd_name:'relationship',
        fd_type:['COMMERCIAL','INDUSTRIAL','GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Relationship',
        data_field:'c_relation',
        fd_required:true,
        row_no:4,
        push:0,
        sequencing:10,
        fd_values:'[]'
    },{
        fd_name:'cust_contact_mobile',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Mobile No.',
        data_field:'cust_contact_mobile',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:11,
        fd_values:'[]'
    },{
        fd_name:'c_mobile',
        fd_type:['COMMERCIAL','INDUSTRIAL','GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Mobile No.',
        data_field:'c_mobile',
        fd_required:true,
        row_no:4,
        push:0,
        sequencing:12,
        fd_values:'[]'
    },{
        fd_name:'cust_telephone',
        fd_type:['RESIDENTIAL','GENERAL','COMMERCIAL','INDUSTRIAL','GOVERNMENT'],
        fd_category:'input',
        fd_class:'profile',
        fd_label:'Telephone No.',
        data_field:'cust_telephone',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:13,
        fd_values:'[]'
    },{
        fd_name:'cust_birthdate',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'date',
        fd_class:'profile',
        fd_label:'Birthdate',
        data_field:'cust_birthdate',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:14,
        fd_values:'[]'
    },{
        fd_name:'cust_gender',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Select Gender',
        data_field:'cust_gender',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:15,
        fd_values:['Male','Female']
    },{
        fd_name:'marital_status',
        fd_type:['RESIDENTIAL','GENERAL'],
        fd_category:'dropdown',
        fd_class:'profile',
        fd_label:'Marital Status',
        data_field:'marital_status',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:16,
        fd_values:['SINGLE','MARRIED','WIDOR(ER)','SEPARATED','ANNULED']
    }
]

export const Address_section = [
    {
        fd_name:'region',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Region',
        data_field:'region',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:0,
        fd_values:'[]'
    },  {
        fd_name:'province',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'Province',
        data_field:'province',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:1,
        fd_values:'[]'
    },  {
        fd_name:'city',
        fd_type:'default',
        fd_category:'dropdown',
        fd_class:'address',
        fd_label:'City/Town',
        data_field:'city',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:2,
        fd_values:'[]'
    },{
        fd_name:'zipcode',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Zip Code',
        data_field:'zipcode',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:3,
        fd_values:'[]'
    }, {
        fd_name:'district',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'District',
        data_field:'district',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:4,
        fd_values:'[]'
    }, {
        fd_name:'barangay',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Barangay',
        data_field:'barangay',
        fd_required:true,
        row_no:4,
        push:1,
        sequencing:5,
        fd_values:'[]'
    }, {
        fd_name:'cust_address',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Complete Address',
        data_field:'cust_address',
        fd_required:true,
        row_no:8,
        push:1,
        sequencing:6,
        fd_values:'[]'
    }, {
        fd_name:'landmark',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Landmark',
        data_field:'landmark',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:7,
        fd_values:'[]'
    }, {
        fd_name:'subdivision',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Subdivision',
        data_field:'subdivision',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:8,
        fd_values:'[]'
    }, {
        fd_name:'street',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'Street',
        data_field:'street',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:9,
        fd_values:'[]'
    }, {
        fd_name:'housenum',
        fd_type:'default',
        fd_category:'input',
        fd_class:'address',
        fd_label:'House No',
        data_field:'housenum',
        fd_required:false,
        row_no:4,
        push:1,
        sequencing:10,
        fd_values:'[]'
    },
]