import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageIcon from '@material-ui/icons/Image';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux'
import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      minWidth: 170,
      fontSize: 14,
    },
  }))(TableCell);
export default function DialogAddProduct({addItemdisplay,handleCloseAddProd,newProdState,onChangeProd,submitAdded}) {
    const subClasses = subStyles();
    const classes = useStyles();
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)
    return (
        <Dialog
        open={addItemdisplay}
        fullWidth={true}
        maxWidth="md"
        // onClose={handleCloseUnassign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
            <DialogTitle id="max-width-dialog-title">Add product to : {initialBranch.branch_name}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4} >
                        <div style={{ width: '100%', height: 360, backgroundColor: '#dfe4ea',position:'relative' }}>
                            <Button
                                style={{ marginTop: 5, height: 40, color: '#3d3d3d',bottom:10,position:'absolute',left:10 }}
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.button}
                                startIcon={<ImageIcon />}> Attach image </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" gutterBottom style={{ color: '#786fa6' }}> <b>Product details</b></Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6} >
                                        <FormControl style={{ width: '100%' }}>
                                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product name</b></Typography>
                                            <TextField
                                            name="prod_name"
                                            value={newProdState.prod_name}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={onChangeProd} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl style={{ width: '100%' }}>
                                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product code</b></Typography>
                                            <TextField
                                            name="prod_code"
                                            value={newProdState.prod_code}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={onChangeProd} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12} >
                                        <FormControl style={{ width: '100%' }}>
                                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product description</b></Typography>
                                            <TextField
                                            name="prod_des"
                                            value={newProdState.prod_des}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={onChangeProd} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl style={{ width: '100%' }}>
                                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product qty.</b></Typography>
                                            <TextField
                                            name="prod_qty"
                                            value={newProdState.prod_qty}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            onChange={onChangeProd} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
                                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product type</b></Typography>
                                            <Select
                                            native
                                            value={newProdState.prod_type}
                                            onChange={onChangeProd}
                                            inputProps={{
                                                name: 'prod_type',
                                                id: 'filled-age-native-simple',
                                            }}>
                                            <option aria-label="None" value="" />
                                            <option value="Round">Round</option>
                                            <option value="Slim">Slim</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl style={{ width: '100%' }}>
                                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Product price</b></Typography>
                                            <TextField
                                            name="prod_price"
                                            value={newProdState.prod_price}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={onChangeProd} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleCloseAddProd} 
                    style={{ backgroundColor: '#b2bec3', height: 40, color: '#f5f6fa' }}
                    color="primary"> Cancel</Button>
                <Button
                    onClick={() => { submitAdded() }}
                    style={{ backgroundColor: '#778beb', height: 40, color: '#f5f6fa' }}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}