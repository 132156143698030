const initialState = {
    profileExtra: [],
    addressExtra:[]
}
const extraFieldsAdded = (state = initialState, action) => {
    switch (action.type) {
        case 'profileAdded':
            return {
                ...state,
                profileExtra: state.profileExtra.concat(action.passProfileExtra)
            }
        case 'addressAdded':
            return {
                ...state,
                addressExtra: state.addressExtra.concat(action.passAddressExtra) 
            }
        case 'newProfileExtra':
            return {
                ...state,
                profileExtra: action.passNewExtraProfile
            }
        case 'newAddressExtra':
            return {
                ...state,
                addressExtra: action.passNewExtraAddress
            }
        case 'updateProfileRequiry':
            return {
                ...state,
                profileExtra : state.profileExtra.map(value => {
                    if(value.sequencing === action.passIndex){
                        return action.passNewValue;
                    }
                    return value;
                })
            }
        case 'updateAddressRequiry':
            return {
                ...state,
                addressExtra : state.addressExtra.map(value => {
                    if(value.sequencing === action.passIndex){
                        return action.passNewValue;
                    }
                    return value;
                })
            }
        default:
            return state;
    }
}
export default extraFieldsAdded; 