import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import useStyles from '../../stylesheet';
import { Filter } from '@material-ui/icons';
export default function Widgets({total,remaining,assignedMR,handleWidgetAssigned,openLoader}) {
    const classes = useStyles();
    const [anchorValue, setAnchorValue] = React.useState(null);
    const [fieldmanwAssign, setfieldmanwAssign] = React.useState(0);

    const handleClick= (event) => {
        setAnchorValue(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorValue(null);
    };

    useEffect(()=>{
        if(openLoader === false){
            let filterGroups = assignedMR.filter(item => item.assigned.length > 0)
            setfieldmanwAssign(filterGroups.length)
        }
    },[openLoader])
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
            <div style={{height: 100, width: '100%', backgroundColor: '#4FA1ED' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{total}</b></Typography>
                    <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> TOTAL </Typography>
                    </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={2}>
            <div style={{height: 100, width: '100%', backgroundColor: '#F59B74' }} onClick={() => { }}>
                <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{remaining}</b></Typography>
                    <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> REMAINING </Typography>
                    </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={2}>
            <div style={{height: 100, width: '100%', backgroundColor: '#4B6584' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{total - remaining}</b></Typography>
                    <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> ASSIGNED </Typography>
                    </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={2}>
            <div 
                onClick={handleClick}
                style={{height: 100, width: '100%', backgroundColor: '#786fa6' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',cursor:"pointer" }}>
                    <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{fieldmanwAssign}</b></Typography>
                    <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> ASSIGNED FIELDMAN</Typography>
                    </div>
                </div>
            </div>
            <Menu
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                id="simple-menu"
                elevation={1}
                getContentAnchorEl={null}
                anchorEl={anchorValue}
                keepMounted
                open={Boolean(anchorValue)}
                onClose={handleClose}>
                <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20,overflow: 'auto'}}>
                    <Card variant="outlined" style={{ marginBottom: 10 }}>
                        <CardContent >
                            <List component="nav" className={classes.root} aria-label="contacts">
                                {assignedMR.map((val, index) => {
                                    if (val.assigned.length > 0) {
                                        return <>
                                            <ListItem button key={index}
                                                onClick={() => {handleWidgetAssigned(val)}}
                                                style={{ backgroundColor: '#fff' }}>
                                                <ListItemText primary={val.fieldmanData.completename} style={{ color: '#3d3d3d' }} />
                                                <ListItemSecondaryAction>
                                                    <p><b>{val.assigned.length}</b></p>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider component="li" />
                                        </>
                                    }
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </div>
            </Menu>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
    </Grid>
  );
}