import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from '../../stylesheet';
import AlertComponent from '../../material/snackbar'
import { getData } from '../../api/api'
import { useDispatch,useSelector } from 'react-redux'
import moment from 'moment'

import { InputProfile,DropDownProfile,InputAddress,DropDownAddress } from '../../assets/formComponents'
import PendingHeader from './pendingHead'
import WidgetPending from './widget'
import PendingTable from './pendingRec'
import DialogViewDetails from './dialogviewDetails'
import DialogConfirmation from './dialogConfirmation'
import LoadingGif from '../../assets/loading.gif'
const subStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    Tabroot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 700,
      },
    subroot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tableContainer: {
        height: 440,
      },
    margin: {
        margin: theme.spacing(1),
      },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

export default function PendingApplication() {
    const classes = useStyles();
    const subClasses = subStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableLength, settableLength] = React.useState(0);
    const [openLoader, setopenLoader] = React.useState(false);
    const [ViewDialog, setViewDialog] = React.useState(false);
    const [openSnack, setopenSnack] = React.useState(false);
    const [openHold, setopenHold] = React.useState(false);
    const [submitIdentifier, setsubmitIdentifier] = React.useState(false);

    const [selectedStatus, setselectedStatus] = React.useState("All");
    const [searchedName, setsearchedName] = React.useState("");
    const [cust_type, setcust_type] = React.useState("");

    const [selectedCustomer, setselectedCustomer] = React.useState([]);
    
    const [widgetCounter, setwidgetCounter] = React.useState({
      all:0,
      pending:0,
      hold:0
    });
    const [alertStyle, setalertStyle] = React.useState({
      alertMessage:'',
      alertType:'Success'
    });
    const [buttonClick, setbuttonClick] = React.useState({
      type:'',
      message:'',
    });
    const [justificationHold, setjustificationHold] = React.useState({
      justify:'',
    });
    const [branchData, setbranchData] = React.useState({
      branch_id:"",
      company_id:"",
      fieldworks:null
    });
    const Dispatch = useDispatch();
    const PendingCustomers = useSelector(state => state.differentCustomer.pendingCustomer)
    const initialBranch = useSelector(state => state.BranchHandled.initialSelectedBranch)

    const getJotype = () => {
        setbranchData({
            branch_id:initialBranch.b_id,
            company_id:initialBranch.c_id,
            fieldworks:initialBranch.branch_field_work
        })
        fetchPendings(initialBranch.c_id,initialBranch.b_id,page,selectedStatus,searchedName,cust_type)
    }
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      fetchPendings(branchData.company_id,branchData.branch_id,newPage,selectedStatus,searchedName,cust_type)
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const fetchPendings=(company_id,branch_id,newPage,status,search,type)=>{
      setopenLoader(true)
      let typeSelected = type
      let statusSelected = status
      if(type === "All"){
          typeSelected = ""
      }
      if(status === "All"){
        statusSelected = ""
      }
      let data = {
        company_id:company_id,
        branch_id:branch_id,
        page:newPage+1,
        limit:rowsPerPage,
        type:typeSelected,
        search:search,
        status:statusSelected
      } 
      getData('Customer/fetchPendingCustomer',data)
      .then((res)=>{
        setopenLoader(false)
        if(res.result === 'Success'){
          if(res.data.length > 0){
            var snackMessage = (res.pending + res.hold)+' customer(s) found!'
            Dispatch({
                type:'pendingApplication',
                passAplicationpending:res.data,
            })
            setwidgetCounter({
              all:res.pending + res.hold,
              pending:res.count - res.hold,
              hold:res.hold
            })
            settableLength(res.count)
            setalertStyle({alertMessage:snackMessage,alertType:'Success'})
            setopenSnack(true)
          }else{
            Dispatch({
              type:'pendingApplication',
              passAplicationpending:[],
            })
            setwidgetCounter({
              all:0,
              pending:0,
              hold:0
            })
            setalertStyle({alertMessage:'Pending customer not found.',alertType:'Warning'})
            setopenSnack(true)
          }
        }else{
          Dispatch({
            type:'pendingApplication',
            passAplicationpending:[],
          })
          setwidgetCounter({
            all:0,
            pending:0,
            hold:0
          })
          setalertStyle({alertMessage:'Pending customer not found.',alertType:'Warning'})
          setopenSnack(true)
        }
      })
    }
    const displayDialog=(ary)=>{
        var pushCust = []
        pushCust.push(ary)
        setselectedCustomer(pushCust)
        setViewDialog(true)
    }
    const handleClose=()=>{
        setViewDialog(false)
    }
    
    const handleCloseHold = () => {
        setopenHold(false);
    };
    const onChangeJustification = (event) => {
        var comment = event.target.value
        setjustificationHold({
          ...justificationHold,
          justify: comment
        });
    };
    const actionButtonDialog=(value)=>{
        setbuttonClick({
        ...buttonClick,
            type:value.type,
            message:value.message,
        });
        setopenHold(true);
    }
    const submitUpdate=()=>{
      var logsOld = []
      var logsNew = []
      if(buttonClick.type === 'Hold'){
        logsOld = {
          user_id:selectedCustomer[0].user_id,
          type:'Update',
          data:[{
            justification:selectedCustomer[0].justification,
            cust_status:selectedCustomer[0].cust_status
          }] 
        }
         logsNew = {
          user_id:selectedCustomer[0].user_id,
          type:'Update',
          data:[{
            justification:justificationHold.justify,
            cust_status:buttonClick.type
          }] 
        }
      }else{
        logsOld = {
          user_id:selectedCustomer[0].user_id,
          type:'Update',
          data:[{
            cust_status:selectedCustomer[0].cust_status
          }] 
        }
         logsNew = {
          user_id:selectedCustomer[0].user_id,
          type:'Update',
          data:[{
            cust_status:buttonClick.type
          }] 
        }
      }
    
      var holdForm = {
        status:buttonClick.type,
        cust_id:selectedCustomer[0].cust_id,
        user_id:selectedCustomer[0].user_id,
        justification:justificationHold.justify,
        old:JSON.stringify(logsOld),
        new:JSON.stringify(logsNew)
      }
      if(buttonClick.type === 'Hold'){
        if(justificationHold.justify === ""){
          setalertStyle({alertMessage:'Textfield cannot be emply, Please widen your justification.',alertType:'Error'})
          setopenSnack(true)
        }else{
          APIupdate(holdForm)
        }
      }else{
        APIupdate(holdForm)
      }
    }
    const APIupdate=(holdForm)=>{
      setopenLoader(true)
      getData('Customer/updateStatus',holdForm)
      .then((res)=>{
        if(JSON.stringify(res).includes('Success')){
          let FindExistingIndx = PendingCustomers.findIndex(x => x.cust_id === selectedCustomer[0].cust_id)
          if(FindExistingIndx > -1){
            if(buttonClick.type === 'Approved' || buttonClick.type === 'Denied'){
              PendingCustomers.splice(FindExistingIndx, 1);
            }else{
              PendingCustomers[FindExistingIndx].cust_status = buttonClick.type
            }
          }
          setjustificationHold({
            ...justificationHold,
            justify:'',
          });
          setopenHold(false);
          setViewDialog(false);
          setalertStyle({alertMessage:'Customer status succesfully changed to '+buttonClick.type,alertType:'Success'})
          setopenSnack(true)
          setopenLoader(false);
        }else{
          setalertStyle({alertMessage:'Failed to update this application. Please try again',alertType:'Error'})
          setopenSnack(true)
          setopenLoader(false)
        }
      
      })
    }
    const fetchCustomer=()=>{
      setopenLoader(true)
      if(searchedName === ""){
        setalertStyle({alertMessage:'Incorrect entry, Please try again',alertType:'Error'})
        setopenSnack(true)
        setopenLoader(false)
      }else{
        setsubmitIdentifier(true)
        Dispatch({
          type:'pendingApplication',
          passAplicationpending:[],
        })
        fetchPendings(branchData.company_id,branchData.branch_id,0,selectedStatus,searchedName,cust_type)
      }
  }
    const handleToggle=(value)=>{
      setcust_type(value)
      Dispatch({
        type:'pendingApplication',
        passAplicationpending:[],
      })
      fetchPendings(branchData.company_id,branchData.branch_id,0,selectedStatus,searchedName,value)
    }
    const onChange = (event) => {
      var valuesearch = event.target.value
      setsearchedName(valuesearch);
    };
    const clearFilter=()=>{
      setcust_type("")
      setsearchedName("")
      setsubmitIdentifier(false)
      Dispatch({
        type:'pendingApplication',
        passAplicationpending:[],
      })
      setTimeout(()=>{
        fetchPendings(branchData.company_id,branchData.branch_id,0,selectedStatus,"","")
      },200)
    }
    const widgetStatus=(val)=>{
      setselectedStatus(val)
      Dispatch({
        type:'pendingApplication',
        passAplicationpending:[],
      })
      setTimeout(()=>{
        fetchPendings(branchData.company_id,branchData.branch_id,0,val,searchedName,cust_type)
      },200)
    }
   


    useEffect(()=>{
        getJotype()
    },[initialBranch])

    return (
        <div className={classes.root}>
            <AlertComponent open={openSnack} message={alertStyle.alertMessage} close={() => setopenSnack(false)} type={alertStyle.alertType} />
            <Breadcrumbs aria-label="breadcrumb" gutterBottom>
                <Typography color="textPrimary">Home Page</Typography>
                <Typography color="textPrimary">CRM</Typography>
                <Typography color="textPrimary">Application</Typography>
                <Link>Pending Form</Link>
            </Breadcrumbs>
            <Card variant="outlined" style={{ marginTop: 15 }}>
                <CardContent>
                    <Typography variant="h5"> <b style={{ color: '#786fa6', }}>Customer pending application</b></Typography>
                    <Card variant="outlined" style={{ marginTop: 10 }}>
                        <CardContent>
                            <WidgetPending
                              widgetCounter={widgetCounter}
                              widgetStatus={widgetStatus}/>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" style={{ marginTop: 10 }}>
                        <CardContent>
                            <PendingHeader
                              onChange={onChange}
                              fetchCustomer={fetchCustomer}
                              searchedName={searchedName}
                              handleToggle={handleToggle}
                              cust_type={cust_type}
                              clearFilter={clearFilter}
                              selectedStatus={selectedStatus}
                              submitIdentifier={submitIdentifier}/>
                            <PendingTable 
                              rowsPerPage={rowsPerPage}
                              page={page}
                              tableLength={tableLength}
                              displayDialog={displayDialog}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}/>
                        </CardContent>
                    </Card>
                </CardContent>
            </Card> 
            <DialogViewDetails
                ViewDialog={ViewDialog}
                handleClose={handleClose}
                selectedCustomer={selectedCustomer}
                actionButtonDialog={actionButtonDialog}/>

            <DialogConfirmation 
                openHold={openHold}
                handleCloseHold={handleCloseHold}
                buttonClick={buttonClick}
                submitUpdate={submitUpdate}
                onChangeJustification={onChangeJustification}/>

            <Backdrop className={subClasses.backdrop} open={openLoader} style={{zIndex:9999}}>
              <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </Backdrop>
        </div>
    );
}