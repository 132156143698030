import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function AlertComponent({open,message,close,type}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
});
  const { vertical, horizontal } = state;
  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={open} autoHideDuration={6000} onClose={()=>close()}>
        <Alert onClose={()=>close()} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}