import React, { useEffect } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";

import MasterData2 from '../master/main'
import UploadData from '../uploadCustom'
import NewApplication2 from '../applications/newForm/newMain'
import PendingApplication from '../applications/pendings/pendingmain'
import Index from '../landingpg'
import CreateJo from '../jobOrder/creation/create'
import Assigning from '../jobOrder/assigning/assigning'
import UpdateApplication from '../master/updateCustomer/updateProf'
import MainInventory from '../inventory/records/main'
import Sales from '../sales/ordering/sales'
import SalesHistory from '../sales/history/main'
export const routesDrawer = [
    {
        path: '/masterdata/',
        exact: true,
        subPage: [],
        component: MasterData2,
        page_name: 'Master List',
        icon: "add"
    },{
        path: 'root',
        exact: true,
        subPage: [{
            path: '/new-app/',
            exact: true,
            component: NewApplication2,
            page_name: 'New Application',
            icon: "people_alt"
        },
        {
            path: '/pending/',
            exact: true,
            component: PendingApplication,
            page_name: 'Pending Application',
            icon: "people_alt"
        }],
        component: NewApplication2,
        page_name: 'Application',
        icon: "people_alt"
    },{
        path: 'root',
        exact: true,
        subPage: [{
            path: '/create/',
            exact: true,
            component: CreateJo,
            page_name: 'Create',
            icon: "people_alt"
        },
        {
            path: '/AssignJo/',
            exact: true,
            component: Assigning,
            page_name: 'Assign',
            icon: "people_alt"
        },
        {
            path: '/AssignJo/',
            exact: true,
            component: Assigning,
            page_name: 'Deliver',
            icon: "people_alt"
        }],
        component: CreateJo,
        page_name: 'Job Order',
        icon: "people_alt"
    },{
        path: 'root',
        exact: true,
        subPage: [{
            path: '/mstrinvt/',
            exact: true,
            component: MainInventory,
            page_name: 'Inventory records',
            icon: "people_alt"
        }],
        component: MainInventory,
        page_name: 'Inventory list',
        icon: "people_alt"
    },{
        path: 'root',
        exact: true,
        subPage: [
            {
                path: '/sls/',
                exact: true,
                component: Sales,
                page_name: 'Ordering',
                icon: "people_alt"
            },{
                path: '/slsHistory/',
                exact: true,
                component: SalesHistory,
                page_name: 'History',
                icon: "people_alt"
            }
        ],
        component: Sales,
        page_name: 'Sales',
        icon: "people_alt"
    },
]


export const routes = [
    {
        path: '/',
        exact: true,
        component: Index, 
        page_name: 'LandPage'
    },
    { 
        path: '/masterdata/',
        exact: false,
        component: MasterData2, 
        page_name: 'Master List'
    },
    {
        path: '/pending/',
        exact: false,
        component: PendingApplication,
        page_name: 'Pending Application'
    },
    {
        path: '/upload/',
        exact: false,
        component: UploadData,
        page_name: 'Upload Customer'
    },
    {
        path: '/new-app/',
        exact: false,
        component: NewApplication2,
        page_name: 'New Application'
    },
    {
        path: '/create/',
        exact: false,
        component: CreateJo, 
        page_name : 'Create'
    }, 
    {
        path: '/AssignJo/',
        exact: false,
        component: Assigning,
        page_name : 'Assign'
    },
    {
        path: '/AssignJo/',
        exact: false,
        component: Assigning,
        page_name : 'Deliver'
    },
    {
        path: '/updateAccount/',
        exact: false,
        component: UpdateApplication,
        page_name : 'Edit Customer Details'
    },
    {
        path: '/mstrinvt/',
        exact: false,
        component: MainInventory,
        page_name : 'Inventory records'
    },
    {
        path: '/sls/',
        exact: false,
        component: Sales,
        page_name : 'Ordering'
    },
    {
        path: '/slsHistory/',
        exact: false,
        component: SalesHistory,
        page_name : 'History'
    }
    
]

