const initialState = {
    userIDparam:"",
    userProfile: [],
    handledBranch:[],
    accountDetails:[],
    access_priv:[],
    componentRender:false
}
const userDataReducers = (state = initialState, action) => {
    switch (action.type) {
        case 'userDecrypted':
            return {
                ...state,
                userIDparam: action.passDecrypted
            }
        case 'accountDetails_':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
}
export default userDataReducers; 