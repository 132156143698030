import {combineReducers} from 'redux'
import userDataReducers from '../reducers/userdata'
import differentCustomer from '../reducers/customerData'
import formTemplate from '../reducers/formTemplate'
import FieldsChecker from '../reducers/checker'
import extraFieldsAdded from '../reducers/extraFields'
import custDetails from '../reducers/customerdetails'
import inventoryData from '../reducers/invent'
import BranchHandled from '../reducers/branches'
import priviledgesNav from '../reducers/priviledges'
export default combineReducers({
    userDataReducers:userDataReducers,
    differentCustomer:differentCustomer,
    formTemplate:formTemplate,
    FieldsChecker:FieldsChecker,
    extraFieldsAdded:extraFieldsAdded,
    custDetails:custDetails,
    inventoryData:inventoryData,
    BranchHandled:BranchHandled,
    priviledgesNav:priviledgesNav
})