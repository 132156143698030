import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AppsIcon from '@material-ui/icons/Apps';
import {
  HashRouter as Router,
  Route,
  useParams,
  Redirect,
  Link as NewLink
} from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
const drawerWidth = 240;

function Toggling() {
    const theme = useTheme();
    const { user_id } = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [setTimer,setsetTimer] = React.useState("")
    const [searchExecutor,setsearchExecutor] = React.useState(false)
    const [searchBranch,setsearchBranch] = React.useState("")
    const [branchDisplay, setbranchDisplay] = React.useState([]);
    const Dispatch = useDispatch();
    const navigation_reducer = useSelector(state => state.navigation_reducer)
    const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)


    const handleClick= (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    const changeSelectedBranch=(value)=>{
        Dispatch({
            type:'passInitialBranch',
            passSelectedBranch:value
          })
        handleClose()
    }

    const updateInput = async (input) => {
        setsearchBranch(input.target.value)
        clearTimeout(setTimer)
        const timerSet = setTimeout(()=>{
            setsearchExecutor(!searchExecutor)
        },500)
        setsetTimer(timerSet)
    }

    const branchFilter=(searchBranch)=>{
        const filtered = BranchHandled.filter(item => {
            return item.branch_name.toLowerCase().includes(searchBranch.toLowerCase())
        })
        setbranchDisplay(filtered)
    }

    useEffect(()=>{
        setbranchDisplay(BranchHandled)
    },[BranchHandled.length])
    
    useEffect(()=>{
        branchFilter(searchBranch)
    },[searchExecutor])
    return(
        <div>
        <IconButton
            onClick={handleClick}
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit">
            <AppsIcon style={{ color: "#786fa6" }} />
        </IconButton>
        <Menu
            id="simple-menu"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            anchorEl={anchorEl}
            elevation={2}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <div style={{width:400,paddingRight: 20,paddingLeft:20,paddingTop:20,paddingBottom:20 }}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search branch</b></Typography>
                            <TextField
                                style={{ backgroundColor: '#fff' }}
                                id="outlined-size-small"
                                variant="outlined"
                                size="small"
                                name="nameSearch"  
                                onChange={updateInput}
                                InputProps={{
                                    startAdornment: <InputAdornment position="end">
                                        <IconButton
                                            // onClick={() => fetchCustomer()}
                                            aria-label="toggle password visibility" edge="start">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }} />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{maxHeight:350,overflow:'auto',marginTop:10}}>
                    {branchDisplay.map((val,index)=>{
                        return<>
                        <MenuItem onClick={()=>{changeSelectedBranch(val)}} key={index}>{val.branch_name}</MenuItem>
                        <Divider/>
                        </>
                    })}
                </div>
            </div>
        </Menu>
    </div>
    )
}

export default Toggling;
