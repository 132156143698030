const initialState = {
    prodList: [],
}
const inventoryData = (state = initialState, action) => {
    switch (action.type) {
        case 'productArr_':
            return {
                ...state,
                prodList: action.passProduct
            }
        case 'newlyAdded':
            return {
                ...state,
                prodList: state.prodList.concat(action.passNewlyprod) 
            }
        default:
            return state;
    }
}
export default inventoryData; 