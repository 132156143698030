import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import useStyles from '../../stylesheet';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      minWidth: 170,
      fontSize: 14,
    },
  }))(TableCell);
export default function DialogViewUnassign({previouslyCreatedJo,selectTorevert,handleUncheck,unAssignDialog,handleCloseUnassign,submitUnassign}) {
    const subClasses = subStyles();
    const classes = useStyles();

    return (
        <Dialog
        open={unAssignDialog}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleCloseUnassign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
            <DialogContent>
                <Card variant="outlined" style={{ marginTop: 10, }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <div style={{ marginBottom: 10, height: 100, width: '100%', backgroundColor: '#4FA1ED' }}>
                                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{previouslyCreatedJo.length}</b></Typography>
                                        <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> TOTAL CREATED</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div style={{ marginBottom: 10, height: 100, width: '100%', backgroundColor: '#F59B74' }} onClick={() => { }}>
                                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="h4" gutterBottom style={{ color: '#fff', marginTop: 5 }}> <b>{selectTorevert.length}</b></Typography>
                                        <div style={{ position: 'absolute', bottom: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="h5" style={{ color: '#fff', fontSize: 13, marginBottom: 10 }}> SELECTED ACCOUNTS </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <List component="nav" className={classes.root} aria-label="contacts">
                            <Paper>
                                <TableContainer size="small" className={subClasses.tableContainer}>
                                    <Table size="small"
                                        stickyHeader
                                        style={{ whiteSpace: "nowrap" }}  >
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "blue" }}>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}></StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Id</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Account_No.</StyledTableCell>
                                                <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Date filter</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {previouslyCreatedJo.map((ary, index) => {
                                                return <TableRow hover tabIndex={-1} key={index}>
                                                    <TableCell align="left">
                                                        <Checkbox
                                                            onClick={() => handleUncheck(ary)}
                                                            edge="start"
                                                            checked={selectTorevert.findIndex(x => String(x.id).toUpperCase() === String(ary.id).toUpperCase()) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple />
                                                    </TableCell>
                                                    <TableCell align="left">{ary.id}</TableCell>
                                                    <TableCell align="left">{ary.account_number}</TableCell>
                                                    <TableCell align="left">{moment(ary.date_filter).format('YYYY-MM-DD')}</TableCell>

                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </List>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseUnassign} color="primary"> Cancel</Button>
                <Button 
                    onClick={()=>{submitUnassign()}} 
                    color="primary" autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}