import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiAlert from '@material-ui/lab/Alert';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DeleteIcon from '@material-ui/icons/Delete';
import { InsertEmoticonRounded } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { getData } from '../../api/api'
import LoadingGif from '../../assets/loading.gif'
import SalesToggeOrder from './ordetoggle'
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
      margin: {
        margin: theme.spacing(1),
      },
}));

export default function SalesHistoryDialog({ historyDialog,handleCloseHistory,customer_credential}) {
    const classes = useStyles();
    const subClasses = subStyles();
    const Dispatch = useDispatch();
    const [refreshs, setrefreshs] = React.useState(false);
    const [loadingDisplay, setloadingDisplay] = React.useState(false);
    const [cancelDisplay, setcancelDisplay] = React.useState(false);
    const [productvalue, setproductvalue] = React.useState("");
    const [alertDisplay,setalertDisplay] = React.useState({
        showAlert:false,
        alertMessage:"",
        alertType:''
    })
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableLength, settableLength] = React.useState(0);

    const [selectedOrder,setselectedOrder] = React.useState({
        order:[],
        index:0
    })
    const [itemHistory,setitemHistory] = React.useState([])
    const [anchorFilterby, setAnchorFilterby] = React.useState(null);
    const BranchHandled = useSelector(state => state.userDataReducers.handledBranch)
    const InventoryArr_ = useSelector(state => state.inventoryData.prodList)
    const user_Data = useSelector(state => state.userDataReducers.userProfile)

    const fetchHistory=(history_data,newPage)=>{
        setloadingDisplay(true)
        let history_ = {
          customer_id:history_data[0].cust_id,
          company_id:history_data[0].company_id,
          branch_id:history_data[0].branch_id,
          page:newPage+1,
          limit:rowsPerPage,
          status:"",
          search:""
        }
        getData('inventory/GetSalesHistory', history_)
        .then((res) => {
            setloadingDisplay(false)
            setitemHistory(res.sales)
            settableLength(res.sales_count)
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchHistory(customer_credential,newPage)
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const resetValue=()=>{
        setitemHistory([])
        handleCloseHistory()
    }

    const removeItem=(order_,index)=>{
        setloadingDisplay(true)
        let itemRemove={
            bid:order_.bid,
            jo_id:order_.jo_id,
            accomplishment_status:2
        }
        getData('inventory/UpdateSalesJobOrder', itemRemove)
        .then((res) => {
            if(res.status === "Success"){
                itemHistory[index].accomplishment_status = "2"
                setloadingDisplay(false)
            }else{
                alert('failed to remove item')
                setloadingDisplay(false)
            }
            setcancelDisplay(false)
        })
    }

 

    useEffect(() => {
        if(customer_credential.length > 0){
            fetchHistory(customer_credential,page)
        }
    }, [customer_credential])
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={historyDialog}
            // onClose={handleClose}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="form-dialog-title">History sales</DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff' }}>

                <Paper variant="outlined">
                    <TableContainer size="small" className={subClasses.tableContainer}>
                        <Table size="small"
                            stickyHeader
                            style={{ whiteSpace: "nowrap" }}  >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{backgroundColor: "#786fa6",color: "#fff"}} align="left">#</TableCell>
                                    <TableCell style={{backgroundColor: "#786fa6",color: "#fff"}} align="left"></TableCell>
                                    <TableCell style={{backgroundColor: "#786fa6",color: "#fff"}} align="left"> Customer name</TableCell>
                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Status </TableCell>
                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Address </TableCell>
                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Price </TableCell>
                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Qty </TableCell>
                                    <TableCell style={{ backgroundColor: "#786fa6", color: "#fff" }} align="left"> Date </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody> 
                                {itemHistory.map((ary,index) => {
                                    let status_identificator = ""
                                    let status_color = ""
                                    let total_quantity = 0
                                    let details_order = []
                                    if(ary.accomplishment_status === null || parseInt(ary.accomplishment_status) === 0){
                                        status_identificator = "Pending"
                                        status_color = "#7f8c8d"
                                    }else if(parseInt(ary.accomplishment_status) === 1){
                                        status_identificator = "Accomplished"
                                        status_color = "#2d98da"
                                    }else if(parseInt(ary.accomplishment_status) === 2){
                                        status_identificator = "Cancelled"
                                        status_color = "#b33939"
                                    }
                                    if(ary.order_details !== null){
                                        if (Array.isArray(JSON.parse(ary.order_details))) {
                                            let sumtotalPrice = JSON.parse(ary.order_details).reduce((previousValue, currentValue) => previousValue + parseInt(currentValue.item_changes), 0)
                                            total_quantity = sumtotalPrice
                                        }
                                    }

                                    if(ary.order_details !== ""){
                                        details_order = JSON.parse(ary.order_details)
                                    }
                                    return<TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align="left">{index+(rowsPerPage*page)+1}</TableCell>
                                            <TableCell align="left">
                                              
                                                <SalesToggeOrder details_order={details_order}/>
                                                {(ary.accomplishment_status === "0" || ary.accomplishment_status === 0) &&
                                                    <IconButton 
                                                        size="small"
                                                        onClick={()=>{
                                                            setselectedOrder({
                                                                order:ary,
                                                                index:index  
                                                            })
                                                           setcancelDisplay(true)
                                                        }}
                                                        aria-label="delete" className={subClasses.margin}>
                                                        <DeleteIcon fontSize="small" style={{color:'#b71540'}}/>
                                                    </IconButton>
                                                 }
                                            </TableCell>
                                            <TableCell align="left">{ary.name}</TableCell>
                                            <TableCell align="left"  style={{backgroundColor: status_color,color:'#fff'}}>{status_identificator}</TableCell>
                                            <TableCell align="left">{ary.address}</TableCell>
                                            <TableCell align="left">{ary.total === null ? "N/A" : '₱'+parseFloat(ary.total).toFixed(2)}</TableCell>
                                            <TableCell align="left">{total_quantity}</TableCell>
                                            <TableCell align="left"><a class="view">{ary.date_added === "0000-00-00 00:00:00" ? 'N/A' : moment(ary.date_added).format('MMM DD, YYYY hh:mm A')}</a></TableCell>
                                        </TableRow>
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10,50, 100]}
                        component="div"
                        count={tableLength}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button style={{backgroundColor:'#95a5a6',color:'#f5f6fa'}} onClick={() => resetValue()}> Close</Button>
            </DialogActions>
            {loadingDisplay === true &&
             <div    
                style={{
                    width:'100%',
                    height:'100%',
                    backgroundColor:'rgba(209, 209, 209,.5)',
                    position:'absolute',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    zIndex:2}}>
                <img src={LoadingGif} style={{ width: 100, height: 100}} />
            </div>  
            }
             <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={cancelDisplay}
                // onClose={handleClose}
                aria-labelledby="max-width-dialog-title">
                <DialogContent style={{ backgroundColor: '#fff' }}>
                    <Typography variant="h6"><b style={{color:'#786fa6'}}>Confirmation</b></Typography>
                    <Typography variant="h6" style={{marginBottom:10}}>Are you sure you want to cancel this order?</Typography>
                    <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                        <Button style={{backgroundColor:'#95a5a6',color:'#f5f6fa',marginRight:5}} onClick={() => setcancelDisplay(false)}> Cancel</Button>
                        <Button style={{backgroundColor:'#2e86de',color:'#f5f6fa'}} onClick={() => removeItem(selectedOrder.order,selectedOrder.index)}> Continue</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Dialog>
    )
}