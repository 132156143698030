import React,{useState,useRef,useEffect  } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
const subStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 450,
      },
  }));
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
export default function AssignTable({fetchedCustomer,handleCheck,selectedAccount}) {
    const subClasses = subStyles();

    return (
        <Paper>
            <TableContainer size="small" className={subClasses.tableContainer}>
                <Table size="small"
                    stickyHeader
                    style={{ whiteSpace: "nowrap" }}  >
                    <TableHead >
                        <TableRow style={{ backgroundColor: "blue" }}>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>#</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}></StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Jo id</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>Account_No.</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }}>MRU</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left">Account_Name</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: "#786fa6", color: "white" }} align="left" >Address</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fetchedCustomer.map((ary, index) => {
                            return <TableRow hover tabIndex={-1} key={index}>
                                <TableCell align="left">{index+1}</TableCell>
                                <TableCell align="left">
                                    <Checkbox
                                        onClick={() => handleCheck(ary)}
                                        edge="start"
                                        checked={selectedAccount.findIndex(x => String(x.jo_id).toUpperCase() === String(ary.jo_id).toUpperCase()) !== -1}
                                        tabIndex={-1}
                                        disableRipple />
                                </TableCell>
                                <TableCell align="left">{ary.jo_id}</TableCell>
                                <TableCell align="left">{ary.account_number}</TableCell>
                                <TableCell align="left">{ary.mru === null ? "n/a" : ary.mru}</TableCell>
                                <TableCell align="left">{ary.name}</TableCell>
                                <TableCell align="left">{ary.address}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}