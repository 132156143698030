import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import Menu from '@material-ui/core/Menu';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import DnsIcon from '@material-ui/icons/Dns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
  } from '@material-ui/pickers';
  import AppsIcon from '@material-ui/icons/Apps';
export default function CreateHeader({branchData,onChangeFieldwork,selectedFieldwork,onChangeDate,cycleDate,onChangejoDate,joborderDate,submitFilter}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick= (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    
    return (
        <div>
            <Button
                onClick={handleClick}
                style={{backgroundColor:'#786fa6',color:'#fff'}}
                className={classes.button}
                startIcon={<DnsIcon/>}>
                Filter
            </Button>
            <Menu
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                elevation={2}
                id="simple-menu"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20 }}>
                    <Typography style={{ color: '#786fa6', fontSize: 17 }}> <b>Filter </b></Typography>
                    <Card variant="outlined" style={{marginBottom:10}}>
                        <CardContent >
                            <Grid container spacing={1}>
                                <Grid item md={12} xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        className={classes.formControl}
                                        required={true}>
                                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Job order:</b></Typography>
                                        <Select
                                            onChange={onChangeFieldwork}
                                            size="small"
                                            native
                                            value={selectedFieldwork}
                                            inputProps={{
                                                name: "branch",
                                                id: 'filled-age-native-simple',
                                            }}>
                                            <option aria-label="None" value="" />
                                            {branchData != null &&
                                                <>
                                                    {branchData === ""
                                                        ? <></>
                                                        : <>
                                                            {JSON.parse(branchData).map((ary, index2) => {
                                                                return <option aria-label="None" value={ary} key={index2}>{ary}</option>
                                                            })}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Billing Cycle:</b></Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            size="small"
                                            inputVariant="outlined"
                                            views={["year", "month"]}
                                            value={cycleDate}
                                            onChange={(e) => onChangeDate(e)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button
                                        onClick={() => {
                                            submitFilter()
                                            handleClose()
                                        }}
                                        style={{ alignSelf: 'flex-end', width: '100%', backgroundColor: '#574B90', color: '#fff' }}> Submit</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </Menu>
        </div>
    );
}