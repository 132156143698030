import React,{useState,useRef,useEffect  } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../stylesheet';
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
  } from '@material-ui/pickers';
export default function CreateFilter({selectedAccount,onChangejoDate,joborderDate}) {
    const classes = useStyles();
    const [anchorFilterby, setAnchorFilterby] = React.useState(null);
    const [anchorValue, setAnchorValue] = React.useState(null);

    const handleClick= (event) => {
        setAnchorFilterby(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorFilterby(null);
    };

    const handleClickValue= (event) => {
        setAnchorValue(event.currentTarget);
    };
    const handleCloseValue = () => {
        setAnchorValue(null);
    };
    return (
        <Grid container spacing={1} style={{marginBottom:10}}>
            <Grid item xs={12} md={3}>
                <FormControl style={{ width: '100%', }}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Search Customer</b></Typography>
                    <TextField
                        style={{backgroundColor:'#f5f6fa'}}
                        // value={insertedSearch}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        // onChange={(val) => setinsertedSearch(val.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">
                                <IconButton
                                    // onClick={() => filterSearch()}
                                    aria-label="toggle password visibility" edge="start">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }} />
                </FormControl>
            </Grid>
             <Grid item xs={12} md={5}>
                <div style={{width:'100%',display:'flex'}}>
                    <div style={{marginRight:5}}>
                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                        <Button
                            style={{ height: 40, backgroundColor: '#f5f6fa', }}
                            size="medium"
                            onClick={handleClick}
                            variant="outlined"
                            className={classes.button}
                            endIcon={<ArrowDropDownIcon />}>
                            Filter by
                        </Button>
                        <Menu
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            id="simple-menu"
                            elevation={1}
                            getContentAnchorEl={null}
                            anchorEl={anchorFilterby}
                            keepMounted
                            open={Boolean(anchorFilterby)}
                            onClose={handleClose}>
                            <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20}}>
                                <Typography style={{ color: '#786fa6', fontSize: 17 }}> <b>Filter by fields :</b></Typography>
                                <FormControl style={{ width: '100%',marginBottom:10}}>
                                    <TextField
                                        style={{ backgroundColor: '#f5f6fa' }}
                                        // value={insertedSearch}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Search field"
                                        // onChange={(val) => setinsertedSearch(val.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    // onClick={() => filterSearch()}
                                                    aria-label="toggle password visibility" edge="start">
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }} />
                                </FormControl>
                                <Card variant="outlined" style={{ marginBottom: 10 }}>
                                    <CardContent >
                                        <MenuItem>MRU</MenuItem>
                                        <MenuItem>Account type</MenuItem>
                                        <MenuItem>Address</MenuItem>
                                    </CardContent>
                                </Card>
                            </div>
                        </Menu>
                    </div>
                    <div style={{marginRight:5}}>
                        <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>&nbsp;</b></Typography>
                        <Button
                            style={{height:40,backgroundColor:'#f5f6fa',}}
                            size="medium"
                            onClick={handleClickValue}
                            variant="outlined"
                            className={classes.button}
                            endIcon={<ArrowDropDownIcon/>}>
                            Filter value
                        </Button>
                        <Menu
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            id="simple-menu"
                            elevation={1}
                            getContentAnchorEl={null}
                            anchorEl={anchorValue}
                            keepMounted
                            open={Boolean(anchorValue)}
                            onClose={handleCloseValue}>
                            <div style={{ width: 350, maxHeight: 400, paddingRight: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20 }}>
                                <Typography style={{ color: '#786fa6', fontSize: 17 }}> <b>Filter value :</b></Typography>
                                <FormControl style={{ width: '100%',marginBottom:10}}>
                                    <TextField
                                        style={{ backgroundColor: '#f5f6fa' }}
                                        // value={insertedSearch}
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Search value"
                                        // onChange={(val) => setinsertedSearch(val.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    // onClick={() => filterSearch()}
                                                    aria-label="toggle password visibility" edge="start">
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }} />
                                </FormControl>
                                <Card variant="outlined" style={{ marginBottom: 10 }}>
                                    <CardContent >
                                        <MenuItem>MRU</MenuItem>
                                        <MenuItem>Account type</MenuItem>
                                        <MenuItem>Address</MenuItem>
                                    </CardContent>
                                </Card>
                            </div>
                        </Menu>
                    </div>
                    <Button
                    style={{ height:39,alignSelf: 'flex-end', backgroundColor: '#686de0', color: '#fff' }}> Submit </Button>
                </div>
             </Grid>
            <Grid item xs={12} md={1}/>
            {selectedAccount.length > 0 &&
                <Grid item xs={12} md={3}>
                    <Typography style={{ color: '#786fa6', fontSize: 15 }}> <b>Jo Date:</b></Typography>
                    <TextField
                        style={{backgroundColor:'#f5f6fa'}}
                        variant="outlined"
                        size="small"
                        id="date"
                        type="date"
                        name={joborderDate}
                        onChange={(e) => onChangejoDate(e)}
                        required={true}
                        value={moment(joborderDate).format('YYYY-MM-DD')}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: false,
                        }} />
                </Grid>
            }
           
        </Grid>
    );
}